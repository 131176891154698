import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { useTableContext } from "@/contexts/TableContext";
import type { TableCellProps } from "@/pages/Table/cellTypes";
import type { TextCellValue, TextColumnMetadata } from "@api/schemas";
import Link from "@tiptap/extension-link";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const TextCell = observer(
	({
		rowId,
		columnId,
		cellValue,
		isProxy,
	}: TableCellProps<TextColumnMetadata>) => {
		let castValue: TextCellValue | null;
		if (cellValue && cellValue.cell_value_type !== "text") {
			castValue = null;
		} else {
			castValue = cellValue;
		}
		const currentValue = castValue?.cell_value;

		const [open, setOpen] = useState(false);
		const tableContext = useTableContext();

		const cellEditor = useEditor(
			{
				extensions: [StarterKit, Link],
				content: currentValue ?? "",
				editable: false,
			},
			[currentValue],
		);

		const popupEditor = useEditor(
			{
				extensions: [StarterKit, Link],
				content: currentValue ?? "",
				editable: true,
				editorProps: {
					attributes: {
						class: "prose prose-sm px-3 py-2 outline-none focus:outline-none",
					},
					handleDOMEvents: {
						keydown: (_, event) => {
							if (event.key === "Enter") {
								if (event.shiftKey) {
									return false;
								}
								event.preventDefault();
								updateCell();
								setOpen(false);
								return true;
							}
						},
					},
				},
			},
			[currentValue],
		);

		const updateCell = () => {
			if (!popupEditor) {
				return;
			}
			const newValue = popupEditor.getHTML();
			if (newValue !== currentValue) {
				tableContext.updateCell({
					rowId,
					columnId,
					value: {
						cell_value: newValue,
						cell_value_type: "text",
					},
				});
			}
		};

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					if (newOpen) {
						popupEditor?.commands.focus();
					}

					if (!newOpen && popupEditor) {
						updateCell();
					}
					setOpen(newOpen);
				}}
			>
				<PopoverTrigger
					disabled={isProxy || !tableContext.editable}
					className={clsx(
						"flex h-full w-full min-w-0 flex-col items-start justify-start truncate p-1 text-left text-neutral-800 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					<EditorContent editor={cellEditor} />
				</PopoverTrigger>
				<PopoverContent align="start" className="overflow-hidden p-0">
					<EditorContent editor={popupEditor} />
				</PopoverContent>
			</Popover>
		);
	},
);
