import { API_ENDPOINT_WS } from "@/config";
import { DisplayedActionError } from "@/contexts/SyncedActions";
import {
	addColumnCategoryAction,
	changeCategoryColorAction,
	createColumnAction,
	createRowAction,
	deleteColumnAction,
	deleteRowsAction,
	moveColumnAction,
	moveRowsAction,
	removeColumnCategoryAction,
	renameColumnCategoryAction,
	resizeColumnAction,
	updateCellAction,
	updateColumnMetadataAction,
} from "@/contexts/TableContext/TableHandlers";
import { formatTableCellId } from "@/idGenerators";
import type {
	CellValue,
	ColumnMetadata,
	MaterializedColumn,
	MaterializedRow,
	RowMetadata,
	TableCellId,
	TableColumnId,
	TableId,
	TableMetadata,
	TableRowId,
	TableTransaction,
} from "@api/schemas";
import {
	type DeleteColumn,
	type DeleteRows,
	type GetTableLatestVersionResponse,
	type MoveColumn,
	TableDisconnectCode,
	type TableResponse,
	type UpdateCellValues,
	type UpdateProxiedCellValues,
	type UpsertColumn,
	type UpsertColumnMetadata,
	type UpsertRow,
	type UpsertRowMetadata,
} from "@api/schemas";
import type { MoveRows } from "@api/schemas/moveRows";
import type { UpdateColumnWidth } from "@api/schemas/updateColumnWidth";
import { useAuth } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

class Table {
	rows: Map<TableRowId, MaterializedRow>;
	proxiedRowMetadata: Map<TableRowId, RowMetadata>;

	columns: Map<TableColumnId, MaterializedColumn>;
	proxiedColumnMetadata: Map<TableColumnId, ColumnMetadata>;

	cellValues: Map<TableCellId, CellValue>;
	proxiedCellValues: Map<TableCellId, CellValue>;

	primaryColumnId: TableColumnId;
	latestTransaction: TableTransaction;

	parentTableIds: Set<TableId>;
	childTableIds: Set<TableId>;

	parentTableMetadatas: Map<TableId, TableMetadata>;
	parentTableColumns: Map<TableId, Map<TableColumnId, MaterializedColumn>>;

	deletedProxiedRowIds: Set<TableRowId>;
	deletedProxiedColumnIds: Set<TableColumnId>;

	constructor(materializedTable: GetTableLatestVersionResponse) {
		this.rows = new Map(
			Object.entries(materializedTable.table.rows).map(([key, value]) => [
				key as TableRowId,
				value,
			]),
		);
		this.proxiedRowMetadata = new Map(
			Object.entries(materializedTable.table.proxied_row_metadata).map(
				([key, value]) => [key as TableRowId, value],
			),
		);
		this.columns = new Map(
			Object.entries(materializedTable.table.columns).map(([key, value]) => [
				key as TableColumnId,
				value,
			]),
		);
		this.proxiedColumnMetadata = new Map(
			Object.entries(materializedTable.table.proxied_column_metadata).map(
				([key, value]) => [key as TableColumnId, value],
			),
		);
		this.cellValues = new Map(
			Object.entries(materializedTable.table.cell_values).map(
				([cellId, cell]) => [cellId as TableCellId, cell],
			),
		);
		this.proxiedCellValues = new Map(
			Object.entries(materializedTable.table.proxied_cell_values).map(
				([cellId, cell]) => [cellId as TableCellId, cell],
			),
		);
		this.primaryColumnId = materializedTable.table.primary_column_id;
		this.latestTransaction = materializedTable.table.latest_transaction;
		this.parentTableIds = new Set(materializedTable.table.table_parent_ids);
		this.childTableIds = new Set(materializedTable.table.table_child_ids);
		this.parentTableMetadatas = new Map(
			Object.entries(materializedTable.parent_table_metadatas).map(
				([tableId, metadata]) => [tableId as TableId, metadata],
			),
		);
		this.parentTableColumns = new Map(
			Object.entries(materializedTable.parent_table_columns).map(
				([tableId, columns]) => [
					tableId as TableId,
					new Map(
						Object.entries(columns).map(([columnId, column]) => [
							columnId as TableColumnId,
							column,
						]),
					),
				],
			),
		);
		this.deletedProxiedRowIds = new Set(
			materializedTable.table.deleted_proxied_row_ids,
		);
		this.deletedProxiedColumnIds = new Set(
			materializedTable.table.deleted_proxied_column_ids,
		);
		makeAutoObservable(this);
	}
}

export class TableState {
	tableId: TableId;
	table: Table;

	/* 
	React hooks
	*/
	getToken: ReturnType<typeof useAuth>["getToken"];
	navigate: ReturnType<typeof useNavigate>;

	/* 
	Reconnect logic
	*/
	ws: WebSocket | null = null;
	isInitialized = false;
	reconnectTimer: Timer | null = null;
	wsConnected = false;
	reconnectAttempts = 0;

	editable: boolean;

	/* 
	If any filters are applied, these rows store the row IDs that pass the filter.
	*/
	filteredRows: Set<TableRowId> | null = null;

	constructor(props: {
		tableId: TableId;
		tableData: GetTableLatestVersionResponse;
		getToken: ReturnType<typeof useAuth>["getToken"];
		navigate: ReturnType<typeof useNavigate>;
		editable: boolean;
	}) {
		this.tableId = props.tableId;
		this.table = new Table(props.tableData);
		this.getToken = props.getToken;
		this.navigate = props.navigate;
		this.editable = props.editable;
		makeAutoObservable(this);
	}

	checkEditable(this: TableState) {
		if (!this.editable) {
			throw new DisplayedActionError("Table is not editable");
		}
	}

	get isComputedTable() {
		return this.table.parentTableIds.size > 0;
	}

	get parentTable() {
		if (this.table.parentTableMetadatas.size === 0) {
			return null;
		}
		if (this.table.parentTableMetadatas.size > 1) {
			throw new Error("Computed table has multiple parents");
		}
		return [...this.table.parentTableMetadatas.values()][0];
	}

	get parentTableColumns() {
		if (this.table.parentTableColumns.size === 0) {
			return null;
		}
		if (this.table.parentTableColumns.size > 1) {
			throw new Error("Computed table has multiple parents");
		}
		return [...this.table.parentTableColumns.values()][0];
	}

	getParentColumnById(tableId: TableId, columnId: TableColumnId) {
		const column = this.table.parentTableColumns.get(tableId)?.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		return column;
	}

	getColumnById(columnId: TableColumnId) {
		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		return column;
	}

	getProxiedColumnMetadataById(columnId: TableColumnId) {
		const column = this.table.proxiedColumnMetadata.get(columnId);
		return column;
	}

	getRowById(rowId: TableRowId) {
		const row = this.table.rows.get(rowId);
		if (!row) {
			throw new Error(`Row with ID ${rowId} not found`);
		}
		return row;
	}

	getProxiedRowMetadataById(rowId: TableRowId) {
		const row = this.table.proxiedRowMetadata.get(rowId);
		return row;
	}

	getCellValue(rowId: TableRowId, columnId: TableColumnId) {
		return (
			this.table.cellValues.get(formatTableCellId({ rowId, columnId })) ?? null
		);
	}

	getProxiedCellValue(rowId: TableRowId, columnId: TableColumnId) {
		return (
			this.table.proxiedCellValues.get(
				formatTableCellId({ rowId, columnId }),
			) ?? null
		);
	}

	get sortedColumns() {
		return [...this.table.columns.values()].sort((a, b) => {
			if (a.column_order < b.column_order) {
				return -1;
			}
			if (a.column_order > b.column_order) {
				return 1;
			}
			return 0;
		});
	}

	get filteredSortedRows() {
		const sortedRows = [...this.table.rows.values()]
			.filter((row) => {
				if (row.row_metadata.row_type === "proxy") {
					const proxiedRowIds = row.row_metadata.proxy_row_ids;
					if (
						proxiedRowIds.every((rowId) =>
							this.table.deletedProxiedRowIds.has(rowId),
						)
					) {
						return false;
					}
				}

				return true;
			})
			.sort((a, b) => {
				if (a.row_order < b.row_order) {
					return -1;
				}
				if (a.row_order > b.row_order) {
					return 1;
				}
				return 0;
			});

		// apply filters, if any
		const filteredRows = this.filteredRows;
		if (filteredRows === null) {
			return sortedRows;
		}
		return sortedRows.filter((row) => filteredRows.has(row.row_id));
	}

	getRowBefore(rowId: TableRowId) {
		const row = this.table.rows.get(rowId);
		if (!row) {
			throw new Error(`Row with ID ${rowId} not found`);
		}
		const prevRow = this.filteredSortedRows.findLast(
			(r) => r.row_order < row.row_order,
		);
		return prevRow;
	}

	getRowAfter(rowId: TableRowId) {
		const row = this.table.rows.get(rowId);
		if (!row) {
			throw new Error(`Row with ID ${rowId} not found`);
		}
		const nextRow = this.filteredSortedRows.find(
			(r) => r.row_order > row.row_order,
		);
		return nextRow;
	}

	getColumnBefore(columnId: TableColumnId) {
		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		const prevColumn = this.sortedColumns.findLast(
			(c) => c.column_order < column.column_order,
		);
		return prevColumn;
	}

	getColumnAfter(columnId: TableColumnId) {
		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with ID ${columnId} not found`);
		}
		const nextColumn = this.sortedColumns.find(
			(c) => c.column_order > column.column_order,
		);
		return nextColumn;
	}

	get lastColumn() {
		return this.sortedColumns.length
			? this.sortedColumns[this.sortedColumns.length - 1]
			: null;
	}

	get lastRow() {
		return this.filteredSortedRows.length
			? this.filteredSortedRows[this.filteredSortedRows.length - 1]
			: null;
	}

	async init({
		isReconnect,
	}: {
		isReconnect?: boolean;
	}) {
		if (this.isInitialized) {
			return;
		}
		this.isInitialized = true;
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in TableContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/tables/${this.tableId}/ws?token=${token}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to table.");
				}
			});
		};
		ws.onclose = (e) => {
			// 4040 is the code for "table deleted"
			if (e.code === TableDisconnectCode.NUMBER_4040) {
				this.navigate("/tables");
				toast.error("Table has been deleted.");
				return;
			}

			// 1000 is the code for "normal closure"
			if (e.code === 1000) {
				return;
			}

			runInAction(() => {
				this.wsConnected = false;
				this.ws = null;
			});

			this._attemptReconnect();
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this._attemptReconnect();
		};

		ws.onmessage = (event) => {
			try {
				const data: TableResponse = JSON.parse(event.data);
				this._handleWsResponse(data);
			} catch (e) {
				console.error("Error parsing websocket response JSON:", e);
				return;
			}
		};
	}

	private _attemptReconnect() {
		Sentry.captureMessage("WebSocket connection lost", "error");

		if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
			console.error("Max reconnection attempts reached");
			toast.error("Unable to reconnect. Please refresh the page.");
			return;
		}
		toast.error("Connection lost. Reconnecting...");

		const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

		this.reconnectTimer = setTimeout(() => {
			this.reconnectAttempts++;
			this.init({
				isReconnect: true,
			});
		}, delay);
	}

	cleanup() {
		if (this.reconnectTimer !== null) {
			clearTimeout(this.reconnectTimer);
		}
		this.ws?.close(1000, "Client closed connection");
	}

	private _handleWsResponse(data: TableResponse) {
		switch (data.type) {
			case "upsert_row": {
				this.upsertRowLocally(data);
				break;
			}
			case "upsert_column": {
				this.upsertColumnLocally(data);
				break;
			}
			case "delete_column": {
				this.deleteColumnLocally(data);
				break;
			}
			case "delete_rows": {
				this.deleteRowsLocally(data);
				break;
			}
			case "update_cell_values": {
				this.updateCellValuesLocally(data);
				break;
			}
			case "upsert_column_metadata": {
				this.upsertColumnMetadata(data);
				break;
			}
			case "upsert_row_metadata": {
				this.upsertRowMetadata(data);
				break;
			}
			case "move_rows": {
				this.moveRowsLocally(data);
				break;
			}
			case "move_column": {
				this.moveColumnLocally(data);
				break;
			}
			case "update_column_width": {
				this.resizeColumnLocally(data);
				break;
			}
			case "update_proxied_cell_values": {
				this.updateProxiedCellValuesLocally(data);
				break;
			}
			default: {
				const _exhaustiveCheck: never = data;
				return _exhaustiveCheck;
			}
		}
	}

	upsertRowLocally(row: UpsertRow): void {
		const rowId = row.row.row_id;

		this.table.rows.set(rowId, row.row);
	}

	upsertColumnLocally(column: UpsertColumn): void {
		this.table.columns.set(column.column.column_id, column.column);
	}

	moveRowsLocally(data: MoveRows): void {
		for (const [rowId, rowOrder] of Object.entries(data.new_row_orders)) {
			const row = this.table.rows.get(rowId as TableRowId);
			if (!row) {
				continue;
			}
			row.row_order = rowOrder;
		}
	}

	moveColumnLocally(column: MoveColumn): void {
		const columnId = column.column_id;
		const newColumnOrder = column.new_column_order;
		const targetColumn = this.table.columns.get(columnId);
		if (!targetColumn) {
			return;
		}
		targetColumn.column_order = newColumnOrder;
	}

	resizeColumnLocally(data: UpdateColumnWidth): void {
		const column = this.table.columns.get(data.column_id);
		if (!column) {
			return;
		}
		column.column_width = data.new_width;
	}

	upsertColumnMetadata(data: UpsertColumnMetadata): void {
		const columnId = data.column_id;
		const column = this.table.columns.get(columnId);
		if (!column) {
			return;
		}
		column.column_metadata = data.column_metadata;
	}

	upsertRowMetadata(data: UpsertRowMetadata): void {
		const rowId = data.row_id;
		const row = this.table.rows.get(rowId);
		if (!row) {
			return;
		}
		row.row_metadata = data.row_metadata;
	}

	deleteColumnLocally(column: DeleteColumn): void {
		this.table.columns.delete(column.column_id);
	}

	deleteRowsLocally(rows: DeleteRows): void {
		for (const rowId of rows.row_ids) {
			this.table.rows.delete(rowId);
		}
	}

	updateCellValuesLocally(cells: UpdateCellValues): void {
		for (const cell of cells.cells) {
			const rowId = cell.row_id;
			const columnId = cell.column_id;
			this.table.cellValues.set(
				formatTableCellId({ rowId, columnId }),
				cell.cell_value,
			);
		}
	}

	updateProxiedCellValuesLocally(cells: UpdateProxiedCellValues): void {
		for (const cell of cells.cells) {
			const rowId = cell.row_id;
			const columnId = cell.column_id;
			this.table.proxiedCellValues.set(
				formatTableCellId({ rowId, columnId }),
				cell.cell_value,
			);
		}
	}

	createRow = createRowAction.bind(this);
	deleteRows = deleteRowsAction.bind(this);
	createColumn = createColumnAction.bind(this);
	deleteColumn = deleteColumnAction.bind(this);
	updateCell = updateCellAction.bind(this);
	updateColumnMetadata = updateColumnMetadataAction.bind(this);
	addColumnCategory = addColumnCategoryAction.bind(this);
	renameColumnCategory = renameColumnCategoryAction.bind(this);
	removeColumnCategory = removeColumnCategoryAction.bind(this);
	changeCategoryColor = changeCategoryColorAction.bind(this);
	moveRows = moveRowsAction.bind(this);
	moveColumn = moveColumnAction.bind(this);
	resizeColumn = resizeColumnAction.bind(this);
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const TableContext = createContext<TableState>(null as any);

export const useTableContext = () => {
	const context = useContext(TableContext);
	if (!context) {
		throw new Error("TableContext must be used within a TableProvider");
	}
	return context;
};

export const TableProvider: React.FC<{
	tableId: TableId;
	tableData: GetTableLatestVersionResponse;
	editable: boolean;
	children: React.ReactNode;
}> = ({ tableId, tableData, editable, children }) => {
	const { getToken } = useAuth();
	const navigate = useNavigate();
	const tableState = useRef(
		new TableState({ tableId, tableData, getToken, navigate, editable }),
	);
	useEffect(() => {
		tableState.current.init({
			isReconnect: false,
		});
		return () => {
			tableState.current.cleanup();
		};
	}, []);

	return (
		<TableContext.Provider value={tableState.current}>
			{children}
		</TableContext.Provider>
	);
};
