/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

/**
 * Enumeration of possible table column types.
 */
export type TableColumnType = typeof TableColumnType[keyof typeof TableColumnType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TableColumnType = {
  text: 'text',
  document: 'document',
  number: 'number',
  boolean: 'boolean',
  date: 'date',
  datetime: 'datetime',
  category: 'category',
  proxy: 'proxy',
  proxy_group: 'proxy_group',
} as const;
