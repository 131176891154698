import { Favicon } from "@/components/Favicon";
import { FeedItemViewer } from "@/components/FeedItemViewer";
import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import {
	HoverCard,
	HoverCardContent,
	HoverCardTrigger,
} from "@/components/ui/hover-card";
import { Input } from "@/components/ui/input";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { Skeleton } from "@/components/ui/skeleton";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import { cleanUrlForDisplay, cleanXmlText } from "@/lib/formatting";
import { refetchFeedChannel } from "@api/fastAPI";
import type { FeedChannelId, FeedItemId } from "@api/schemas";
import {
	ArrowUpRight,
	ArrowsClockwise,
	DotOutline,
} from "@phosphor-icons/react";
import { useMediaQuery } from "@uidotdev/usehooks";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";

const FeedItems = observer(() => {
	const appContext = useAppContext();
	const params = useParams();
	const feedChannelId = params.feedChannelId as FeedChannelId;
	const feedItems = appContext.sortedFeedItemsByChannel?.get(feedChannelId);
	const [searchQuery, setSearchQuery] = useState("");
	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const matchedFeedItemIds = useMemo(() => {
		return appContext.searchFeedItemsByMetadata(searchQuery);
	}, [searchQuery]);

	let renderedItems: React.ReactNode;

	if (feedItems === undefined) {
		renderedItems = (
			<div className="h-full w-full min-w-0 overflow-y-scroll">
				<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
					<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
					<div className="flex flex-col gap-1">
						<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
					</div>
				</div>
				<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
					<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
					<div className="flex flex-col gap-1">
						<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
					</div>
				</div>
				<div className="flex w-full items-center gap-2 border-transparent border-l-4 p-2">
					<Skeleton className="h-16 w-24 shrink-0 rounded-md bg-neutral-200" />
					<div className="flex flex-col gap-1">
						<Skeleton className="h-4 w-36 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-4 w-48 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-4 w-24 shrink-0 rounded-md bg-neutral-200" />
					</div>
				</div>
			</div>
		);
	} else {
		renderedItems = (
			<div className="max-h-max min-h-0 w-full min-w-0 overflow-y-scroll">
				{feedItems
					.filter((x) => {
						if (searchQuery.trim() === "") {
							return true;
						}
						return matchedFeedItemIds.has(x.feed_item_id);
					})
					.map((item) => (
						<NavLink
							key={item.feed_item_id}
							className={({ isActive }) =>
								clsx(
									"flex w-full items-center gap-2 border-l-4 p-2",
									isActive
										? "border-blue-500 bg-blue-100"
										: "border-transparent hover:bg-blue-50",
								)
							}
							to={`/feed/${feedChannelId}/${item.feed_item_id}`}
						>
							{item.feed_item_og_image ? (
								<img
									src={item.feed_item_og_image}
									alt={item.file_name}
									className="flex h-16 w-24 shrink-0 items-center justify-center rounded object-cover text-xs"
								/>
							) : (
								<div className="flex h-16 w-24 shrink-0 items-center justify-center rounded bg-neutral-300 text-neutral-500 text-xs" />
							)}
							<div className="grpw flex w-full min-w-0 flex-col items-start">
								<h2 className="flex w-full min-w-0 items-center gap-2 truncate text-left font-semibold text-neutral-800 text-sm">
									<span className="min-w-0 truncate">
										{cleanXmlText(item.file_name)}
									</span>
									{item.feed_item_status === "pending" && (
										<Badge variant="secondary" className="shrink-0">
											Indexing
										</Badge>
									)}
								</h2>
								<h3 className="w-full min-w-0 truncate text-left text-neutral-600 text-sm">
									{cleanXmlText(item.feed_item_description ?? "")}
								</h3>
								<span className="flex w-full min-w-0 items-center gap-0.5 truncate text-left text-neutral-500 text-sm">
									<span>{cleanXmlText(item.feed_item_author ?? "")}</span>{" "}
									<DotOutline className="shrink-0" weight="fill" />
									<span>
										{dayjs(
											item.feed_item_pub_date ?? item.file_created_at,
										).format("MMM D, YYYY h:mm A")}
									</span>
									{item.feed_item_wordcount && (
										<>
											<DotOutline weight="fill" className="shrink-0" />
											<span>
												{Intl.NumberFormat().format(item.feed_item_wordcount)}{" "}
												words
											</span>
										</>
									)}
								</span>
							</div>
						</NavLink>
					))}
			</div>
		);
	}
	return (
		<>
			<div className="border-b bg-neutral-50 p-3">
				<Input
					placeholder={
						feedItems
							? `Search ${feedItems?.length ?? 0} articles...`
							: "Search articles..."
					}
					className="w-full bg-white p-2"
					value={searchQuery}
					onChange={(e) => setSearchQuery(e.target.value)}
				/>
			</div>
			{renderedItems}
		</>
	);
});

const FeedChannelHeader = observer(
	({ feedChannelId }: { feedChannelId: FeedChannelId }) => {
		const appContext = useAppContext();

		const feedChannel = appContext.feedChannelsById?.get(feedChannelId);
		const [refreshing, setRefreshing] = useState(false);

		return (
			<div
				className={clsx(
					"flex h-14 w-full shrink-0 items-center justify-between gap-2 border-b bg-white/90 px-3 backdrop-blur",
				)}
			>
				{!appContext.showSidebar && <ShowSidebarButton />}
				{feedChannel ? (
					<>
						<HoverCard>
							<HoverCardTrigger className="flex items-center gap-2 truncate text-sm">
								{feedChannel.feed_channel_link ? (
									<Favicon
										url={feedChannel.feed_channel_link}
										alt={feedChannel.file_name}
										className="h-10 w-10 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div>
									<h1 className="min-w-0 truncate font-semibold text-neutral-700">
										{feedChannel.file_name}
									</h1>
									{feedChannel.feed_channel_link && (
										<h2>
											<a
												href={feedChannel.feed_channel_link}
												target="_blank"
												rel="noreferrer"
												className="flex items-center gap-1 truncate text-blue-500 hover:underline"
											>
												{cleanUrlForDisplay(feedChannel.feed_channel_link)}
												<ArrowUpRight weight="bold" />
											</a>
										</h2>
									)}
								</div>
							</HoverCardTrigger>
							<HoverCardContent
								align="start"
								className="flex w-96 gap-3 text-sm"
							>
								{feedChannel.feed_channel_link ? (
									<Favicon
										url={feedChannel.feed_channel_link}
										alt={feedChannel.file_name}
										className="h-16 w-16 shrink-0 rounded-md bg-neutral-200"
									/>
								) : (
									<div className="h-16 w-16 shrink-0 rounded-md bg-neutral-200" />
								)}
								<div>
									<h1 className="min-w-0 truncate font-semibold text-neutral-700">
										{feedChannel.file_name}
									</h1>
									{feedChannel.feed_channel_subtitle && (
										<h1 className="min-w-0 truncate font-semibold text-neutral-700">
											{feedChannel.feed_channel_subtitle}
										</h1>
									)}
									{feedChannel.feed_channel_link && (
										<h2>
											<a
												href={feedChannel.feed_channel_link}
												target="_blank"
												rel="noreferrer"
												className="flex items-center truncate text-blue-500 hover:underline"
											>
												{cleanUrlForDisplay(feedChannel.feed_channel_link)}
												<ArrowUpRight weight="bold" />
											</a>
										</h2>
									)}
									{feedChannel.feed_channel_description && (
										<p className="mt-2 min-w-0 text-neutral-500">
											{feedChannel.feed_channel_description}
										</p>
									)}
								</div>
							</HoverCardContent>
						</HoverCard>
						<Tooltip>
							<TooltipTrigger asChild>
								<Button
									variant="ghost"
									className={clsx("text-lg text-neutral-700")}
									disabled={refreshing}
									onClick={() => {
										setRefreshing(true);
										refetchFeedChannel({
											feed_channel_id: feedChannelId,
										}).finally(() => {
											setRefreshing(false);
										});
									}}
								>
									<ArrowsClockwise
										weight="bold"
										className={clsx(refreshing && "animate-spin")}
									/>
								</Button>
							</TooltipTrigger>

							<TooltipContent>Refresh feed</TooltipContent>
						</Tooltip>
					</>
				) : (
					<>
						<Skeleton className="h-10 w-10 shrink-0 rounded-md bg-neutral-200" />
						<Skeleton className="h-6 w-48 shrink-0 rounded-md bg-neutral-200" />
					</>
				)}
			</div>
		);
	},
);

export const FeedChannel = observer(() => {
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
	const params = useParams();

	const feedItemId = params.feedItemId as FeedItemId | undefined;
	const feedChannelId = params.feedChannelId as FeedChannelId;

	const Layout = isSmallDevice ? (
		<div className="max-h-full min-h-0 w-full min-w-0 grow">
			<FeedChannelHeader feedChannelId={feedChannelId} />
			<FeedItems />
			<Drawer
				open={!!feedItemId}
				onOpenChange={(open) => {
					if (!open) {
						//
					}
				}}
			>
				<DrawerContent className="h-[90%]">
					<div
						className={
							"relative mt-4 flex h-full min-h-0 flex-col items-center"
						}
					>
						{feedItemId ? (
							<FeedItemViewer feedItemId={feedItemId} />
						) : (
							<div className="flex h-full items-center justify-center text-neutral-300">
								Open an article to view it here
							</div>
						)}
					</div>
				</DrawerContent>
			</Drawer>
		</div>
	) : (
		// Important: the min-w-0 and relative classes are necessary to make the
		// resizable panels work correctly with the toggleable sidebar
		<ResizablePanelGroup
			direction="horizontal"
			className="h-full max-h-full min-h-0 grow"
		>
			<ResizablePanel minSize={25} order={1} className="flex flex-col">
				<FeedChannelHeader feedChannelId={feedChannelId} />

				<FeedItems />
			</ResizablePanel>
			<ResizableHandle />
			<ResizablePanel minSize={25} order={2}>
				<div className="relative flex h-full max-h-full min-h-0 w-full min-w-0 flex-col items-center">
					{feedItemId ? (
						<FeedItemViewer feedItemId={feedItemId} />
					) : (
						<div className="flex h-full w-full grow items-center justify-center bg-neutral-50 text-neutral-300">
							Open an article to view it here
						</div>
					)}
				</div>
			</ResizablePanel>
		</ResizablePanelGroup>
	);

	return (
		<>
			<Helmet>
				<title>Feed - Village</title>
			</Helmet>
			<div className="flex h-full max-h-full min-h-0 grow flex-col">
				{Layout}
			</div>
		</>
	);
});
