import { CategoryColor } from "@api/schemas";

export const chooseRandomCategoryColor = () => {
	const colors = Object.values(CategoryColor);
	return colors[Math.floor(Math.random() * colors.length)];
};

export const CategoryBackgroundColors: {
	[key in CategoryColor]: string;
} = {
	[CategoryColor.gray]: "bg-gray-100",
	[CategoryColor.red]: "bg-red-100",
	[CategoryColor.orange]: "bg-orange-100",
	[CategoryColor.amber]: "bg-amber-100",
	[CategoryColor.yellow]: "bg-yellow-100",
	[CategoryColor.lime]: "bg-lime-100",
	[CategoryColor.green]: "bg-green-100",
	[CategoryColor.emerald]: "bg-emerald-100",
	[CategoryColor.teal]: "bg-teal-100",
	[CategoryColor.cyan]: "bg-cyan-100",
	[CategoryColor.sky]: "bg-sky-100",
	[CategoryColor.blue]: "bg-blue-100",
	[CategoryColor.indigo]: "bg-indigo-100",
	[CategoryColor.violet]: "bg-violet-100",
	[CategoryColor.purple]: "bg-purple-100",
	[CategoryColor.fuchsia]: "bg-fuchsia-100",
	[CategoryColor.pink]: "bg-pink-100",
	[CategoryColor.rose]: "bg-rose-100",
};
