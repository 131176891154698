import { UploadCoverImage } from "@/components/UploadCoverImage";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { useAppContext } from "@/contexts/AppContext";
import { CaretRight, X } from "@phosphor-icons/react";
import { AnimatePresence, motion } from "framer-motion";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const FileUploadsDrawer = observer(() => {
	const appContext = useAppContext();
	const [isOpen, setIsOpen] = useState(false);

	if (appContext.recentUploadsArray.length === 0) {
		return null;
	}

	const numPending = appContext.recentUploadsArray.filter(
		(upload) => upload.upload_status === "pending",
	).length;

	const allDone = numPending === 0;

	return (
		<motion.div
			initial={{ opacity: 0, y: 50 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 50 }}
			transition={{ duration: 0.3 }}
			className="absolute right-4 bottom-4 z-[100] w-[32rem] overflow-hidden rounded-lg border shadow"
		>
			<Collapsible open={isOpen} onOpenChange={setIsOpen}>
				<CollapsibleTrigger className="group roundedt-lg flex w-full items-center gap-2 bg-white px-4 py-3">
					<motion.button
						type="button"
						animate={{ rotate: isOpen ? 90 : 0 }}
						transition={{ duration: 0.2 }}
					>
						<CaretRight
							className="text-lg text-neutral-500 group-hover:text-neutral-800"
							weight="bold"
						/>
					</motion.button>
					<span className="grow text-left text-neutral-600 text-sm">
						{allDone ? (
							<>All uploads processed.</>
						) : (
							<>
								Processing {numPending} upload{numPending > 1 ? "s" : ""}
							</>
						)}
					</span>
					{allDone && (
						<button
							type="button"
							onClick={() => {
								runInAction(() => {
									appContext.recentUploads = new Map();
								});
							}}
							className="p-1 text-lg"
						>
							<X />
						</button>
					)}
				</CollapsibleTrigger>
				<AnimatePresence>
					{isOpen && (
						<CollapsibleContent forceMount asChild>
							<motion.div
								initial={{ opacity: 0, height: 0 }}
								animate={{ opacity: 1, height: "auto" }}
								exit={{ opacity: 0, height: 0 }}
								transition={{ duration: 0.15 }}
							>
								<div className="flex max-h-[50vh] flex-col gap-0.5 overflow-y-scroll border-t bg-neutral-100 px-4 py-2">
									{appContext.recentUploadsArray.map((upload) => (
										<motion.div
											key={upload.upload_id}
											initial={{ opacity: 0, x: -20 }}
											animate={{ opacity: 1, x: 0 }}
											exit={{ opacity: 0, x: -20 }}
											transition={{ duration: 0.2 }}
											className="flex items-center gap-2 px-0.5 py-1 text-sm"
										>
											<UploadCoverImage
												upload_id={upload.upload_id}
												upload_status={upload.upload_status}
												size={128}
												className={(status) =>
													status === "ready"
														? "h-4 max-w-6 shadow"
														: "h-4 text-base"
												}
											/>
											<span className="grow truncate text-left text-neutral-600">
												{upload.file_name}
											</span>
										</motion.div>
									))}
								</div>
							</motion.div>
						</CollapsibleContent>
					)}
				</AnimatePresence>
			</Collapsible>
		</motion.div>
	);
});
