import type { AppState } from "@/contexts/AppContext";
import {
	DisplayedActionError,
	createSyncedAction,
} from "@/contexts/SyncedActions";
import type { TableState } from "@/contexts/TableContext";
import {
	formatTableCellId,
	newTableColumnId,
	newTableId,
	newTableRowId,
} from "@/idGenerators";
import {
	addColumnCategoryRoute,
	changeCategoryColorRoute,
	createColumnRoute,
	createComputedTableRoute,
	createRowRoute,
	createTableRoute,
	deleteColumnRoute,
	deleteRowsRoute,
	deleteTablesRoute,
	moveColumnRoute,
	moveRowsRoute,
	removeColumnCategoryRoute,
	renameColumnCategoryRoute,
	renameTableRoute,
	resizeColumnRoute,
	updateCellRoute,
	updateColumnMetadataRoute,
} from "@api/fastAPI";
import type {
	CategoryColor,
	CategoryMetadata,
	CellValue,
	ColumnMetadata,
	Filter,
	MaterializedColumn,
	MaterializedRow,
	PrimaryColumnType,
	TableColumnId,
	TableId,
	TableMetadata,
	TableRowId,
} from "@api/schemas";
import { base62 } from "mudder";
import { toast } from "sonner";

export const createTableAction = createSyncedAction<
	AppState,
	{
		tableName: string;
		primaryColumn: {
			columnName: string;
			columnDescription: string;
			columnType: PrimaryColumnType;
		};
	},
	TableMetadata,
	TableMetadata
>({
	async local(args) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const tableId = newTableId();
		const columnId = newTableColumnId();
		const newTable: TableMetadata = {
			table_id: tableId,
			file_name: args.tableName,
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_creator_id: this.workspace.userId,
			file_deleted_at: null,
			table_primary_column_id: columnId,
			file_type: "table",
		};
		this.workspace.tables.set(tableId, newTable);
		return newTable;
	},
	async remote(args, localResult) {
		const table = await createTableRoute({
			table_id: localResult.table_id,
			table_name: args.tableName,
			table_primary_column: {
				column_id: localResult.table_primary_column_id,
				column_metadata: {
					column_name: args.primaryColumn.columnName,
					column_description: args.primaryColumn.columnDescription,
					column_type: args.primaryColumn.columnType,
				},
			},
		});
		return table.data;
	},
	rollback(_, localResult) {
		this.workspace?.tables.delete(localResult.table_id);
	},
	onRemoteSuccess(_localArgs, _localResult, remoteResult) {
		this.workspace?.tables.set(remoteResult.table_id, remoteResult);
	},
});

export const createComputedTableAction = createSyncedAction<
	AppState,
	{
		tableName: string;
		parentTableId: TableId;
		proxiedColumnIds: TableColumnId[];
		filters: Filter[];
	},
	void,
	TableMetadata
>({
	async local() {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
	},
	async remote(args) {
		const table = await createComputedTableRoute({
			table_name: args.tableName,
			parent_table_id: args.parentTableId,
			filters: args.filters,
			proxied_column_ids: args.proxiedColumnIds,
		});
		return table.data;
	},
	rollback() {},
	onRemoteSuccess(_localArgs, _localResult, remoteResult) {
		this.workspace?.tables.set(remoteResult.table_id, remoteResult);
	},
});

export const deleteTablesAction = createSyncedAction<
	AppState,
	{ tableIds: TableId[] },
	void,
	void
>({
	async local({ tableIds }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		for (const id of tableIds) {
			const table = this.workspace.tables.get(id);
			if (!table) {
				throw new Error("Table not found");
			}
			table.file_deleted_at = new Date().toISOString();
		}
	},
	async remote({ tableIds }) {
		await deleteTablesRoute({ table_ids: tableIds });
	},
	rollback({ tableIds }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		for (const id of tableIds) {
			const table = this.workspace.tables.get(id);
			if (!table) {
				throw new Error("Table not found");
			}
			table.file_deleted_at = null;
		}
	},
	onRemoteSuccess({ tableIds }) {
		toast.success(
			`Deleted ${tableIds.length} table${tableIds.length > 1 ? "s" : ""}`,
		);
	},
});

export function renameTableLocally(
	this: AppState,
	{ tableId, tableName }: { tableId: TableId; tableName: string },
) {
	if (this.workspace === null) {
		throw new Error("Workspace not loaded yet!");
	}
	const table = this.workspace.tables.get(tableId);
	if (!table) {
		throw new Error(`Table with id ${tableId} not found`);
	}
	const oldName = table.file_name;
	table.file_name = tableName;
	return { oldName, newName: tableName };
}

export const renameTableAction = createSyncedAction<
	AppState,
	{ tableId: TableId; tableName: string },
	{ oldName: string; newName: string },
	void
>({
	async local({ tableId, tableName }) {
		if (tableName.trim().length === 0) {
			throw new DisplayedActionError("Table name cannot be empty");
		}
		return this.renameTableLocally({ tableId, tableName });
	},
	async remote({ tableId, tableName }) {
		await renameTableRoute({ table_id: tableId, table_name: tableName });
	},
	rollback({ tableId }, { oldName }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const table = this.workspace.tables.get(tableId);
		if (table) {
			table.file_name = oldName;
		}
	},
	onRemoteSuccess() {
		toast.success("Table renamed successfully.");
	},
});

export const createColumnAction = createSyncedAction<
	TableState,
	{
		columnMetadata: ColumnMetadata;
	},
	MaterializedColumn,
	void
>({
	async local(args) {
		this.checkEditable();

		const columnId = newTableColumnId();

		if (!args.columnMetadata.column_name.trim()) {
			throw new DisplayedActionError("Column name cannot be empty");
		}
		if (!args.columnMetadata.column_description.trim()) {
			throw new DisplayedActionError("Column description cannot be empty");
		}

		const newColumn: MaterializedColumn = {
			column_metadata: args.columnMetadata,
			column_id: columnId,
			column_order: base62.mudder(this.lastColumn?.column_order ?? "", "")[0],
			column_width: null,
		};
		this.table.columns.set(columnId, newColumn);
		return newColumn;
	},
	async remote(_, localResult) {
		await createColumnRoute({
			table_id: this.tableId,
			table_column_id: localResult.column_id,
			table_column_metadata: localResult.column_metadata,
		});
	},
	rollback(_, localResult) {
		toast.error("Failed to create column");
		this.table.columns.delete(localResult.column_id);
	},
});

export const createRowAction = createSyncedAction<
	TableState,
	void,
	MaterializedRow,
	void
>({
	async local() {
		this.checkEditable();

		const rowId = newTableRowId();

		const newRow: MaterializedRow = {
			row_id: rowId,
			row_metadata: {
				row_type: "root",
			},
			row_order: base62.mudder(this.lastRow?.row_order ?? "", "")[0],
		};
		this.table.rows.set(rowId, newRow);
		return newRow;
	},
	async remote(_, localResult) {
		await createRowRoute({
			table_id: this.tableId,
			table_row_id: localResult.row_id,
			table_row_metadata: localResult.row_metadata,
		});
	},
	rollback(_, localResult) {
		toast.error("Failed to create row");
		this.table.rows.delete(localResult.row_id);
	},
});

export const deleteColumnAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
	},
	{ oldColumn: MaterializedColumn },
	void
>({
	async local({ columnId }) {
		this.checkEditable();

		const oldColumn = this.table.columns.get(columnId);
		if (!oldColumn) {
			throw new DisplayedActionError("Column not found");
		}
		this.table.columns.delete(columnId);
		return { oldColumn };
	},
	async remote(localArgs) {
		await deleteColumnRoute({
			table_id: this.tableId,
			table_column_id: localArgs.columnId,
		});
	},
	rollback({ columnId }, { oldColumn }) {
		toast.error("Failed to delete column");
		this.table.columns.set(columnId, oldColumn);
	},
});

export const updateColumnMetadataAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		columnName: string | null;
		columnDescription: string | null;
	},
	{
		oldColumn: MaterializedColumn;
	},
	void
>({
	async local({ columnId, columnName, columnDescription }) {
		this.checkEditable();

		const column = this.table.columns.get(columnId);

		if (!column) {
			throw new DisplayedActionError("Column not found");
		}

		const oldColumn = { ...column };
		column.column_metadata = {
			...column.column_metadata,
			column_name: columnName ?? column.column_metadata.column_name,
			column_description:
				columnDescription ?? column.column_metadata.column_description,
		};
		return { oldColumn };
	},
	async remote({ columnId, columnName, columnDescription }) {
		await updateColumnMetadataRoute({
			table_id: this.tableId,
			column_id: columnId,
			column_name: columnName,
			column_description: columnDescription,
		});
	},
	rollback(localArgs, { oldColumn }) {
		toast.error("Failed to update column metadata");
		this.table.columns.set(localArgs.columnId, oldColumn);
	},
});

export const deleteRowsAction = createSyncedAction<
	TableState,
	{
		rowIds: Set<TableRowId>;
	},
	{
		deletedRowMetadata: Map<TableRowId, MaterializedRow>;
	},
	void
>({
	async local({ rowIds }) {
		this.checkEditable();

		const deletedRowMetadata: Map<TableRowId, MaterializedRow> = new Map();
		const deletedRows: Map<
			TableRowId,
			Map<TableColumnId, CellValue>
		> = new Map();
		for (const rowId of rowIds) {
			const rowMetadata = this.table.rows.get(rowId);
			if (!rowMetadata) {
				throw new DisplayedActionError("Row not found");
			}
			deletedRowMetadata.set(rowId, { ...rowMetadata });
			deletedRows.set(rowId, new Map());
			this.table.rows.delete(rowId);
		}
		return { deletedRowMetadata, deletedRows };
	},
	async remote(localArgs) {
		await deleteRowsRoute({
			table_id: this.tableId,
			table_row_ids: [...localArgs.rowIds],
		});
	},
	rollback(_, { deletedRowMetadata }) {
		toast.error("Failed to delete rows");
		for (const [rowId, row] of deletedRowMetadata) {
			this.table.rows.set(rowId, row);
		}
	},
});

export const moveRowsAction = createSyncedAction<
	TableState,
	{
		rowIds: Set<TableRowId>;
		insertingBeforeRowId: TableRowId | null;
	},
	{ oldRowOrders: { rowId: TableRowId; rowOrder: string }[] },
	void
>({
	async local({ rowIds, insertingBeforeRowId }) {
		this.checkEditable();

		let insertingAfterRowOrder: string;
		let insertingBeforeRowOrder: string;

		if (insertingBeforeRowId) {
			const insertingBeforeRow = this.getRowById(insertingBeforeRowId);

			const rowToInsertAfter = this.getRowBefore(insertingBeforeRow.row_id);

			// the row to insert after might be null if we are moving to the top
			insertingAfterRowOrder = rowToInsertAfter?.row_order ?? "";
			insertingBeforeRowOrder = insertingBeforeRow.row_order;
		} else {
			// if no row to insert before, assume we are moving to the end
			const rowToInsertAfter = this.lastRow;

			if (!rowToInsertAfter) {
				throw new DisplayedActionError("No rows to insert after");
			}

			insertingAfterRowOrder = rowToInsertAfter.row_order;
			insertingBeforeRowOrder = "";
		}

		const rowsToMove = [...rowIds]
			.map((rowId) => {
				const row = this.getRowById(rowId);
				if (!row) {
					throw new DisplayedActionError("Row not found");
				}
				return row;
			})
			.sort((a, b) => a.row_order.localeCompare(b.row_order));

		// Store the old row orders for rollback
		const oldRowOrders = rowsToMove.map((row) => ({
			rowId: row.row_id,
			rowOrder: row.row_order,
		}));

		if (
			rowIds.size === 1 &&
			insertingBeforeRowId &&
			rowIds.has(insertingBeforeRowId)
		) {
			throw new DisplayedActionError("Cannot move a row before itself");
		}

		const newRowOrders = base62.mudder(
			insertingAfterRowOrder,
			insertingBeforeRowOrder,
			rowsToMove.length,
		);

		for (let i = 0; i < rowsToMove.length; i++) {
			const row = rowsToMove[i];
			row.row_order = newRowOrders[i];
		}
		return { oldRowOrders };
	},
	async remote({ rowIds, insertingBeforeRowId }) {
		await moveRowsRoute({
			table_id: this.tableId,
			row_ids: [...rowIds],
			inserting_before_row_id: insertingBeforeRowId,
		});
	},
	async rollback(_, { oldRowOrders }) {
		for (const { rowId, rowOrder } of oldRowOrders) {
			const row = this.table.rows.get(rowId);
			if (row) {
				row.row_order = rowOrder;
			} else {
				console.error(`Row with ID ${rowId} not found during rollback.`);
			}
		}
	},
});

export const moveColumnAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		insertingBeforeColumnId: TableColumnId | null;
	},
	{ oldColumnOrder: string },
	void
>({
	async local({ columnId, insertingBeforeColumnId }) {
		this.checkEditable();

		let insertingAfterColumnOrder: string;
		let insertingBeforeColumnOrder: string;

		if (columnId === insertingBeforeColumnId) {
			throw new DisplayedActionError("Cannot move a column before itself");
		}

		if (insertingBeforeColumnId) {
			const insertingBeforeColumn = this.getColumnById(insertingBeforeColumnId);

			const insertingAfterColumn = this.getColumnBefore(
				insertingBeforeColumn.column_id,
			);

			// The column to insert after might be null if we are moving to the first position
			insertingAfterColumnOrder = insertingAfterColumn?.column_order ?? "";
			insertingBeforeColumnOrder = insertingBeforeColumn.column_order;
		} else {
			// If no column to insert before, assume we are moving to the end
			const insertingAfterColumn = this.lastColumn;

			if (!insertingAfterColumn) {
				throw new DisplayedActionError("No columns to insert after");
			}

			insertingAfterColumnOrder = insertingAfterColumn.column_order;
			insertingBeforeColumnOrder = "";
		}

		const columnToMove = this.getColumnById(columnId);
		if (!columnToMove) {
			throw new DisplayedActionError("Column not found");
		}

		// Store the old column order for rollback
		const oldColumnOrder = columnToMove.column_order;

		const newColumnOrder = base62.mudder(
			insertingAfterColumnOrder,
			insertingBeforeColumnOrder,
			1,
		)[0];

		columnToMove.column_order = newColumnOrder;

		return { oldColumnOrder };
	},
	async remote({ columnId, insertingBeforeColumnId }) {
		await moveColumnRoute({
			table_id: this.tableId,
			column_id: columnId,
			inserting_before_column_id: insertingBeforeColumnId,
		});
	},
	async rollback({ columnId }, { oldColumnOrder }) {
		const columnToMove = this.getColumnById(columnId);

		if (!columnToMove) {
			throw new DisplayedActionError("Column not found during rollback");
		}

		columnToMove.column_order = oldColumnOrder;
	},
});

export const resizeColumnAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		newWidth: number;
	},
	{
		oldWidth: number | null;
	},
	void
>({
	async local({ columnId, newWidth }) {
		this.checkEditable();

		const column = this.table.columns.get(columnId);

		if (!column) {
			throw new DisplayedActionError("Column not found");
		}

		const oldWidth = column.column_width;

		column.column_width = newWidth;
		return { oldWidth };
	},
	async remote({ columnId, newWidth }) {
		await resizeColumnRoute({
			table_id: this.tableId,
			column_id: columnId,
			column_width: newWidth,
		});
	},
	rollback({ columnId }, { oldWidth }) {
		const column = this.table.columns.get(columnId);

		if (!column) {
			throw new DisplayedActionError("Column not found during rollback");
		}

		if (oldWidth !== null) {
			column.column_width = oldWidth;
		} else {
			column.column_width = null;
		}
	},
});

export const updateCellAction = createSyncedAction<
	TableState,
	{
		rowId: TableRowId;
		columnId: TableColumnId;
		value: CellValue;
	},
	{
		oldValue: CellValue | undefined;
	},
	void
>({
	async local({ rowId, columnId, value }) {
		this.checkEditable();

		const oldValue = this.table.cellValues.get(
			formatTableCellId({ rowId, columnId }),
		);
		this.table.cellValues.set(formatTableCellId({ rowId, columnId }), value);

		return { oldValue };
	},
	async remote(localArgs, { oldValue }) {
		if (localArgs.value.cell_value === oldValue?.cell_value) {
			return;
		}
		await updateCellRoute({
			table_id: this.tableId,
			table_row_id: localArgs.rowId,
			table_column_id: localArgs.columnId,
			table_cell_value: localArgs.value,
		});
	},
	rollback(localArgs, { oldValue }) {
		if (oldValue) {
			this.table.cellValues.set(
				formatTableCellId({
					rowId: localArgs.rowId,
					columnId: localArgs.columnId,
				}),
				oldValue,
			);
		} else {
			this.table.cellValues.delete(
				formatTableCellId({
					rowId: localArgs.rowId,
					columnId: localArgs.columnId,
				}),
			);
		}
	},
});

export const addColumnCategoryAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		newCategory: { value: string; color: CategoryColor };
		cellToSet: { rowId: TableRowId } | null;
	},
	{
		oldColumn: MaterializedColumn;
	},
	void
>({
	async local({ columnId, newCategory, cellToSet }) {
		this.checkEditable();

		if (newCategory.value.trim().length === 0) {
			throw new DisplayedActionError("Category value cannot be empty");
		}

		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		column.column_metadata.categories[newCategory.value] = newCategory;
		if (cellToSet) {
			this.table.cellValues.set(
				formatTableCellId({
					rowId: cellToSet.rowId,
					columnId,
				}),
				{
					cell_value_type: "category",
					cell_value: newCategory.value,
				},
			);
		}

		return { oldColumn: { ...column } };
	},
	async remote({ columnId, newCategory, cellToSet }) {
		await addColumnCategoryRoute({
			table_id: this.tableId,
			column_id: columnId,
			new_category: newCategory,
			cell_to_set: cellToSet
				? {
						row_id: cellToSet.rowId,
					}
				: null,
		});
	},
	rollback(localArgs, { oldColumn }) {
		const column = this.table.columns.get(localArgs.columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		if (oldColumn.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		column.column_metadata.categories = oldColumn.column_metadata.categories;
	},
});

export const renameColumnCategoryAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		oldCategory: string;
		newCategory: string;
	},
	{
		oldColumn: MaterializedColumn;
	},
	void
>({
	async local({ columnId, oldCategory, newCategory }) {
		this.checkEditable();

		if (newCategory.trim().length === 0) {
			throw new DisplayedActionError("Category value cannot be empty");
		}
		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		const oldCategoryData = column.column_metadata.categories[oldCategory];
		if (!oldCategoryData) {
			throw new DisplayedActionError("Category not found");
		}
		column.column_metadata.categories[newCategory] = {
			...oldCategoryData,
			value: newCategory,
		};
		delete column.column_metadata.categories[oldCategory];

		for (const rowId of this.table.rows.keys()) {
			const cell = this.table.cellValues.get(
				formatTableCellId({ rowId, columnId }),
			);
			if (!cell) {
				continue;
			}
			if (
				cell.cell_value_type === "category" &&
				cell.cell_value === oldCategory
			) {
				cell.cell_value = newCategory;
			}
		}

		return { oldColumn: { ...column } };
	},
	async remote({ columnId, oldCategory, newCategory }) {
		await renameColumnCategoryRoute({
			table_id: this.tableId,
			column_id: columnId,
			old_category: oldCategory,
			new_category: newCategory,
		});
	},
	rollback(localArgs, { oldColumn }) {
		const column = this.table.columns.get(localArgs.columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		if (oldColumn.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		column.column_metadata.categories = oldColumn.column_metadata.categories;

		// revert cells
		for (const rowId of this.table.rows.keys()) {
			const cell = this.table.cellValues.get(
				formatTableCellId({ rowId, columnId: localArgs.columnId }),
			);

			if (!cell) {
				continue;
			}
			if (
				cell.cell_value_type === "category" &&
				cell.cell_value === localArgs.newCategory
			) {
				cell.cell_value = localArgs.oldCategory;
			}
		}
	},
});

export const removeColumnCategoryAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		categoryToRemove: string;
	},
	{
		oldCategory: CategoryMetadata;
		affectedRows: Map<TableRowId, CellValue>;
	},
	void
>({
	async local({ columnId, categoryToRemove }) {
		this.checkEditable();

		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}

		const oldCategory = column.column_metadata.categories[categoryToRemove];
		if (!oldCategory) {
			throw new DisplayedActionError("Category not found");
		}

		// Remove the category from the options
		delete column.column_metadata.categories[categoryToRemove];

		// Store affected cells for rollback
		const affectedRows: Map<TableRowId, CellValue> = new Map();

		// Remove category from cells
		for (const rowId of this.table.rows.keys()) {
			const cellValue = this.table.cellValues.get(
				formatTableCellId({ rowId, columnId }),
			);
			if (
				cellValue &&
				cellValue.cell_value_type === "category" &&
				cellValue.cell_value === categoryToRemove
			) {
				affectedRows.set(rowId, cellValue);
				cellValue.cell_value = null;
			}
		}

		return { oldCategory, affectedRows };
	},
	async remote({ columnId, categoryToRemove }) {
		await removeColumnCategoryRoute({
			table_id: this.tableId,
			column_id: columnId,
			category_to_remove: categoryToRemove,
		});
	},
	rollback({ columnId, categoryToRemove }, { oldCategory, affectedRows }) {
		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}

		// Restore the old options
		if (!column.column_metadata.categories[categoryToRemove]) {
			column.column_metadata.categories[categoryToRemove] = oldCategory;
		}

		// Restore the cells
		for (const [rowId, cellValue] of affectedRows) {
			const cell = this.table.cellValues.get(
				formatTableCellId({ rowId, columnId }),
			);

			if (cell) {
				cell.cell_value = cellValue.cell_value;
			}
		}
		toast.error("Failed to remove category");
	},
});

export const changeCategoryColorAction = createSyncedAction<
	TableState,
	{
		columnId: TableColumnId;
		categoryValue: string;
		newColor: CategoryColor;
	},
	{
		oldCategory: CategoryMetadata;
	},
	void
>({
	async local({ columnId, categoryValue, newColor }) {
		this.checkEditable();

		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		const category = column.column_metadata.categories[categoryValue];
		if (!category) {
			throw new DisplayedActionError("Category not found in column");
		}
		const oldCategory = { ...category };
		category.color = newColor;
		return { oldCategory };
	},
	async remote({ columnId, categoryValue, newColor }) {
		await changeCategoryColorRoute({
			table_id: this.tableId,
			column_id: columnId,
			category: categoryValue,
			color: newColor,
		});
	},
	rollback({ columnId, categoryValue }, { oldCategory }) {
		const column = this.table.columns.get(columnId);
		if (!column) {
			throw new DisplayedActionError("Column not found");
		}
		if (column.column_metadata.column_type !== "category") {
			throw new DisplayedActionError("Column is not a category");
		}
		const category = column.column_metadata.categories[categoryValue];
		if (!category) {
			throw new DisplayedActionError("Category not found in column");
		}
		category.color = oldCategory.color;
		toast.error("Failed to change category color");
	},
	onRemoteSuccess() {
		toast.success("Category color updated successfully");
	},
});
