import { FeedItemViewer } from "@/components/FeedItemViewer";
import { SearchPDFViewer } from "@/components/PDFViewer";
import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { Command } from "@/components/ui/command";
import { Drawer, DrawerContent } from "@/components/ui/drawer";
import {
	ResizableHandle,
	ResizablePanel,
	ResizablePanelGroup,
} from "@/components/ui/resizable";
import { useAppContext } from "@/contexts/AppContext";
import {
	type SearchLibraryResultWithUpload,
	SearchProvider,
	useSearchContext,
} from "@/contexts/SearchContext";
import {
	SEARCH_RESULTS_CONTAINER_ID,
	SearchBody,
} from "@/pages/Search/SearchBody";
import { SearchComboboxCommandList } from "@/pages/Search/SearchComboboxCommandList";
import { SearchSettings } from "@/pages/Search/SearchSettings";
import type { SearchFeedItemsResultOutput as SearchFeedItemsResult } from "@api/schemas";
import { useMediaQuery } from "@uidotdev/usehooks";
import clsx from "clsx";
import { Command as CommandPrimitive } from "cmdk";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { BarLoader } from "react-spinners";

const SearchHeader = observer(() => {
	const searchContext = useSearchContext();
	const appContext = useAppContext();
	const inputRef = useRef<HTMLInputElement>(null);
	const dropdownRef = useRef<HTMLDivElement>(null);

	const { query, searchLoading } = searchContext;

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleClickOutside = useCallback((event: MouseEvent) => {
		if (
			inputRef.current &&
			!inputRef.current.contains(event.target as Node) &&
			dropdownRef.current &&
			!dropdownRef.current.contains(event.target as Node)
		) {
			searchContext.setShowCommandList(false);
		}
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const handleEscapeKey = useCallback((event: KeyboardEvent) => {
		if (event.key === "Escape") {
			searchContext.setShowCommandList(false);
			if (inputRef.current) {
				inputRef.current.blur();
			}
		}
	}, []);

	useEffect(() => {
		document.addEventListener("mousedown", handleClickOutside);
		document.addEventListener("keydown", handleEscapeKey);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
			document.removeEventListener("keydown", handleEscapeKey);
		};
	}, [handleClickOutside, handleEscapeKey]);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (inputRef.current) {
			searchContext.searchInputElement = inputRef.current;
		}
	}, [inputRef.current]);

	return (
		<div className="border-b">
			<div className="relative w-full min-w-0 px-4">
				<div className="flex w-full min-w-0 gap-1 pt-4">
					{!appContext.showSidebar && <ShowSidebarButton />}
					<div className="relative w-full min-w-0">
						<CommandPrimitive.Input
							placeholder="Search"
							value={query}
							className={clsx(
								"flex w-full grow items-center rounded-lg border py-2 pr-1 pl-3 outline-none ring-1",
								searchContext.showCommandList
									? "border-blue-300 ring-blue-100"
									: "ring-transparent",
							)}
							onValueChange={(value) => {
								runInAction(() => {
									searchContext.query = value;
								});
							}}
							onFocus={() => searchContext.setShowCommandList(true)}
							ref={inputRef}
						/>
						{(searchContext.searchResults || searchContext.searchLoading) && (
							<div
								ref={dropdownRef}
								className={clsx(
									"absolute right-0 left-0 z-10 mt-2 max-h-[300px] overflow-y-auto rounded-lg border border-neutral-200 bg-white shadow-lg",
									searchContext.showCommandList ? "block" : "hidden",
								)}
							>
								<SearchComboboxCommandList
									setShowCommandList={(show) => {
										searchContext.setShowCommandList(show);
									}}
									disabled={!searchContext.showCommandList}
								/>
							</div>
						)}
					</div>
				</div>

				<SearchSettings />
			</div>
			<div className="h-[2px]">
				{searchLoading && (
					<BarLoader color={"black"} loading={true} height={2} width={"100%"} />
				)}
			</div>
		</div>
	);
});

const ActiveSearchResult = observer(
	({
		activeSearchResult,
	}: {
		activeSearchResult: SearchLibraryResultWithUpload | SearchFeedItemsResult;
	}) => {
		if (activeSearchResult.type === "library") {
			return (
				<SearchPDFViewer
					upload={activeSearchResult.upload}
					highlight={{
						textToHighlight: {
							textStart: activeSearchResult.chunk_text,
						},
						pageIndicesToSearch: activeSearchResult.chunk_page_indices,
					}}
				/>
			);
		}

		if (activeSearchResult.type === "feed_items") {
			return (
				<div className="relative flex h-full max-h-full min-h-0 w-full min-w-0 flex-col items-center">
					<FeedItemViewer feedItemId={activeSearchResult.feed_item_id} />
				</div>
			);
		}

		return <></>;
	},
);

const _Search = observer(() => {
	const searchContext = useSearchContext();
	const { activeSearchResult } = searchContext;

	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	if (isSmallDevice) {
		return (
			<Command
				id={SEARCH_RESULTS_CONTAINER_ID}
				className="flex min-h-0 grow flex-col rounded-none outline-none"
			>
				<SearchHeader />
				<SearchBody />
				<Drawer
					open={!!activeSearchResult}
					onOpenChange={(open) => {
						if (!open) {
							runInAction(() => {
								searchContext.activeSearchResult = null;
							});
						}
					}}
				>
					<DrawerContent className="h-[90%]">
						<div
							className={
								"relative mt-4 flex h-full min-h-0 flex-col items-center"
							}
						>
							{activeSearchResult ? (
								<ActiveSearchResult activeSearchResult={activeSearchResult} />
							) : (
								<></>
							)}
						</div>
					</DrawerContent>
				</Drawer>
			</Command>
		);
	}

	return (
		// `min-h-0` is used to allow the flex children to grow to fill the container
		// otherwise, this will overflow the screen height
		<ResizablePanelGroup
			direction="horizontal"
			className="flex min-h-0 w-screen grow"
		>
			<ResizablePanel
				defaultSize={40}
				minSize={30}
				className="flex h-full flex-col"
			>
				<Command
					id={SEARCH_RESULTS_CONTAINER_ID}
					className="rounded-none outline-none"
				>
					<SearchHeader />
					<SearchBody />
				</Command>
			</ResizablePanel>
			<ResizableHandle className="h-full bg-neutral-200 hover:bg-neutral-300" />
			<ResizablePanel minSize={10} className="min-h-0">
				{activeSearchResult ? (
					<ActiveSearchResult activeSearchResult={activeSearchResult} />
				) : (
					<div className="flex h-full items-center justify-center">
						<h1 className="px-4 text-center text-neutral-400">
							Open a result to view it here
						</h1>
					</div>
				)}
			</ResizablePanel>
		</ResizablePanelGroup>
	);
});

export const Search = () => {
	return (
		<SearchProvider>
			<Helmet>
				<title>Search - Village</title>
			</Helmet>
			<_Search />
		</SearchProvider>
	);
};
