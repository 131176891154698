import dayjs from "dayjs";

import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { isObservable, toJS } from "mobx";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("UTC");

export const API_ENDPOINT_HTTP =
	import.meta.env.VITE_APP_API_BASE_URL ?? "http://localhost:8000";

export const API_ENDPOINT_WS =
	import.meta.env.VITE_APP_API_ENDPOINT_WS ?? "ws://localhost:8000";

export const IS_DEV = import.meta.env.DEV;

export const VITE_APP_ENV = import.meta.env.VITE_APP_ENV as
	| "development"
	| "staging"
	| "production";

export const CLERK_PUBLISHABLE_KEY =
	import.meta.env.VITE_APP_CLERK_PUBLISHABLE_KEY ??
	new Error("Missing Clerk Publishable Key");

const originalConsoleLog = console.log;

console.log = (...args) => {
	// Process each argument
	const processedArgs = args.map((arg) => {
		// Check if the argument is a MobX observable
		if (isObservable(arg)) {
			// Convert the observable to a plain JavaScript object
			return toJS(arg);
		}
		// Return the argument as is if it's not an observable
		return arg;
	});

	// Call the original console.log with the processed arguments
	originalConsoleLog.apply(console, processedArgs);
};
