import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { useAppContext } from "@/contexts/AppContext";
import { TableProvider, useTableContext } from "@/contexts/TableContext";
import { formatRelativeDate } from "@/lib/formatting";
import { BaseCellRenderer } from "@/pages/Table/Cells/CellRenderer";
import { ProxiedCell } from "@/pages/Table/Cells/ProxiedCell";
import { ColumnCreationHeader } from "@/pages/Table/ColumnCreationHeader";
import { getColumnHeader } from "@/pages/Table/columnHeaders";
import { useGetTableLatestVersion } from "@api/fastAPI";
import type {
	TableColumnId,
	TableId,
	TableMetadata,
	TableRowId,
} from "@api/schemas";
import {
	DndContext,
	type DragEndEvent,
	type DragOverEvent,
	DragOverlay,
	type DragStartEvent,
	type Modifier,
	MouseSensor,
	TouchSensor,
	useDraggable,
	useDroppable,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	horizontalListSortingStrategy,
	useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import {
	DotsSixVertical,
	Plus,
	RectangleDashed,
	Trash,
} from "@phosphor-icons/react";
import {
	type Header,
	type Row,
	type RowSelectionState,
	type TableState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { computed } from "mobx";
import { observer } from "mobx-react-lite";
import {
	type CSSProperties,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { Helmet } from "react-helmet";
import { NavLink, Navigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const columnHelper = createColumnHelper<{ rowId: TableRowId }>();

const restrictAxisByType: Modifier = ({ active, transform }) => {
	if (!active) return transform;
	const type = active.data.current?.type;
	if (type === "column") {
		return {
			...transform,
			y: 0,
		};
	}
	if (type === "row") {
		return {
			...transform,
			x: 0,
		};
	}
	return transform;
};

const selectorColumn = columnHelper.display({
	id: "select",
	enableResizing: false,
	header: ({ table }) => {
		const tableContext = useTableContext();
		<div className="flex h-full w-full items-center justify-end pr-2 pl-0.5">
			<Checkbox
				disabled={!tableContext.editable}
				checked={
					table.getIsAllRowsSelected()
						? true
						: table.getIsSomeRowsSelected()
							? "indeterminate"
							: false
				}
				onCheckedChange={(checked) =>
					table.toggleAllRowsSelected(checked === true)
				}
			/>
		</div>;
	},
	cell: ({ row }) => {
		const tableContext = useTableContext();
		const { attributes, listeners, setNodeRef } = useDraggable({
			id: row.original.rowId,
			data: {
				type: "row",
			},
			disabled: !tableContext.editable,
			// No need to pass selectedRowIds here since we access them from state directly
		});

		return (
			<div
				className="flex h-8 w-full items-center justify-between gap-1 pr-2"
				onClick={(e) => {
					e.stopPropagation();
				}}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						e.stopPropagation();
					}
				}}
			>
				<button
					className={clsx(
						"flex text-lg text-neutral-500 opacity-0",
						tableContext.editable && "group-hover/table-row:opacity-100",
					)}
					ref={setNodeRef}
					{...attributes}
					{...listeners}
				>
					<DotsSixVertical weight="bold" />
				</button>
				<Checkbox
					disabled={!tableContext.editable}
					checked={row.getIsSelected()}
					onCheckedChange={(checked) => row.toggleSelected(checked === true)}
					onClick={(e) => {
						e.stopPropagation();
					}}
				/>
			</div>
		);
	},
	size: 48,
	maxSize: 48,
});

const DraggableRow = observer(
	({
		row,
		overId,
		draggingIds,
	}: {
		row: Row<{
			rowId: TableRowId;
		}>;
		overId: TableRowId | null;
		draggingIds: Set<TableRowId> | null;
	}) => {
		const { setNodeRef } = useDroppable({
			id: row.original.rowId,
			data: {
				type: "row",
			},
		});

		const isDragging = draggingIds?.has(row.original.rowId);

		const style: CSSProperties = {
			opacity: isDragging ? 0.5 : 1,
		};

		return (
			<>
				{overId === row.original.rowId && (
					<div
						key={`drop-indicator-${row.id}`}
						className="z-10 h-0 w-full p-0 ring-2 ring-blue-500"
					/>
				)}
				<div
					aria-label="tr"
					className={clsx(
						"flex cursor-pointer",
						row.getIsSelected() ? "bg-blue-50" : "",
						"group/table-row",
					)}
					ref={setNodeRef}
					style={style}
				>
					{row.getVisibleCells().map((cell) => (
						<div
							aria-label="td"
							key={cell.id}
							className={clsx(
								// a set height is required to have the cell contents use height: 100%
								// without this, the cell contents will be vertically centered
								"whitespace-break-space flex items-center border-b p-0",
								cell.column.id !== "add_column" && "border-r",
							)}
							style={{
								width: `calc(var(--col-${cell.column.id}-size) * 1px)`,
							}}
						>
							{flexRender(cell.column.columnDef.cell, cell.getContext())}
						</div>
					))}
				</div>
			</>
		);
	},
);

const DraggableHeader = observer(
	({
		header,
	}: {
		header: Header<
			{
				rowId: TableRowId;
			},
			unknown
		>;
	}) => {
		const {
			attributes,
			isDragging,
			listeners,
			setNodeRef,
			transform,
			transition,
		} = useSortable({
			id: header.column.id,
			data: {
				type: "column",
			},
		});

		const style: CSSProperties = {
			opacity: isDragging ? 0.5 : 1,
			transform: CSS.Transform.toString(transform),
			transition,
			whiteSpace: "nowrap",
			// width: header.column.getSize(),
			width: `calc(var(--header-${header.id}-size) * 1px)`,
			zIndex: isDragging ? 1 : 0,
		};

		return (
			<div
				aria-label="th"
				className={clsx(
					"relative z-10 h-8 border-b p-0 text-left font-semibold",
					header.column.id !== "create_column" && "border-t border-r",
				)}
				style={style}
			>
				<div
					className="relative z-10 flex h-full min-w-0 grow"
					ref={setNodeRef}
					{...attributes}
					{...listeners}
				>
					{header.isPlaceholder
						? null
						: flexRender(header.column.columnDef.header, header.getContext())}
				</div>
				{header.id !== "select" && header.id !== "create_column" ? (
					<button
						{...{
							onDoubleClick: () => header.column.resetSize(),
							onMouseDown: header.getResizeHandler(),
							onTouchStart: header.getResizeHandler(),
							className: `h-full cursor-ew-resize	 absolute right-0 z-20 w-1 top-0 bg-blue-500 opacity-0 hover:opacity-100 ${
								header.column.getIsResizing() ? "opacity-100" : ""
							}`,
						}}
					/>
				) : null}
			</div>
		);
	},
);

// from https://github.com/TanStack/table/discussions/2498#discussioncomment-8649218
export const useResizeObserver = (
	state: TableState,
	callback: (columnId: string, columnSize: number) => void,
) => {
	// This Ref will contain the id of the column being resized or undefined
	const columnResizeRef = useRef<string | false>();
	useEffect(() => {
		// We are interested in calling the resize event only when "state.columnResizingInfo?.isResizingColumn" changes from
		// a string to false, because it indicates that it WAS resizing but it no longer is.
		if (
			state.columnSizingInfo &&
			!state.columnSizingInfo?.isResizingColumn &&
			columnResizeRef.current
		) {
			// Trigger resize event
			callback(
				columnResizeRef.current,
				state.columnSizing[columnResizeRef.current],
			);
		}
		columnResizeRef.current = state.columnSizingInfo?.isResizingColumn;
	}, [callback, state.columnSizingInfo, state.columnSizing]);
};

export const TableView = observer(() => {
	const tableContext = useTableContext();
	const { table: tableData } = tableContext;
	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	const columns = useMemo(
		() =>
			computed(() => [
				selectorColumn,
				...[...tableContext.sortedColumns.values()].map((column) => {
					return columnHelper.display({
						id: column.column_id,
						header: getColumnHeader({
							column,
							isPrimary: column.column_id === tableData.primaryColumnId,
						}),
						cell: observer(({ row: tanstackRow, column: tanstackColumn }) => {
							const tableContext = useTableContext();
							const rowId = tanstackRow.original.rowId;
							const columnId = tanstackColumn.id as TableColumnId;

							const columnMetadata = column.column_metadata;
							const cellValue = tableContext.getCellValue(rowId, columnId);

							if (columnMetadata.column_type === "proxy") {
								return (
									<ProxiedCell
										rowId={rowId}
										columnId={columnId}
										cellValue={cellValue}
										columnMetadata={columnMetadata}
										isProxy={true}
									/>
								);
							}
							if (columnMetadata.column_type === "proxy_group") {
								throw new Error("Proxy group columns are not supported");
							}
							return (
								<BaseCellRenderer
									rowId={rowId}
									columnId={columnId}
									cellValue={cellValue}
									columnMetadata={columnMetadata}
									isProxy={false}
								/>
							);
						}),
						size: column.column_width ?? 320,
					});
				}),
				columnHelper.display({
					id: "create_column",
					header: ColumnCreationHeader,
				}),
			]),
		[tableContext.sortedColumns, tableData.primaryColumnId],
	).get();

	const columnOrder = useMemo(
		() => computed(() => tableContext.sortedColumns.map((x) => x.column_id)),
		[tableContext.sortedColumns],
	).get();

	const rows = useMemo(
		() =>
			computed(() =>
				tableContext.filteredSortedRows.map((row) => {
					return {
						rowId: row.row_id,
					};
				}),
			),
		[tableContext.filteredSortedRows],
	).get();

	// State for active drag item
	const [activeId, setActiveId] = useState<
		| { type: "row"; id: TableRowId }
		| { type: "column"; id: TableColumnId }
		| null
	>(null);
	const [overId, setOverId] = useState<
		| { type: "row"; id: TableRowId }
		| { type: "column"; id: TableColumnId }
		| null
	>(null);
	const [draggingIds, setDraggingIds] = useState<Set<TableRowId> | null>(null);

	const table = useReactTable({
		data: rows,
		columns,
		getCoreRowModel: getCoreRowModel(),
		enableRowSelection: tableContext.editable,
		onRowSelectionChange: setRowSelection,
		state: {
			rowSelection,
		},
		getRowId: (row) => row.rowId,
		enableColumnResizing: tableContext.editable,
		columnResizeMode: "onChange",
		defaultColumn: {
			minSize: 60,
			maxSize: 800,
		},
	});

	useResizeObserver(table.getState(), (columnId, columnSize) => {
		tableContext.resizeColumn({
			columnId: columnId as TableColumnId,
			newWidth: columnSize,
		});
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const selectedRowIds = useMemo(() => {
		return new Set(
			table.getSelectedRowModel().rows.map((row) => row.original.rowId),
		);
	}, [rowSelection]);

	// Handle drag start
	function handleDragStart(event: DragStartEvent) {
		const activeId = event.active.id;
		const type = event.active.data.current?.type;

		if (type === "column") {
			setActiveId({ type: "column", id: activeId as TableColumnId });
		} else if (type === "row") {
			const draggedId = activeId;
			// Get selected ids from the current state
			setActiveId({ type: "row", id: draggedId as TableRowId });

			const draggingIds = selectedRowIds.has(draggedId as TableRowId)
				? selectedRowIds
				: new Set([draggedId]);
			setDraggingIds(draggingIds as Set<TableRowId>);
		}
	}

	// Handle drag over
	function handleDragOver(event: DragOverEvent) {
		const type = event.active.data.current?.type;
		const over = event.over;
		if (!over) return;

		if (type === "column") {
			setOverId({
				type: "column",
				id: over.id as TableColumnId,
			});
		} else if (type === "row") {
			setOverId({
				type: "row",
				id: over.id as TableRowId,
			});
		}
	}

	// Reorder rows after drag & drop
	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;

		setActiveId(null);
		setOverId(null);
		setDraggingIds(null);

		if (!over) return;

		const type = event.active.data.current?.type;

		if (type === "column") {
			const draggedColumnId = active.id as TableColumnId;
			const overColumnId = over.id as TableColumnId;

			if (draggedColumnId === overColumnId) return;

			const draggedColumn = tableContext.getColumnById(draggedColumnId);
			const overColumn = tableContext.getColumnById(overColumnId);

			if (!draggedColumn || !overColumn) return;

			let insertingBeforeColumnId: TableColumnId | null;
			if (draggedColumn.column_order < overColumn.column_order) {
				const columnAfter = tableContext.getColumnAfter(overColumnId);
				insertingBeforeColumnId = columnAfter?.column_id ?? null;
			} else if (draggedColumn.column_order > overColumn.column_order) {
				insertingBeforeColumnId = overColumnId;
			} else {
				throw new Error("Column orders are the same");
			}

			tableContext.moveColumn({
				columnId: draggedColumnId,
				insertingBeforeColumnId,
			});
		} else if (type === "row") {
			const draggedRowId = active.id as TableRowId;
			const overRowId = over.id as TableRowId;
			// Get selected ids from the current state
			const draggingIds = selectedRowIds.has(draggedRowId)
				? selectedRowIds
				: new Set([draggedRowId]);

			tableContext.moveRows({
				rowIds: draggingIds,
				insertingBeforeRowId: overRowId,
			});
		}
	}

	const sensors = useSensors(
		useSensor(MouseSensor, {
			activationConstraint: {
				// Used to separate click events (e.g. for toggling header menus) from drag events
				delay: 100,
				distance: 5,
			},
		}),
		useSensor(TouchSensor, {}),
	);

	// Get rows being dragged for drag overlay
	const draggingRows = useMemo(() => {
		if (!draggingIds) return [];
		return table
			.getRowModel()
			.rows.filter((row) => draggingIds.has(row.original.rowId));
	}, [table.getRowModel, draggingIds]);

	/**
	 * Instead of calling `column.getSize()` on every render for every header
	 * and especially every data cell (very expensive),
	 * we will calculate all column sizes at once at the root table level in a useMemo
	 * and pass the column sizes down as CSS variables to the <table> element.
	 */

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const columnSizeVars = useMemo(() => {
		const headers = table.getFlatHeaders();
		const colSizes: { [key: string]: number } = {};
		for (let i = 0; i < headers.length; i++) {
			const header = headers[i];
			colSizes[`--header-${header.id}-size`] = header.getSize();
			colSizes[`--col-${header.column.id}-size`] = header.column.getSize();
		}
		return colSizes;
	}, [table.getState().columnSizingInfo, table.getState().columnSizing]);

	const rowData = table.getRowModel().rows;

	return (
		<AnimatePresence>
			<motion.div
				initial="hidden"
				animate="visible"
				exit="hidden"
				variants={{
					hidden: { opacity: 0 },
					visible: { opacity: 1 },
				}}
				transition={{ duration: 0.2 }}
				className="flex h-full max-h-full min-h-0 grow flex-col"
			>
				{/* NOTE: This provider creates div elements, so don't nest inside of <table> elements */}
				<DndContext
					modifiers={[restrictAxisByType]}
					onDragStart={handleDragStart}
					onDragOver={handleDragOver}
					onDragEnd={handleDragEnd}
					sensors={tableContext.editable ? sensors : []}
				>
					<div className="shrink-0 px-4 text-neutral-500 text-sm">
						Updated{" "}
						{formatRelativeDate(
							tableData.latestTransaction.table_transaction_created_at,
						)}
					</div>

					<div className="grow overflow-y-scroll">
						<div
							aria-label="table"
							// `table-fixed` is required for the column widths to work
							// `grid` is required for the sticky header borders to work
							className="flex grow border-collapse flex-col"
							{...{
								style: {
									...columnSizeVars, //Define column sizes on the <table> element
									width: table.getCenterTotalSize(),
								},
							}}
						>
							<div
								aria-label="thead"
								className="sticky top-0 z-10 flex bg-white text-neutral-700 text-sm"
							>
								{table.getHeaderGroups().map((headerGroup) => (
									<SortableContext
										items={columnOrder}
										strategy={horizontalListSortingStrategy}
										key={headerGroup.id}
									>
										{headerGroup.headers.map((header) => (
											<DraggableHeader key={header.id} header={header} />
										))}
									</SortableContext>
								))}
							</div>
							<div aria-label="tbody" className="h-full">
								{rowData.length ? (
									rowData.map((row) => (
										<DraggableRow
											key={row.id}
											row={row}
											overId={
												overId?.type === "row" ? (overId?.id ?? null) : null
											}
											draggingIds={draggingIds}
										/>
									))
								) : (
									<div className="flex h-8 w-full items-center border-b bg-neutral-50 px-2 text-neutral-500 text-sm">
										No rows. Click "New row" to add a row.
									</div>
								)}
								{activeId?.type === "row" && overId === null && (
									<div className="z-10 h-0 w-full p-0 ring-2 ring-blue-500" />
								)}

								<button
									type="button"
									onClick={() => {
										tableContext.createRow();
									}}
									disabled={tableContext.isComputedTable}
									className="flex w-full items-center gap-1 border-b text-left text-neutral-500 hover:bg-neutral-100 hover:text-neutral-900"
								>
									<span className="flex h-8 w-8 items-center justify-center">
										<Plus weight="bold" />
									</span>
									<span className="text-sm">New row</span>
								</button>
							</div>
						</div>
					</div>
					{tableContext.editable && selectedRowIds.size > 0 ? (
						<motion.div
							initial={{ opacity: 0, y: 25 }}
							animate={{ opacity: 1, y: 0 }}
							exit={{ opacity: 0, y: 25 }}
							transition={{ duration: 0.15 }}
							className="absolute bottom-4 flex w-full justify-center"
						>
							<div className="flex items-center gap-2 rounded-lg border p-2 shadow-md">
								<div className="rounded-md rounded-l-lg border border-blue-200 bg-blue-50 px-4 py-1 text-blue-500 text-sm shadow-inner">
									{selectedRowIds.size} row
									{selectedRowIds.size > 1 ? "s" : ""} selected
								</div>
								<button
									type="button"
									onClick={() => {
										table.resetRowSelection();
										tableContext.deleteRows({
											rowIds: selectedRowIds,
										});
									}}
									className="flex h-full items-center gap-2 rounded-md px-3 text-sm hover:bg-neutral-100"
								>
									<Trash weight="bold" /> Delete
								</button>
							</div>
						</motion.div>
					) : null}
					<DragOverlay>
						{activeId && activeId.type === "row" ? (
							<div
								className="w-full"
								{...{
									style: {
										width: table.getCenterTotalSize(),
									},
								}}
							>
								<div>
									{draggingRows.map((row) => (
										<DraggableRow
											key={row.id}
											row={row}
											overId={null}
											draggingIds={draggingIds}
										/>
									))}
								</div>
							</div>
						) : null}
					</DragOverlay>
				</DndContext>
			</motion.div>
		</AnimatePresence>
	);
});

const LoadingState = observer(() => {
	return (
		<div className="relative flex h-full min-h-0 w-full flex-col">
			<Helmet>
				<title>Table - Village</title>
			</Helmet>
		</div>
	);
});

export const TableHeader = observer(
	({ tableMetadata }: { tableMetadata: TableMetadata }) => {
		const appContext = useAppContext();

		const [newName, setNewName] = useState(tableMetadata.file_name);
		useEffect(() => {
			setNewName(tableMetadata.file_name);
		}, [tableMetadata.file_name]);

		const onSubmit = () => {
			if (newName === tableMetadata.file_name) {
				return;
			}
			if (newName.trim() === "") {
				toast.error("Table name cannot be empty");
				setNewName(tableMetadata.file_name);
				return;
			}
			appContext.renameTable({
				tableId: tableMetadata.table_id,
				tableName: newName,
			});
		};

		return (
			<div className="flex h-14 w-full shrink-0 items-center px-2">
				{!appContext.showSidebar && <ShowSidebarButton />}
				<input
					value={newName}
					className="rounded-md border border-transparent bg-white px-2 font-semibold text-lg ring-blue-100 hover:border-blue-200 focus:border-blue-200 focus:outline-none focus:ring-1"
					placeholder="Untitled table"
					onChange={(e) => {
						setNewName(e.target.value);
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							onSubmit();
						}
					}}
					onBlur={onSubmit}
				/>
			</div>
		);
	},
);

export const Table = observer(() => {
	const { activeTableId } = useParams();
	const appContext = useAppContext();

	const {
		data: table,
		isLoading,
		error,
	} = useGetTableLatestVersion(activeTableId as TableId, {
		query: {
			queryKey: ["table", activeTableId],
			// disable stale time to always fetch the latest data
			gcTime: 0,
		},
	});

	if (!activeTableId) {
		return <Navigate to="/tables" />;
	}

	if (!appContext.workspaceHasLoaded) {
		return <LoadingState />;
	}

	const tableMetadata = appContext.getTableById(activeTableId as TableId);
	if (!tableMetadata || error) {
		return (
			<div className="flex h-full w-full flex-col items-center justify-center">
				<RectangleDashed className="h-24 w-24 text-neutral-300" />
				<h1 className="mt-4 font-semibold text-xl">Table not found</h1>
				<p className="mt-2 text-center text-neutral-500">
					We couldn't find the table you're looking for.
					<br />
					If you think this is a mistake, please contact us.
				</p>

				<NavLink to="/tables" className="mt-4">
					<Button variant="outline">View all tables</Button>
				</NavLink>
			</div>
		);
	}

	return (
		<div className="flex h-full flex-col">
			<TableHeader tableMetadata={tableMetadata} />
			{table && !isLoading ? (
				<TableProvider
					tableId={activeTableId as TableId}
					tableData={table.data}
					key={activeTableId}
					editable
				>
					<TableView />
				</TableProvider>
			) : (
				<LoadingState />
			)}
		</div>
	);
});
