import { ColumnTypeIcons } from "@/components/ColumnTypeOptions";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Command,
	CommandEmpty,
	CommandGroup,
	CommandInput,
	CommandItem,
	CommandList,
} from "@/components/ui/command";
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { TimePicker } from "@/components/ui/time-picker";
import { useAppContext } from "@/contexts/AppContext";
import { TableContext, TableState } from "@/contexts/TableContext";
import { newFilterId } from "@/idGenerators";
import { TableView } from "@/pages/Table";
import { CategoryBackgroundColors } from "@/pages/Table/categoryColors";
import {
	createComputedTableRoute,
	getFilteredRowsRoute,
	getTableLatestVersion,
} from "@api/fastAPI";
import type {
	CategoryFilter,
	DateFilter,
	DateRangeFilter,
	DatetimeFilter,
	DatetimeRangeFilter,
	Filter,
	FilterId,
	GetTableLatestVersionResponse,
	MaterializedColumn,
	NumberFilter,
	TableColumnId,
	TableColumnType,
	TableId,
	TableRowId,
	TextFilter,
} from "@api/schemas";
import { useAuth } from "@clerk/clerk-react";
import { Check, Plus, Trash } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { autorun, makeAutoObservable, reaction, runInAction, toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const filterLabels: {
	[key in Filter["filter_type"]]: string;
} = {
	// Text filters
	text_contains: "contains",
	text_does_not_contain: "does not contain",
	text_equals: "is",
	text_does_not_equal: "is not",
	text_starts_with: "starts with",
	text_ends_with: "ends with",

	// Number filters
	number_equals: "is equal to",
	number_does_not_equal: "is not equal to",
	number_greater_than: "is greater than",
	number_less_than: "is less than",
	number_greater_than_or_equal: "is greater than or equal to",
	number_less_than_or_equal: "is less than or equal to",

	// Category filters
	category_equals: "is",
	category_does_not_equal: "is not",

	// Date filters
	date_equals: "is",
	date_does_not_equal: "is not",
	date_after: "is after",
	date_before: "is before",
	date_after_or_equal: "is on or after",
	date_before_or_equal: "is on or before",
	date_is_between: "is between",

	// Datetime filters
	datetime_equals: "is",
	datetime_does_not_equal: "is not",
	datetime_after: "is after",
	datetime_before: "is before",
	datetime_after_or_equal: "is on or after",
	datetime_before_or_equal: "is on or before",
	datetime_is_between: "is between",

	// Boolean filters
	boolean_is_true: "is true",
	boolean_is_false: "is false",

	// Document filters
	exists: "is empty",
	does_not_exist: "is not empty",
};

const filtersByType: {
	[key in TableColumnType]: Filter["filter_type"][];
} = {
	text: [
		"text_contains",
		"text_does_not_contain",
		"text_equals",
		"text_does_not_equal",
		"text_starts_with",
		"text_ends_with",
		"exists",
		"does_not_exist",
	],
	number: [
		"number_equals",
		"number_does_not_equal",
		"number_greater_than",
		"number_less_than",
		"number_greater_than_or_equal",
		"number_less_than_or_equal",
		"exists",
		"does_not_exist",
	],
	category: [
		"category_equals",
		"category_does_not_equal",
		"exists",
		"does_not_exist",
	],
	date: [
		"date_equals",
		"date_does_not_equal",
		"date_after",
		"date_before",
		"date_after_or_equal",
		"date_before_or_equal",
		"date_is_between",
		"exists",
		"does_not_exist",
	],
	datetime: [
		"datetime_equals",
		"datetime_does_not_equal",
		"datetime_after",
		"datetime_before",
		"datetime_after_or_equal",
		"datetime_before_or_equal",
		"datetime_is_between",
		"exists",
		"does_not_exist",
	],
	boolean: ["boolean_is_true", "boolean_is_false", "exists", "does_not_exist"],
	document: ["exists", "does_not_exist"],
	proxy: [],
	proxy_group: [],
};

const TextFilterInput = ({
	filter,
}: {
	filter: TextFilter;
}) => {
	return (
		<Input
			value={filter.filter_value}
			onChange={(e) => {
				filter.filter_value = e.target.value;
			}}
		/>
	);
};

const NumberFilterInput = ({
	filter,
}: {
	filter: NumberFilter;
}) => {
	return (
		<Input
			value={filter.filter_value}
			onChange={(e) => {
				filter.filter_value = Number(e.target.value);
			}}
		/>
	);
};

const CategoryFilterInput = ({ filter }: { filter: CategoryFilter }) => {
	const [open, setOpen] = useState(false);
	const [query, setQuery] = useState("");

	const filterCategories = filter.filter_values;

	const creatorContext = useCreatorContext();
	const config = creatorContext.config;
	if (!config) {
		return null;
	}

	const column = config.getColumnById(filter.filter_column_id);

	if (column.column_metadata.column_type !== "category") {
		return null;
	}

	const categories = column.column_metadata.categories;

	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 items-start truncate rounded-md border p-1 text-left text-neutral-800 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{filterCategories.map((category) => {
					const categoryColor = categories[category]?.color;
					return (
						<Badge
							key={category}
							className={clsx(
								CategoryBackgroundColors[categoryColor],
								"min-w-0 truncate text-neutral-800",
							)}
						>
							{category}
						</Badge>
					);
				})}
			</PopoverTrigger>
			<PopoverContent className="w-48 p-0" align="start">
				<Command>
					<CommandInput
						value={query}
						onValueChange={setQuery}
						placeholder="Search options..."
					/>
					<CommandList>
						<CommandEmpty>No options found.</CommandEmpty>
						<CommandGroup>
							{Object.values(categories).map((category) => (
								<CommandItem
									key={category.value}
									value={category.value}
									onSelect={(newValue) => {
										runInAction(() => {
											if (filterCategories.includes(newValue)) {
												filter.filter_values = filterCategories.filter(
													(category) => category !== newValue,
												);
											} else {
												filter.filter_values.push(newValue);
											}
										});
										setOpen(false);
									}}
								>
									<Check
										className={clsx(
											"mr-2 h-4 w-4",
											filterCategories.includes(category.value)
												? "opacity-100"
												: "opacity-0",
										)}
									/>
									<Badge
										className={clsx(
											CategoryBackgroundColors[category.color],
											"text-neutral-800",
										)}
									>
										{category.value}
									</Badge>
								</CommandItem>
							))}
						</CommandGroup>
					</CommandList>
				</Command>
			</PopoverContent>
		</Popover>
	);
};

const DateFilterInput = ({
	filter,
}: {
	filter: DateFilter;
}) => {
	const [open, setOpen] = useState(false);
	const currentValue = filter.filter_value;

	const [newValue, setNewValue] = useState(
		// don't use `new Date(currentValue)` because it will convert the date to the local timezone,
		// potentially adding/subtracting a day
		currentValue ? dayjs.tz(currentValue, "UTC") : null,
	);

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					const newDateString = newValue.utc().format("YYYY-MM-DD");
					if (newDateString === currentValue) {
						return;
					}
					runInAction(() => {
						filter.filter_value = newDateString;
					});
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue ? newValue.format("MMMM D, YYYY") : null}
			</PopoverTrigger>
			<PopoverContent className="w-auto p-2" align="start">
				<Calendar
					mode="single"
					selected={newValue?.toDate()}
					onSelect={(selected) => {
						selected && setNewValue(dayjs.tz(selected, "UTC"));
					}}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	);
};

const DateRangeFilterInput = ({
	filter,
}: {
	filter: DateRangeFilter;
}) => {
	return <div>{filter.filter_id}</div>;
};

const DatetimeFilterInput = ({
	filter,
}: {
	filter: DatetimeFilter;
}) => {
	const [open, setOpen] = useState(false);

	const currentValue = filter.filter_value;

	const [newValue, setNewValue] = useState(
		currentValue ? dayjs.tz(currentValue, "UTC") : null,
	);

	const handleSelect = (newDay: Date | undefined) => {
		if (!newDay) return;
		if (!newValue) {
			setNewValue(dayjs.tz(newDay, "UTC"));
			return;
		}

		const updatedDate = newValue
			.year(dayjs.tz(newDay, "UTC").year())
			.month(dayjs.tz(newDay, "UTC").month())
			.date(dayjs.tz(newDay, "UTC").date());

		setNewValue(updatedDate);
	};

	return (
		<Popover
			open={open}
			onOpenChange={(newOpen) => {
				setOpen(newOpen);
				if (!newOpen && newValue) {
					if (dayjs(currentValue).isSame(newValue)) return;
					runInAction(() => {
						filter.filter_value = newValue.utc().toISOString();
					});
				}
			}}
		>
			<PopoverTrigger
				className={clsx(
					"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
					open && "bg-blue-50 ring-2 ring-blue-300",
				)}
			>
				{newValue ? newValue.local().format("YYYY-MM-DD HH:mm:ss") : null}
			</PopoverTrigger>
			<PopoverContent className="w-auto p-2">
				<Calendar
					mode="single"
					selected={newValue?.toDate()}
					onSelect={(d) => handleSelect(d)}
					initialFocus
				/>
				<div className="border-t p-3">
					<TimePicker
						setDate={(newDate) => {
							setNewValue(dayjs(newDate));
						}}
						date={newValue?.toDate()}
					/>
				</div>
			</PopoverContent>
		</Popover>
	);
};

const DatetimeRangeFilterInput = ({
	filter,
}: {
	filter: DatetimeRangeFilter;
}) => {
	return <div>{filter.filter_id}</div>;
};

const FilterInputComponent = ({ filter }: { filter: Filter }) => {
	switch (filter.filter_parameter_signature) {
		case "text": {
			return <TextFilterInput filter={filter} />;
		}
		case "number": {
			return <NumberFilterInput filter={filter} />;
		}
		case "category": {
			return <CategoryFilterInput filter={filter} />;
		}
		case "date": {
			return <DateFilterInput filter={filter} />;
		}
		case "date_range": {
			return <DateRangeFilterInput filter={filter} />;
		}
		case "datetime": {
			return <DatetimeFilterInput filter={filter} />;
		}
		case "datetime_range": {
			return <DatetimeRangeFilterInput filter={filter} />;
		}
		case "boolean": {
			return null;
		}
		case "existential": {
			return null;
		}
		default: {
			const _exhaustiveCheck: never = filter;
			return _exhaustiveCheck;
		}
	}
};

const TableSelect = () => {
	const creatorContext = useCreatorContext();
	const tables = useAppContext().workspace?.tables;

	if (!tables) {
		return null;
	}

	return (
		<Select
			onValueChange={(value) => {
				// Confirm if user wants to clear existing filters & group by
				// Remember stored table id in dialog state
				runInAction(() => {
					creatorContext.selectedTableId = value as TableId;
				});
			}}
		>
			<SelectTrigger>
				<SelectValue placeholder="Select table..." />
			</SelectTrigger>
			<SelectContent className="max-w-72">
				{[...tables.keys()].map((tableId) => {
					const table = tables.get(tableId);
					if (!table) {
						return null;
					}
					return (
						<SelectItem key={table.table_id} value={table.table_id}>
							<div className="flex items-center gap-1">
								<span>{table.file_name}</span>
							</div>
						</SelectItem>
					);
				})}
			</SelectContent>
		</Select>
	);
};

class ComputedTableConfig {
	tableId: TableId;
	tableState: TableState;
	filters: Map<FilterId, Filter> = new Map();
	applyFilters: () => void;

	constructor(
		tableId: TableId,
		tableData: GetTableLatestVersionResponse,
		getToken: ReturnType<typeof useAuth>["getToken"],
		navigate: ReturnType<typeof useNavigate>,
	) {
		this.tableId = tableId;
		this.tableState = new TableState({
			tableId,
			tableData,
			getToken,
			navigate,
			editable: false,
		});

		makeAutoObservable(this);

		this.applyFilters = reaction(
			() => toJS(this.filters),
			(filters) => {
				if (filters.size === 0) {
					this.tableState.filteredRows = null;
				} else {
					getFilteredRowsRoute({
						table_id: this.tableId,
						filters: Array.from(filters.values()),
					}).then((res) => {
						runInAction(() => {
							this.tableState.filteredRows = new Set(
								res.data,
							) as Set<TableRowId>;
						});
					});
				}
			},
		);
	}

	swapFilter(filterId: FilterId, newFilter: Filter) {
		const oldFilter = this.filters.get(filterId);
		if (!oldFilter) {
			throw new Error(`Filter with id ${filterId} not found`);
		}
		if (oldFilter.filter_column_type !== newFilter.filter_column_type) {
			throw new Error("Cannot swap filters with different column types");
		}
		this.filters.set(filterId, newFilter);
	}

	getColumnById(columnId: TableColumnId) {
		const column = this.tableState.table.columns.get(columnId);
		if (!column) {
			throw new Error(`Column with id ${columnId} not found`);
		}
		return column;
	}

	get sortedColumns() {
		return [...this.tableState.table.columns.values()].sort((a, b) =>
			a.column_order.localeCompare(b.column_order),
		);
	}

	get filtersByColumn() {
		const filtersByColumn = new Map<TableColumnId, Filter[]>();
		for (const filter of this.filters.values()) {
			if (!filtersByColumn.has(filter.filter_column_id)) {
				filtersByColumn.set(filter.filter_column_id, []);
			}
			filtersByColumn.get(filter.filter_column_id)?.push(filter);
		}
		return filtersByColumn;
	}

	initFilter(column: MaterializedColumn) {
		let filter: Filter;
		switch (column.column_metadata.column_type) {
			case "text": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "text",
					filter_type: "text_contains",
					filter_column_type: "text",
					filter_value: "",
				};
				break;
			}
			case "number": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "number",
					filter_type: "number_equals",
					filter_column_type: "number",
					filter_value: 0,
				};
				break;
			}
			case "boolean": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "boolean",
					filter_type: "boolean_is_false",
					filter_column_type: "boolean",
				};
				break;
			}
			case "category": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "category",
					filter_type: "category_equals",
					filter_column_type: "category",
					filter_values: [],
				};
				break;
			}
			case "date": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "date",
					filter_type: "date_equals",
					filter_column_type: "date",
					filter_value: "",
				};
				break;
			}
			case "datetime": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "datetime",
					filter_type: "datetime_equals",
					filter_column_type: "datetime",
					filter_value: "",
				};
				break;
			}
			case "document": {
				filter = {
					filter_column_id: column.column_id,
					filter_id: newFilterId(),
					filter_parameter_signature: "existential",
					filter_type: "exists",
					filter_column_type: null,
				};
				break;
			}
			case "proxy": {
				// This is going to be rough
				throw new Error("Proxy column not supported");
				// break;
			}
			case "proxy_group": {
				// This is going to be rough
				throw new Error("Proxy group column not supported");
				// break;
			}
			default: {
				const _exhaustiveCheck: never = column.column_metadata;
				return _exhaustiveCheck;
			}
		}

		this.filters.set(filter.filter_id as FilterId, filter);
	}

	removeFilter(filterId: FilterId) {
		this.filters.delete(filterId);
	}

	groupBy: string | null = null; // Property ID
}

class CreatorState {
	selectedTableId: TableId | null = null;
	config: ComputedTableConfig | null = null;

	get configMatchesSelectedTable() {
		return this.selectedTableId === this.config?.tableId;
	}

	constructor() {
		makeAutoObservable(this);
	}
}

const creatorContext = // biome-ignore lint/suspicious/noExplicitAny: <explanation>
	createContext<CreatorState>(null as any);

const useCreatorContext = () => {
	const context = useContext(creatorContext);
	if (!context) {
		throw new Error(
			"useCreatorContext must be used within a CreateComputedTableProvider",
		);
	}
	return context;
};

export const CreatorProvider: React.FC<{
	children: React.ReactNode;
}> = ({ children }) => {
	const { getToken } = useAuth();
	const navigate = useNavigate();
	const [creatorState] = useState(() => new CreatorState());

	useEffect(() => {
		return autorun(() => {
			const selectedTableId = creatorState.selectedTableId;
			if (!selectedTableId) {
				return;
			}
			getTableLatestVersion(selectedTableId).then((res) => {
				runInAction(() => {
					creatorState.config = new ComputedTableConfig(
						selectedTableId,
						res.data,
						getToken,
						navigate,
					);
				});
			});
		});
	}, [creatorState, getToken, navigate]);

	return (
		<creatorContext.Provider value={creatorState}>
			{children}
		</creatorContext.Provider>
	);
};

export const _CreateComputedTableDialog = observer(() => {
	const appContext = useAppContext();
	const creatorContext = useCreatorContext();
	const navigate = useNavigate();

	return (
		<Dialog
			open={appContext.showCreateComputedTableDialog}
			onOpenChange={() => {
				runInAction(() => {
					appContext.showCreateComputedTableDialog = false;
				});
			}}
		>
			<DialogContent
				hideCloseButton={true}
				className="!rounded-none flex h-screen w-screen max-w-full flex-col gap-0 space-y-0 border-none p-0 transition-none"
			>
				<section className="flex items-center justify-between border-neutral-200 border-b p-4">
					<DialogTitle className="text-base">
						Create new computed table
					</DialogTitle>
					<div className="flex gap-2">
						<Button
							variant={"outline"}
							onClick={() => {
								runInAction(() => {
									appContext.showCreateComputedTableDialog = false;
									appContext.showCreateTableDialog = true;
								});
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								if (!creatorContext.config) {
									return;
								}
								createComputedTableRoute({
									filters: [],
									proxied_column_ids: creatorContext.config.sortedColumns.map(
										(column) => column.column_id,
									),
									parent_table_id: creatorContext.config.tableId,
									table_name: "Computed Table",
								}).then((res) => {
									navigate(`/table/${res.data.table_id}`);
								});
							}}
						>
							Create
						</Button>
					</div>
				</section>
				<section className="flex min-h-0 w-full min-w-0 grow">
					<div className="flex h-full w-96 shrink-0 flex-col gap-4 overflow-y-auto border-neutral-200 border-r p-4">
						<div className="flex flex-col gap-2">
							<h2 className="font-medium text-neutral-950 text-sm">
								Input table
							</h2>
							<p className="text-neutral-500 text-xs">
								Select a table to perform computations such as calculations,
								transformations, or aggregations.
							</p>
							<TableSelect />
						</div>
						<hr />
						<div className="flex flex-col gap-2">
							<h2 className="font-medium text-sm">Filter by</h2>
							{creatorContext.config?.sortedColumns.map((column) => {
								return (
									<div className="flex flex-col px-4" key={column.column_id}>
										<div className="flex w-full items-center justify-between gap-2">
											<div className="flex items-center gap-2">
												{ColumnTypeIcons[column.column_metadata.column_type]({
													className: "text-neutral-500",
												})}
												<span className="font-medium text-neutral-700 text-sm">
													{column.column_metadata.column_name}
												</span>
											</div>

											<button
												type="button"
												className="flex items-center gap-1 rounded-md px-2 py-1 font-medium text-neutral-500 text-sm hover:bg-neutral-100 hover:text-neutral-800"
												onClick={() => {
													creatorContext.config?.initFilter(column);
												}}
											>
												<Plus size={10} weight="bold" />
												<span>Add filter</span>
											</button>
										</div>
										{creatorContext.config?.filtersByColumn
											.get(column.column_id)
											?.map((filter) => {
												return (
													<div
														key={filter.filter_id}
														className="flex items-center gap-2"
													>
														<Select
															value={filter.filter_type}
															onValueChange={(newValue) => {
																creatorContext.config?.swapFilter(
																	filter.filter_id as FilterId,
																	{
																		filter_column_type:
																			filter.filter_column_type,
																		filter_column_id: filter.filter_column_id,
																		// @ts-expect-error
																		filter_type: newValue,
																		filter_id: filter.filter_id,
																	},
																);
															}}
														>
															<SelectTrigger className="max-w-max">
																<SelectValue>
																	{filterLabels[filter.filter_type]}
																</SelectValue>
															</SelectTrigger>
															<SelectContent>
																{filtersByType[
																	column.column_metadata.column_type
																].map((filterType) => {
																	return (
																		<SelectItem
																			value={filterType}
																			key={filterType}
																			onClick={() => {
																				runInAction(() => {
																					filter.filter_type = filterType;
																				});
																			}}
																		>
																			{filterLabels[filterType]}
																		</SelectItem>
																	);
																})}
															</SelectContent>
														</Select>
														<FilterInputComponent filter={filter} />
														<Button
															variant="ghost"
															onClick={() => {
																creatorContext.config?.removeFilter(
																	filter.filter_id as FilterId,
																);
															}}
														>
															<Trash />
														</Button>
													</div>
												);
											})}
									</div>
								);
							})}
						</div>
						<div className="flex flex-col gap-2">
							<h2 className="font-medium text-sm">Group by</h2>
							{/* <GroupByPropertySelect /> */}
						</div>
					</div>
					<div className="flex h-full w-full min-w-0 flex-col gap-2 bg-neutral-50 p-4">
						<h2 className="font-medium text-neutral-950">Preview</h2>
						<div className="flex min-h-[600px] w-full flex-col rounded-sm border border-neutral-200">
							{creatorContext.config ? (
								<TableContext.Provider value={creatorContext.config.tableState}>
									<TableView />
								</TableContext.Provider>
							) : null}
						</div>
					</div>
				</section>
			</DialogContent>
		</Dialog>
	);
});

export const CreateComputedTableDialog = observer(() => {
	return (
		<CreatorProvider>
			<_CreateComputedTableDialog />
		</CreatorProvider>
	);
});
