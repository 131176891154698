import { Favicon } from "@/components/Favicon";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import { formatDate } from "@/lib/utils";
import { ResultWithinFeedItemsGroup } from "@/pages/Search/ResultWithinFeedItemsGroup";
import type {
	FeedChannel,
	SearchFeedItemsResultOutput as SearchFeedItemsResult,
} from "@api/schemas";
import { CaretRight } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useRef, useState } from "react";
import { flushSync } from "react-dom";

export const SearchFeedItemsResultGroup = observer(
	({
		feedChannel,
		results,
		containerId,
	}: {
		feedChannel: FeedChannel;
		results: SearchFeedItemsResult[];
		containerId: string;
	}) => {
		const [open, setOpen] = useState(true);
		const collapsibleRef = useRef<HTMLDivElement>(null);
		const triggerRef = useRef<HTMLButtonElement>(null);

		return (
			<Collapsible
				open={open}
				onOpenChange={(open) => {
					const container = document.getElementById(containerId);
					if (!triggerRef.current || !collapsibleRef.current || !container)
						return;

					const containerWindowOffset = container.getBoundingClientRect().top;
					const triggerWindowOffset =
						triggerRef.current.getBoundingClientRect().top;

					const collapsibleScrollOffset = collapsibleRef.current.offsetTop;

					flushSync(() => {
						setOpen(open);
					});

					if (!open) {
						// sticky
						if (containerWindowOffset === triggerWindowOffset) {
							// scroll to the trigger
							triggerRef.current.scrollIntoView({
								behavior: "instant",
								block: "start",
							});
						} else {
							container.scrollTo({
								top:
									collapsibleScrollOffset +
									containerWindowOffset -
									triggerWindowOffset,
								behavior: "instant",
							});
						}
					}
				}}
				defaultOpen
				ref={collapsibleRef}
			>
				<CollapsibleTrigger
					ref={triggerRef}
					className="group sticky top-0 z-10 flex w-full min-w-0 items-center justify-between gap-2 truncate border-b bg-white/80 px-2 py-1.5 text-left shadow-xs backdrop-blur"
				>
					<div
						className={clsx(
							"transform text-lg text-neutral-400 transition-transform group-hover:text-neutral-900",
							open ? "rotate-90" : "rotate-0",
						)}
					>
						<CaretRight weight="bold" />
					</div>
					<div className="flex min-w-0 grow items-center gap-2 truncate">
						{feedChannel.feed_channel_link ? (
							<Favicon
								url={feedChannel.feed_channel_link || ""}
								alt={feedChannel.file_name || ""}
								className="h-8 w-8 shrink-0 rounded-md bg-neutral-200"
							/>
						) : (
							<div className="h-8 w-8 shrink-0 rounded-md bg-neutral-200" />
						)}

						<div className="min-w-0 truncate">
							<h2 className="min-w-0 truncate text-sm leading-4">
								{feedChannel.file_name}
							</h2>
							<h3 className="mt-1 min-w-0 truncate text-neutral-500 text-sm leading-4">
								{feedChannel.feed_channel_subtitle}
								{feedChannel.feed_channel_pub_date &&
									`, ${formatDate(feedChannel.feed_channel_pub_date)}`}
							</h3>
						</div>
					</div>

					<h3 className="mt-0.5 min-w-5 max-w-max shrink-0 rounded-full bg-blue-50 px-1.5 py-0.5 text-center font-semibold text-blue-500 text-xs leading-4">
						{results.length}
					</h3>
				</CollapsibleTrigger>
				<CollapsibleContent>
					<div className="mt-2 flex flex-col gap-2 pr-2 pb-1 pl-2">
						{results.map((result) => (
							<ResultWithinFeedItemsGroup
								key={result.feed_item_chunk_id}
								result={result}
							/>
						))}
					</div>
				</CollapsibleContent>
			</Collapsible>
		);
	},
);
