import dayjs from "dayjs";
import { convert } from "html-to-text";

export const cleanXmlText = (text: string): string => {
	return convert(text);
};

export function cleanUrlForDisplay(url: string) {
	// trim starting http:// or https://
	let cleandUrl = url.replace(/^https?:\/\//, "");
	// trim trailing slash
	cleandUrl = cleandUrl.replace(/\/$/, "");
	return cleandUrl;
}

export function capitalizeFirstLetter(word: string) {
	if (!word) return word;
	return word[0].toUpperCase() + word.slice(1).toLowerCase();
}

export const formatRelativeDate = (date: string) => {
	const parsedDate = dayjs(date);
	const today = dayjs();

	if (parsedDate.isAfter(today.subtract(1, "day"))) {
		return parsedDate.fromNow(); // e.g., "2 hours ago", "5 minutes ago"
	}
	if (parsedDate.isAfter(today.subtract(1, "week"))) {
		return parsedDate.format("dddd [at] h:mm A"); // e.g., "Tuesday at 2:30 PM"
	}
	if (parsedDate.isAfter(today.subtract(1, "month"))) {
		return parsedDate.format("MMM D [at] h:mm A"); // e.g., "Sep 5 at 2:30 PM"
	}

	if (parsedDate.year() === today.year()) {
		return parsedDate.format("MMM D [at] h:mm A"); // e.g., "Mar 15 at 2:30 PM"
	}
	return parsedDate.format("MMM D, YYYY [at] h:mm A"); // e.g., "Sep 12, 2023 at 2:30 PM"
};
