import {
	AlertDialog,
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogDescription,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useAppContext } from "@/contexts/AppContext";
import type { TableMetadata } from "@api/schemas";
import { DotsThree, PencilSimple, Trash } from "@phosphor-icons/react";
import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

export const TableLink = observer(({ table }: { table: TableMetadata }) => {
	const [showOptions, setShowOptions] = useState(false);
	const appContext = useAppContext();
	const navigate = useNavigate();
	const { activeTableId } = useParams();

	const [renaming, setRenaming] = useState(false);
	const [tableName, setTableName] = useState(table.file_name);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		// if the chat title gets updated from the server, update the local state
		if (table.file_name !== tableName) {
			setTableName(table.file_name);
		}
	}, [table.file_name]);

	return (
		<NavLink
			className={({ isActive }) =>
				clsx(
					"group relative flex w-full min-w-0 shrink-0 items-center justify-between gap-1 truncate rounded-lg p-[0.125rem] text-xs",
					isActive
						? "bg-neutral-200 text-neutral-900"
						: "text-neutral-600 hover:bg-neutral-200 hover:text-neutral-900",
				)
			}
			to={`/table/${table.table_id}`}
			key={table.table_id}
			onClick={(e) => {
				if (renaming) {
					e.preventDefault();
				}
			}}
		>
			{renaming ? (
				<input
					value={tableName}
					onChange={(e) => {
						setTableName(e.target.value);
					}}
					onBlur={() => {
						if (renaming) {
							if (tableName !== table.file_name) {
								appContext.renameTable({
									tableId: table.table_id,
									tableName,
								});
							}
							setRenaming(false);
						}
					}}
					onClick={(e) => {
						if (renaming) {
							e.stopPropagation();
							e.preventDefault();
						}
					}}
					className={clsx(
						"min-w-0 grow truncate rounded-md border border-transparent p-1",
						renaming
							? "border-neutral-400 bg-white"
							: "bg-transparent outline-none",
					)}
				/>
			) : (
				<div className="min-w-0 grow truncate rounded-md border border-transparent p-1">
					{table.file_name}
				</div>
			)}
			{/* Hidden element to shift the chat titles when the chat is hovered, otherwise using `hidden` on the actual trigger will cause the dropdown to jump around */}
			<div
				className={clsx(
					"shrink-0 rounded-md p-1 text-neutral-500 text-xl opacity-0 outline-none hover:text-neutral-900",
					showOptions ? "flex" : "hidden group-hover:flex",
				)}
			>
				<DotsThree weight="bold" className="h-1 w-1" />
			</div>
			<DropdownMenu open={showOptions} onOpenChange={setShowOptions}>
				<DropdownMenuTrigger
					className={clsx(
						"absolute right-0 rounded-md p-2 text-neutral-500 text-xl outline-none hover:text-neutral-900",
						showOptions ? "flex" : "invisible group-hover:visible",
					)}
				>
					<DotsThree weight="bold" />
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start">
					<DropdownMenuItem
						onClick={(e) => {
							e.stopPropagation();
							setRenaming(true);
						}}
					>
						<button type="button" className="flex items-center gap-2 ">
							<PencilSimple weight="bold" />
							Rename
						</button>
					</DropdownMenuItem>
					<DropdownMenuItem
						onClick={(e) => {
							e.stopPropagation();
						}}
						className="p-0"
					>
						<AlertDialog>
							<AlertDialogTrigger
								onClick={(e) => {
									e.stopPropagation();
								}}
								className="flex w-full items-center gap-2 rounded-md px-2 py-1.5 text-red-500 hover:bg-red-50 hover:text-red-500"
							>
								<Trash weight="bold" />
								Delete
							</AlertDialogTrigger>
							<AlertDialogContent>
								<AlertDialogHeader>
									<AlertDialogTitle>Delete table?</AlertDialogTitle>
									<AlertDialogDescription>
										This will delete the table...
									</AlertDialogDescription>
								</AlertDialogHeader>
								<AlertDialogFooter>
									<AlertDialogCancel>Cancel</AlertDialogCancel>
									<AlertDialogAction
										onClick={() => {
											if (activeTableId === table.table_id) {
												navigate("/tables");
											}
											appContext.deleteTables({
												tableIds: [table.table_id],
											});
										}}
									>
										Continue
									</AlertDialogAction>
								</AlertDialogFooter>
							</AlertDialogContent>
						</AlertDialog>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenu>
		</NavLink>
	);
});
