import { UploadCoverImage } from "@/components/UploadCoverImage";
import { formatUploadTitle } from "@/lib/utils";
import type { Upload } from "@api/schemas";
import { File as FileIcon } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

export const UploadPreview = observer(
	({ upload }: { upload: Upload | null }) => {
		if (!upload) {
			return (
				<div className="flex h-full min-h-64 w-full flex-col items-center justify-center space-y-2 overflow-y-scroll border-l bg-neutral-100 p-2 text-neutral-500 text-sm">
					<FileIcon className="h-8 w-8" />
					<p className="text-center">
						Select an upload
						<br /> to preview it here
					</p>
				</div>
			);
		}

		return (
			<div
				className="flex h-full min-h-64 flex-col overflow-y-scroll border-l p-4"
				key={upload.upload_id}
			>
				<div className="p-4">
					<UploadCoverImage
						size={512}
						upload_id={upload.upload_id}
						upload_status={upload.upload_status ?? "pending"}
						className={() => "mx-auto h-64 max-w-full rounded"}
					/>
				</div>

				<div>
					<h1 className="font-semibold text-base text-neutral-800">
						{formatUploadTitle({
							title: upload.upload_title,
							subtitle: upload.upload_subtitle,
							filename: upload.file_name,
						})}
					</h1>
				</div>
				<div className="mt-2 flex flex-col">
					<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
						<h3>Title</h3>
						<span className="text-right font-semibold">
							{upload.upload_title}
						</span>
					</div>
					<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
						<h3>Subtitle</h3>
						<span className="text-right font-semibold">
							{upload.upload_subtitle}
						</span>
					</div>
					<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
						<h3>Filename</h3>
						<span className="text-right font-semibold">{upload.file_name}</span>
					</div>
					<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
						<h3>Publisher</h3>
						<span className="text-right font-semibold">
							{upload.upload_publisher}
						</span>
					</div>
					<div className="flex justify-between space-x-1 py-1 text-neutral-950 text-xs">
						<h3>Year Published</h3>
						<span className="text-right font-semibold">
							{upload.upload_year_published}
						</span>
					</div>
				</div>
			</div>
		);
	},
);
