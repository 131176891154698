import type {
	ActionId,
	ActionInputId,
	ChatId,
	FeedChannelId,
	FeedItemId,
	FilterId,
	FolderId,
	MessageId,
	SessionUserId,
	StepId,
	StepUserId,
	TableCellId,
	TableColumnId,
	TableId,
	TableRowId,
	UploadId,
	UserId,
} from "@api/schemas";
import { ulid } from "ulidx";

export type FileId = string & { _brand: "fileId" };

type IdType =
	| "step"
	| "action"
	| "feed-channel"
	| "feed-item"
	| "folder"
	| "chat"
	| "table"
	| "table-column"
	| "table-row"
	| "table-cell"
	| "upload"
	| "file"
	// Session types
	| "session-user"
	| "step-user"
	| "message"
	| "action-input"
	| "filter";

type IdMap = {
	step: StepId;
	action: ActionId;
	"feed-channel": FeedChannelId;
	"feed-item": FeedItemId;
	folder: FolderId;
	chat: ChatId;
	table: TableId;
	"table-column": TableColumnId;
	"table-row": TableRowId;
	"table-cell": TableCellId;
	upload: UploadId;
	user: UserId;
	file: FileId;
	// Session types
	"session-user": SessionUserId;
	"step-user": StepUserId;
	message: MessageId;
	"action-input": ActionInputId;
	filter: FilterId;
};

const generateId = <T extends IdType>(prefix: T): IdMap[T] => {
	return `${prefix}_${ulid()}` as IdMap[T];
};

export const newStepId = () => generateId("step");
export const newActionId = () => generateId("action");
export const newFeedChannelId = () => generateId("feed-channel");
export const newFeedItemId = () => generateId("feed-item");
export const newFolderId = () => generateId("folder");
export const newChatId = () => generateId("chat");
export const newTableId = () => generateId("table");
export const newTableColumnId = () => generateId("table-column");
export const newTableRowId = () => generateId("table-row");
export const formatTableCellId = ({
	rowId,
	columnId,
}: { rowId: TableRowId; columnId: TableColumnId }) =>
	`${rowId}:${columnId}` as TableCellId;
export const newUploadId = () => generateId("upload");
export const newFilterId = () => generateId("filter");
// Session types
export const newSessionUserId = () => generateId("session-user");
export const newMessageId = () => generateId("message");
export const newStepUserId = () => generateId("step-user");
export const newActionInputId = () => generateId("action-input");
