import { type AppState, useAppContext } from "@/contexts/AppContext";
import { areStrSetsEqual } from "@/lib/utils";
import type {
	DocumentCellValueCellValue,
	FeedItemId,
	UploadId,
} from "@api/schemas";
import { makeAutoObservable } from "mobx";
import { createContext, useContext, useEffect, useRef } from "react";

export class MultiDocumentSelectorState {
	appState: AppState;

	selectedUploadIds: Set<UploadId>;
	selectedFeedItemIds: Set<FeedItemId>;
	previewedUploadId: UploadId | null = null;
	previewedFeedItemId: FeedItemId | null = null;

	constructor(
		appState: AppState,
		selectedUploadIds: Set<UploadId>,
		selectedFeedItemIds: Set<FeedItemId>,
	) {
		this.appState = appState;
		this.selectedUploadIds = selectedUploadIds;
		this.selectedFeedItemIds = selectedFeedItemIds;
		makeAutoObservable(this);
	}

	get previewedUpload() {
		return this.previewedUploadId
			? this.appState.getUploadById(this.previewedUploadId)
			: null;
	}

	get previewedFeedItem() {
		return this.previewedFeedItemId
			? this.appState.getFeedItemById(this.previewedFeedItemId)
			: null;
	}
}

export enum DocumentSelectorTab {
	uploads = "uploads",
	feed_items = "feed_items",
}

export class SingleDocumentSelectorState {
	appState: AppState;
	selectedDocumentId: DocumentCellValueCellValue | null;
	previewedUploadId: UploadId | null = null;
	previewedFeedItemId: FeedItemId | null = null;
	openTab: DocumentSelectorTab;

	constructor(
		appState: AppState,
		selectedDocumentId: DocumentCellValueCellValue | null,
	) {
		this.appState = appState;
		this.selectedDocumentId = selectedDocumentId;
		if (selectedDocumentId) {
			if (selectedDocumentId.document_type === "upload") {
				this.previewedUploadId = selectedDocumentId.document_id;
				this.openTab = DocumentSelectorTab.uploads;
			} else if (selectedDocumentId.document_type === "feed_item") {
				this.previewedFeedItemId = selectedDocumentId.document_id;
				this.openTab = DocumentSelectorTab.feed_items;
			} else {
				this.openTab = DocumentSelectorTab.uploads;
			}
		} else {
			this.openTab = DocumentSelectorTab.uploads;
		}
		makeAutoObservable(this);
	}

	setOpenTab(tab: DocumentSelectorTab) {
		this.openTab = tab;
	}

	get previewedUpload() {
		return this.previewedUploadId
			? this.appState.getUploadById(this.previewedUploadId)
			: null;
	}

	get previewedFeedItem() {
		return this.previewedFeedItemId
			? this.appState.getFeedItemById(this.previewedFeedItemId)
			: null;
	}
}

export const MultiDocumentSelectorContext =
	createContext<MultiDocumentSelectorState>(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		null as any,
	);

export const SingleDocumentSelectorContext =
	createContext<SingleDocumentSelectorState>(
		// biome-ignore lint/suspicious/noExplicitAny: <explanation>
		null as any,
	);

export const useMultiDocumentSelectorContext = () => {
	const context = useContext(MultiDocumentSelectorContext);
	if (!context) {
		throw new Error("useLibraryContext must be used within an LibraryProvider");
	}

	return context;
};

export const useSingleDocumentSelectorContext = () => {
	const context = useContext(SingleDocumentSelectorContext);
	if (!context) {
		throw new Error("useLibraryContext must be used within an LibraryProvider");
	}

	return context;
};

export const MultiDocumentSelectorProvider: React.FC<{
	children: React.ReactNode;
	selectedUploadIds: Set<UploadId>;
	selectedFeedItemIds: Set<FeedItemId>;
}> = ({ children, selectedUploadIds, selectedFeedItemIds }) => {
	const appContext = useAppContext();
	const state = useRef(
		new MultiDocumentSelectorState(
			appContext,
			selectedUploadIds,
			selectedFeedItemIds,
		),
	);

	useEffect(() => {
		if (!areStrSetsEqual(state.current.selectedUploadIds, selectedUploadIds)) {
			state.current.selectedUploadIds = new Set(selectedUploadIds);
		}
	}, [selectedUploadIds]);

	useEffect(() => {
		if (
			!areStrSetsEqual(state.current.selectedFeedItemIds, selectedFeedItemIds)
		) {
			state.current.selectedFeedItemIds = new Set(selectedFeedItemIds);
		}
	}, [selectedFeedItemIds]);

	return (
		<MultiDocumentSelectorContext.Provider value={state.current}>
			{children}
		</MultiDocumentSelectorContext.Provider>
	);
};

export const SingleDocumentSelectorProvider: React.FC<{
	children: React.ReactNode;
	selectedDocumentId: DocumentCellValueCellValue | null;
}> = ({ children, selectedDocumentId }) => {
	const appContext = useAppContext();
	const state = useRef(
		new SingleDocumentSelectorState(appContext, selectedDocumentId),
	);

	return (
		<SingleDocumentSelectorContext.Provider value={state.current}>
			{children}
		</SingleDocumentSelectorContext.Provider>
	);
};
