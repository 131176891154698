import { CreateComputedTableDialog } from "@/components/CreateComputedTableDialog";
import { CreateTableDialog } from "@/components/CreateTableDialog";
import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Skeleton } from "@/components/ui/skeleton";
import { useAppContext } from "@/contexts/AppContext";
import { formatRelativeDate } from "@/lib/formatting";
import type { TableMetadata, UserId } from "@api/schemas";
import {
	ArrowDown,
	ArrowUp,
	Clock,
	ClockCounterClockwise,
	Plus,
	TextAlignLeft,
	Trash,
	User,
} from "@phosphor-icons/react";
import {
	type RowSelectionState,
	type SortDirection,
	type SortingState,
	createColumnHelper,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
	useReactTable,
} from "@tanstack/react-table";
import clsx from "clsx";
import { motion } from "framer-motion";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { type ReactNode, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const TablesEmptyState = observer(() => {
	const appContext = useAppContext();
	return (
		<div className="flex h-full flex-col items-center justify-center gap-4">
			<h1 className="px-4 text-center text-neutral-500">
				Add a table to get started!
			</h1>
			<Button
				onClick={() => {
					runInAction(() => {
						appContext.showCreateTableDialog = true;
					});
				}}
			>
				Add table
			</Button>
		</div>
	);
});

const SortingIcon = ({
	sortDirection,
}: { sortDirection: SortDirection | false }) => {
	if (!sortDirection) {
		return null;
	}

	return (
		<span className="rounded border border-blue-300 bg-blue-100 p-0.5 text-blue-500 text-md">
			{sortDirection === "asc" && <ArrowUp weight="bold" />}
			{sortDirection === "desc" && <ArrowDown weight="bold" />}
		</span>
	);
};

const headerButtonStyle =
	"flex items-center gap-1 p-2 grow select-none hover:bg-blue-50 justify-between w-full whitespace-nowrap";

export const ManageTables = observer(() => {
	const appContext = useAppContext();
	const navigate = useNavigate();

	const [tablesQuery, setTablesQuery] = useState("");
	const tables = appContext.tablesAsArray;

	const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

	const columnHelper = createColumnHelper<TableMetadata>();

	const columns = [
		columnHelper.display({
			id: "select",
			size: 32,
			maxSize: 32,
			header: ({ table }) => (
				<div className="flex h-full w-full items-center justify-center px-2">
					<Checkbox
						checked={
							table.getIsAllRowsSelected()
								? true
								: table.getIsSomeRowsSelected()
									? "indeterminate"
									: false
						}
						onCheckedChange={(checked) =>
							table.toggleAllRowsSelected(checked === true)
						}
					/>
				</div>
			),
			cell: ({ row }) => (
				<div
					className="absolute inset-0 flex items-center justify-center px-2"
					onClick={(e) => {
						e.stopPropagation();
					}}
					onKeyDown={(e) => {
						if (e.key === "Enter") {
							e.stopPropagation();
						}
					}}
				>
					<Checkbox
						checked={row.getIsSelected()}
						onCheckedChange={(checked) => row.toggleSelected(checked === true)}
						onClick={(e) => {
							e.stopPropagation();
						}}
					/>
				</div>
			),
		}),
		columnHelper.accessor("file_name", {
			id: "table_name",
			header: ({ column }) => (
				<button
					type="button"
					className={headerButtonStyle}
					onClick={column.getToggleSortingHandler()}
				>
					<span className="flex grow items-center gap-1">
						<TextAlignLeft className="text-base" />
						Name
					</span>
					<SortingIcon sortDirection={column.getIsSorted()} />
				</button>
			),
			cell: (props) => (
				<div className="flex w-full min-w-0 items-center gap-2 truncate p-2 text-sm">
					<h2 className="text-neutral-700">{props.row.original.file_name}</h2>
				</div>
			),
		}),
		columnHelper.accessor(
			(row) => {
				const user = appContext.getUserById(row.file_creator_id as UserId);
				if (user) {
					return user.user_first_name || user.user_email || "Unknown user";
				}
				return "Unknown user";
			},
			{
				id: "creator",
				sortingFn: "alphanumeric",
				header: ({ column }) => (
					<button
						type="button"
						className={headerButtonStyle}
						onClick={column.getToggleSortingHandler()}
					>
						<span className="flex grow items-center gap-1">
							<User className="text-base" />
							Creator
						</span>
						<SortingIcon sortDirection={column.getIsSorted()} />
					</button>
				),
				cell: (props) => {
					const appContext = useAppContext();
					const user = appContext.getUserById(
						props.row.original.file_creator_id as UserId,
					);
					return (
						<div className="flex w-full min-w-0 items-center gap-2 truncate p-2 text-sm">
							<Badge variant="outline" className="flex items-center gap-1">
								{user ? (
									<>
										{user.user_image_url ? (
											<img
												src={user.user_image_url}
												alt={user.user_email}
												className="h-4 w-4 rounded-full"
											/>
										) : (
											<div className="h-4 w-4 rounded-full bg-neutral-100" />
										)}
										{user.user_first_name ? (
											<>
												{user.user_first_name} {user.user_last_name || ""}
											</>
										) : (
											user.user_email
										)}
									</>
								) : (
									"Unknown user"
								)}
							</Badge>
						</div>
					);
				},
			},
		),
		columnHelper.accessor("file_created_at", {
			id: "date_created",
			header: ({ column }) => (
				<button
					type="button"
					className={headerButtonStyle}
					onClick={column.getToggleSortingHandler()}
				>
					<span className="flex grow items-center gap-1">
						<Clock className="text-base" />
						Created
					</span>
					<SortingIcon sortDirection={column.getIsSorted()} />
				</button>
			),
			cell: (props) => (
				<div className="flex w-full min-w-0 items-center gap-2 truncate p-2 text-sm">
					<h2 className="text-neutral-700">
						{formatRelativeDate(props.row.original.file_created_at)}
					</h2>
				</div>
			),
		}),
		columnHelper.accessor("file_updated_at", {
			id: "date_updated",
			header: ({ column }) => (
				<button
					type="button"
					className={headerButtonStyle}
					onClick={column.getToggleSortingHandler()}
					onKeyDown={column.getToggleSortingHandler()}
				>
					<span className="flex grow items-center gap-1">
						<ClockCounterClockwise className="text-base" />
						Last modified
					</span>
					<SortingIcon sortDirection={column.getIsSorted()} />
				</button>
			),
			cell: (props) => (
				<div className="flex w-full min-w-0 items-center gap-2 truncate p-2 text-sm">
					<h2 className="text-neutral-700">
						{formatRelativeDate(props.row.original.file_updated_at)}
					</h2>
				</div>
			),
		}),
	];

	const [sorting, setSorting] = useState<SortingState>([]);

	const table = useReactTable({
		data: tables,
		columns,
		state: {
			sorting,
			rowSelection,
		},
		enableRowSelection: true,
		onSortingChange: setSorting,
		onRowSelectionChange: setRowSelection,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const selectedTableIds = useMemo(() => {
		return table.getSelectedRowModel().rows.map((row) => row.original.table_id);
	}, [rowSelection]);

	let tablesTable: ReactNode;
	if (tables.length === 0 && appContext.workspaceHasLoaded) {
		tablesTable = <TablesEmptyState />;
	} else {
		tablesTable = (
			<div className="flex-1 overflow-auto">
				<table
					className="w-full table-fixed"
					style={{
						width: table.getCenterTotalSize(),
					}}
				>
					<thead className="sticky top-0 z-10 text-neutral-900 text-sm">
						{table.getHeaderGroups().map((headerGroup) => (
							<tr key={headerGroup.id} className="z-10">
								{headerGroup.headers.map((header) => (
									<th
										className="h-0 border-t border-r border-b p-0 text-left font-normal"
										key={header.id}
										style={{
											width: header.getSize(),
										}}
									>
										{header.isPlaceholder
											? null
											: flexRender(
													header.column.columnDef.header,
													header.getContext(),
												)}
									</th>
								))}
							</tr>
						))}
					</thead>
					{appContext.workspaceHasLoaded ? (
						<tbody className="h-full">
							{table.getRowModel().rows.map((row) => (
								<tr
									className={clsx(
										"cursor-pointer",
										row.getIsSelected() ? "bg-blue-50" : "hover:bg-neutral-100",
									)}
									key={row.id}
									onClick={() => {
										navigate(`/table/${row.original.table_id}`);
									}}
									onKeyDown={(e) => {
										if (e.key === "Enter") {
											navigate(`/table/${row.original.table_id}`);
										}
									}}
								>
									{row.getVisibleCells().map((cell) => (
										<td
											key={cell.id}
											// a set height is required to have the cell contents use height: 100%
											// without this, the cell contents will be vertically centered
											className="relative h-0 border-r border-b p-0"
										>
											{flexRender(
												cell.column.columnDef.cell,
												cell.getContext(),
											)}
										</td>
									))}
								</tr>
							))}
						</tbody>
					) : (
						<tbody className="max-h-full min-h-0 grow">
							{Array.from({ length: 3 }).map((_, i) => (
								// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
								<tr key={i}>
									{Array.from({ length: columns.length }).map((_, j) => (
										// biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
										<td key={j} className="border-r border-b p-1">
											<Skeleton className="h-6 w-full" />
										</td>
									))}
								</tr>
							))}
						</tbody>
					)}
				</table>
			</div>
		);
	}

	return (
		<div className="relative flex h-full min-h-0 w-full flex-col">
			<Helmet>
				<title>Tables - Village</title>
			</Helmet>

			<CreateTableDialog />
			<CreateComputedTableDialog />

			<div className="flex h-14 w-full shrink-0 items-center px-2">
				{!appContext.showSidebar && <ShowSidebarButton />}
				<h1 className="px-2 font-semibold text-lg">All tables</h1>
			</div>
			<div className="flex w-full shrink-0 items-center gap-2 bg-white/90 px-4 pb-4 backdrop-blur">
				<Input
					placeholder="Search tables..."
					value={tablesQuery}
					onChange={(e) => setTablesQuery(e.target.value)}
					className="max-w-[24rem]"
				/>
				<Button
					className="flex items-center gap-2"
					onClick={() => {
						runInAction(() => {
							appContext.showCreateTableDialog = true;
						});
					}}
				>
					<Plus className="text-lg" weight="regular" />
					<span>Create new</span>
				</Button>
			</div>

			{tablesTable}

			{selectedTableIds.length > 0 && (
				<motion.div
					initial={{ opacity: 0, y: 25 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ opacity: 0, y: 25 }}
					transition={{ duration: 0.15 }}
					className="absolute bottom-4 flex w-full justify-center"
				>
					<div className="flex items-center gap-2 rounded-lg border p-2 shadow-md">
						<div className="rounded-md rounded-l-lg border border-blue-200 bg-blue-50 px-4 py-1 text-blue-500 text-sm shadow-inner">
							{selectedTableIds.length} table
							{selectedTableIds.length > 1 ? "s" : ""} selected
						</div>
						<button
							type="button"
							onClick={() => {
								table.resetRowSelection();
								// Delete tables
								appContext.deleteTables({
									tableIds: selectedTableIds,
								});
							}}
							className="flex h-full items-center gap-2 rounded-md px-3 text-sm hover:bg-neutral-100"
						>
							<Trash weight="bold" /> Delete
						</button>
					</div>
				</motion.div>
			)}
		</div>
	);
});
