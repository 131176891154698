import { UploadCoverImage } from "@/components/UploadCoverImage";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@/components/ui/collapsible";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import { PDFAnnotation, useChatContext } from "@/contexts/ChatContext";
import { formatAuthors, formatPages, formatUploadTitle } from "@/lib/utils";
import { ResultWithinGroup } from "@/pages/Research/ChatSearchResults";
import { tiptapToMarkdown } from "@/pages/Research/tiptapToMarkdown";
import {
	Annotation,
	AnnotationComponent,
	AnnotationProvider,
	useAnnotation,
} from "@/plugins/Annotation";
import { UploadLink } from "@/plugins/UploadLink";
import type {
	AssistantMessageActionOutput as AssistantMessageAction,
	SearchLibraryResultOutput as SearchLibraryResult,
	Upload,
} from "@api/schemas";
import { ArrowsClockwise, CaretRight, Copy } from "@phosphor-icons/react";
import * as Sentry from "@sentry/react";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import { toast } from "sonner";

const TABLE_STYLES =
	"prose-table:border prose-table:border-collapse prose-th:bg-neutral-100 prose-td:border prose-td:px-2 prose-th:px-2 prose-th:border prose-headings:font-semibold prose-td:text-left prose-th:text-left prose-td:align-top prose-th:align-top prose-table:mb-2";

interface AssistantMessageProps {
	assistantMessage: AssistantMessageAction;
	isLast: boolean;
}

const _AssistantMessage = observer(
	({ assistantMessage, isLast }: AssistantMessageProps) => {
		const [showUploads, setShowUploads] = useState(false);
		const chatContext = useChatContext();
		const appContext = useAppContext();
		const annotationContext = useAnnotation();
		const citationIdsToMetadata: Map<
			string,
			{
				index: number;
				chunk: SearchLibraryResult;
				upload: Upload;
			}
		> =
			// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
			useMemo(() => {
				const citationIds =
					assistantMessage.content
						.match(/<cite>(.*?)<\/cite>/g)
						?.map((citeTag) => citeTag.slice(6, -7)) ?? [];
				const citationIdsToMetadata = new Map<
					string,
					{
						index: number;
						chunk: SearchLibraryResult;
						upload: Upload;
					}
				>();
				let index = 1;
				for (const citationId of citationIds) {
					const chunk = [...(chatContext.retrievedChunks?.values() ?? [])].find(
						(chunk) => chunk.chunk_id.endsWith(citationId),
					);
					if (!chunk) {
						continue;
					}
					const upload = appContext.getUploadById(chunk.upload_id);
					if (!upload) {
						continue;
					}
					if (!citationIdsToMetadata.has(citationId)) {
						citationIdsToMetadata.set(citationId, { index, chunk, upload });
						index++;
					}
				}
				return citationIdsToMetadata;
			}, [assistantMessage.content, chatContext.retrievedChunks]);

		const editor = useEditor(
			{
				extensions: [
					StarterKit,
					Table,
					TableCell,
					TableHeader,
					TableRow,
					Annotation.configure({
						annotationHighlight: {
							annotationState: annotationContext,
						},
					}),
					Link,
					UploadLink.configure({
						handleUploadLinkClick: ({
							uploadId,
							chunkId,
							textStart,
							textEnd,
						}) => {
							const retrievedChunk = chatContext.retrievedChunks?.get(chunkId);
							runInAction(() => {
								chatContext.activeSearchResult = new PDFAnnotation({
									// biome-ignore lint/style/noNonNullAssertion: <explanation>
									upload: appContext.getUploadById(uploadId)!,
									textToHighlight: {
										textStart,
										textEnd,
									},
									pageIndicesToSearch: retrievedChunk?.chunk_page_indices ?? [],
								});
							});
						},
					}),
				],
				content: assistantMessage.content,
				editable: false,
			},
			[assistantMessage.content, citationIdsToMetadata],
		);

		if (assistantMessage.is_error) {
			return (
				<div className="flex items-center gap-2 py-2 text-red-500 text-sm">
					An error occurred.{" "}
					{isLast && !chatContext.viewOnly && (
						<button
							type="button"
							className="flex items-center gap-2 rounded-full border bg-neutral-50 py-1 pr-4 pl-3 text-neutral-600 hover:bg-neutral-100 hover:text-neutral-900"
							onClick={() => chatContext.rerunResearch()}
						>
							<ArrowsClockwise
								weight="bold"
								className="text-base text-emerald-500"
							/>{" "}
							Retry request
						</button>
					)}
				</div>
			);
		}

		return (
			<>
				<EditorContent
					editor={editor}
					className={clsx(
						"prose-sm group mt-2 mb-0 prose-ol:list-decimal prose-ul:list-disc py-0",
						TABLE_STYLES,
					)}
				/>
				{assistantMessage.annotations?.map((annotation) => (
					<AnnotationComponent
						key={annotation.annotation_id}
						annotation={annotation}
					/>
				))}
				<div>
					{citationIdsToMetadata.size > 0 && (
						<Collapsible
							open={showUploads}
							onOpenChange={setShowUploads}
							className="w-full min-w-0"
						>
							<CollapsibleTrigger className="flex items-center gap-2 rounded-full bg-neutral-100 px-2 py-1 text-neutral-500 text-sm ">
								<span>Uploads</span>
								<CaretRight
									weight="bold"
									className={clsx(
										"transition-transform",
										showUploads ? "rotate-90" : "",
									)}
								/>
							</CollapsibleTrigger>
							<CollapsibleContent className="flex flex-col gap-2 py-3">
								{Array.from(citationIdsToMetadata.entries())
									.sort((a, b) => a[1].index - b[1].index)
									.map(([citationId, metadata]) => {
										return (
											<div key={citationId} className="flex">
												<span className="py-1 pr-4 pl-1 font-mono text-neutral-500 text-sm">
													{metadata.index}
												</span>
												<div className="flex flex-col gap-1">
													<div className="flex items-center gap-2 py-1">
														<UploadCoverImage
															size={128}
															upload_id={metadata.upload.upload_id}
															upload_status={metadata.upload.upload_status}
															className={(uploadStatus) =>
																clsx(
																	"h-9 max-w-8 rounded-xs",
																	uploadStatus === "ready" && "shadow",
																)
															}
														/>
														<div className="w-full min-w-0 shrink grow truncate">
															<h2 className="w-full min-w-0 truncate text-sm leading-4">
																{formatUploadTitle({
																	title: metadata.upload.upload_title,
																	subtitle: metadata.upload.upload_subtitle,
																	filename: metadata.upload.file_name,
																})}
															</h2>
															<h3 className="flex items-center text-neutral-500 text-sm leading-4">
																{formatAuthors(
																	metadata.upload.upload_authors ?? [],
																)}
																{metadata.upload.upload_year_published &&
																	`, ${metadata.upload.upload_year_published}`}
															</h3>
														</div>
													</div>
													<ResultWithinGroup
														key={metadata.chunk.chunk_id}
														result={metadata.chunk}
														upload={metadata.upload}
													/>
												</div>
											</div>
										);
									})}
							</CollapsibleContent>
						</Collapsible>
					)}
				</div>
				<div className="flex max-w-max items-center gap-0.5 text-base">
					<Tooltip>
						<TooltipTrigger
							type="button"
							className="rounded-lg p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-800"
							onClick={() => {
								if (!editor) {
									Sentry.captureMessage(
										"Error copying message: editor not found",
										"error",
									);
									toast.error("Error copying message: editor not found");
									return;
								}
								const text = tiptapToMarkdown(editor.schema, {
									...editor.getJSON(),
									type: "doc",
								});
								// replace 2+ consecutive newlines with 2 newlines
								let cleanedText = text.replace(/\n{2,}/g, "\n\n");
								for (const [citationId, metadata] of citationIdsToMetadata) {
									const uploadTitle = formatUploadTitle({
										title: metadata.upload.upload_title,
										subtitle: metadata.upload.upload_subtitle,
										filename: metadata.upload.file_name,
									});
									const uploadAuthors = formatAuthors(
										metadata.upload.upload_authors ?? [],
									);
									const uploadPages = formatPages(
										metadata.chunk.chunk_page_indices,
									);
									// replace the citation with the upload title
									cleanedText = cleanedText.replaceAll(
										`<cite>${citationId}</cite>`,
										`(${uploadAuthors}, ${uploadTitle}, page ${uploadPages})`,
									);
								}
								navigator.clipboard.writeText(cleanedText);
								toast.success("Message copied to clipboard");
							}}
						>
							<Copy weight="bold" />
						</TooltipTrigger>
						<TooltipContent>Copy response</TooltipContent>
					</Tooltip>
					{isLast &&
						!chatContext.viewOnly &&
						!chatContext.chatPendingState.isPending && (
							<div className="flex w-full justify-start">
								<Tooltip>
									<TooltipTrigger
										type="button"
										className="rounded-lg p-1.5 text-neutral-500 hover:bg-neutral-100 hover:text-neutral-800"
										onClick={() => chatContext.rerunResearch()}
									>
										<ArrowsClockwise weight="bold" />
									</TooltipTrigger>
									<TooltipContent>Rerun request</TooltipContent>
								</Tooltip>
							</div>
						)}
				</div>
			</>
		);
	},
);

export const AssistantMessage = observer(
	({ assistantMessage, isLast }: AssistantMessageProps) => {
		return (
			<AnnotationProvider>
				<_AssistantMessage
					assistantMessage={assistantMessage}
					isLast={isLast}
				/>
			</AnnotationProvider>
		);
	},
);
