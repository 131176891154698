import { Button } from "@/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogDescription,
	DialogTitle,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useAppContext } from "@/contexts/AppContext";
import { PrimaryColumnType } from "@api/schemas";
import { Desktop, File, Spinner, TextAlignLeft } from "@phosphor-icons/react";
import * as RadioGroup from "@radix-ui/react-radio-group";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const CreateTableDialog = observer(() => {
	const appContext = useAppContext();
	const [createLoading, setCreateLoading] = useState(false);
	const [tableType, setTableType] = useState<
		"document" | "text" | "computed" | undefined
	>();

	const navigate = useNavigate();

	const handleContinue = () => {
		if (tableType === "document") {
			setCreateLoading(true);

			appContext
				.createTable({
					tableName: "New table",
					primaryColumn: {
						columnName: "Document",
						columnDescription: "Some default description",
						columnType: PrimaryColumnType.document,
					},
				})
				.then((table) => {
					if (table) {
						navigate(`/table/${table.remoteResult?.table_id}`);
						appContext.showCreateTableDialog = false;
					}
				})
				.finally(() => {
					setCreateLoading(false);
				});
		}

		if (tableType === "text") {
			setCreateLoading(true);

			appContext
				.createTable({
					tableName: "New table",
					primaryColumn: {
						columnName: "Text",
						columnDescription: "Some default description",
						columnType: PrimaryColumnType.text,
					},
				})
				.then((table) => {
					if (table) {
						navigate(`/table/${table.remoteResult?.table_id}`);
						appContext.showCreateTableDialog = false;
					}
				})
				.finally(() => {
					setCreateLoading(false);
				});
		}

		if (tableType === "computed") {
			runInAction(() => {
				appContext.showCreateComputedTableDialog = true;
				appContext.showCreateTableDialog = false;
			});
		}
	};

	return (
		<Dialog
			open={appContext.showCreateTableDialog}
			onOpenChange={() => {
				runInAction(() => {
					appContext.showCreateTableDialog = false;
				});
			}}
		>
			<DialogContent className="p-0">
				<section className="px-4 pt-4">
					<DialogTitle>Create new table</DialogTitle>
					<DialogDescription className="mt-2 text-neutral-500">
						Tables help you quickly source and analyze information.
					</DialogDescription>
				</section>
				<section className="flex flex-col gap-2 border-t p-4">
					<Label>Select table type:</Label>
					<RadioGroup.Root
						className="flex flex-col space-y-2"
						onValueChange={(value) => {
							setTableType(
								value ? (value as "document" | "text" | "computed") : undefined,
							);
						}}
						value={tableType}
					>
						<RadioGroup.Item
							value="document"
							className={
								"flex w-full items-center gap-2 rounded border border-neutral-200 p-2"
							}
						>
							<div className="rounded bg-neutral-100 p-2.5">
								<File size={20} />
							</div>
							<div className="flex min-w-0 grow flex-col items-start">
								<span className="text-neutral-950 text-sm">Document table</span>
								<span className="text-neutral-500 text-sm">
									Create a table based on a set of documents.
								</span>
							</div>
							<RadioGroup.Indicator className="relative flex h-4 w-4 shrink-0 items-center justify-center rounded-full border border-neutral-950">
								<div className="h-2 w-2 rounded-full bg-neutral-950" />
							</RadioGroup.Indicator>
						</RadioGroup.Item>
						<RadioGroup.Item
							value="text"
							className="flex w-full items-center gap-2 rounded border border-neutral-200 p-2"
						>
							<div className="rounded bg-neutral-100 p-2.5">
								<TextAlignLeft size={20} />
							</div>
							<div className="flex min-w-0 grow flex-col items-start">
								<span className=" text-neutral-950 text-sm">Text table</span>
								<span className="text-neutral-500 text-sm">
									Create a table based on a set of text.
								</span>
							</div>
							<RadioGroup.Indicator className="relative flex h-4 w-4 shrink-0 items-center justify-center rounded-full border border-neutral-950">
								<div className="h-2 w-2 rounded-full bg-neutral-950" />
							</RadioGroup.Indicator>{" "}
						</RadioGroup.Item>
						<RadioGroup.Item
							value="computed"
							className="flex w-full items-center gap-2 rounded border border-neutral-200 p-2"
						>
							<div className="rounded bg-neutral-100 p-2.5">
								<Desktop size={20} />
							</div>
							<div className="flex min-w-0 grow flex-col items-start">
								<span className="text-neutral-950 text-sm">Computed table</span>
								<span className="text-neutral-500 text-sm">
									Create a table based on a set of other tables.
								</span>
							</div>
							<RadioGroup.Indicator className="relative flex h-4 w-4 shrink-0 items-center justify-center rounded-full border border-neutral-950">
								<div className="h-2 w-2 rounded-full bg-neutral-950" />
							</RadioGroup.Indicator>
						</RadioGroup.Item>
					</RadioGroup.Root>
				</section>
				<section className="p-4">
					<Button
						className="w-full"
						disabled={!tableType || createLoading}
						onClick={handleContinue}
					>
						{createLoading ? (
							<Spinner className="animate-spin" size={20} />
						) : null}
						Continue
					</Button>
				</section>
			</DialogContent>
		</Dialog>
	);
});
