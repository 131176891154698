import { FileUploaderBox } from "@/components/FileUploaderBox";
import {
	DialogContent,
	DialogHeader,
	DialogTitle,
} from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { useAppContext } from "@/contexts/AppContext";
import type { Folder } from "@api/schemas";
import { FolderSimple, Question } from "@phosphor-icons/react";
import { DialogDescription } from "@radix-ui/react-dialog";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const FileUploadDialogContent = observer(
	({
		folder,
	}: {
		folder: Folder | null;
	}) => {
		const appContext = useAppContext();

		const [inferMetadata, setInferMetadata] = useState(true);

		const onDrop = (files?: File[]) => {
			const promises = files?.map((file) => {
				return appContext.createUpload({
					file,
					folderId: folder ? folder.folder_id : null,
					inferMetadata,
				});
			});

			Promise.all(promises || []);
		};

		return (
			<DialogContent className="max-h-[80vh] overflow-y-scroll">
				<DialogHeader>
					<DialogTitle>Upload files</DialogTitle>
					<DialogDescription className="flex items-center gap-1">
						<span>Uploading to</span>
						{folder ? (
							<div className="flex min-w-0 max-w-max items-center gap-1 truncate rounded border bg-neutral-100 px-1.5 py-0.5 text-sm">
								<FolderSimple
									weight="duotone"
									className="text-base text-neutral-500"
								/>
								{folder.file_name}
							</div>
						) : (
							"workspace root"
						)}
					</DialogDescription>
				</DialogHeader>
				<div className="flex items-center gap-2 text-neutral-700">
					<Switch checked={inferMetadata} onCheckedChange={setInferMetadata} />
					<span>Automatically infer metadata</span>
					<Tooltip>
						<TooltipTrigger>
							<Question weight="duotone" className="text-neutral-500 text-xl" />
						</TooltipTrigger>
						<TooltipContent>
							Processes the uploaded document with AI to infer its title,
							author, and other metadata.
						</TooltipContent>
					</Tooltip>
				</div>
				<FileUploaderBox
					description="PDFs and EPUBs supported"
					onDrop={onDrop}
				/>
			</DialogContent>
		);
	},
);
