import { ShowSidebarButton } from "@/components/ShowSidebarButton";
import { useAppContext } from "@/contexts/AppContext";
import { ChatProvider } from "@/contexts/ChatContext";
import { createUserMessageStep } from "@/messaging";
import { ActiveResearchLayout } from "@/pages/Research/ActiveResearchLayout";
import { ChatEmptyState } from "@/pages/Research/ChatEmptyState";
import { ChatInput } from "@/pages/Research/ChatInput";
import type { ChatId } from "@api/schemas";
import clsx from "clsx";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

const ChatView = observer(() => {
	const { activeChatId } = useParams();
	const appContext = useAppContext();

	if (!activeChatId) {
		return (
			<div className="flex h-full w-full min-w-0 grow flex-col items-center">
				<div
					className={clsx(
						"flex h-14 w-full shrink-0 items-center justify-between gap-2 px-2",
					)}
				>
					{!appContext.showSidebar && <ShowSidebarButton />}
				</div>
				<ChatEmptyState />
				<ChatInput
					onSubmit={(message) => {
						if (!message.content.trim()) {
							toast.error("Please enter a message to send.");
							return;
						}
						appContext.navigateToNewChat({
							initialMessages: [
								createUserMessageStep({
									content: message.content,
									// required to work with react-router-dom
									focus_upload_ids: toJS([...message.focusUploadIds]),
									focus_feed_channel_ids: toJS([
										...message.focusFeedChannelIds,
									]),
									idx: 0,
								}),
							],
							chatTitle: null,
						});
					}}
					isPending={false}
				/>
			</div>
		);
	}
	return (
		<ChatProvider key={activeChatId} chatId={activeChatId as ChatId}>
			<ActiveResearchLayout />
		</ChatProvider>
	);
});

export const _Research = observer(() => {
	return (
		<div className="relative flex h-full min-h-0 w-full">
			<Helmet>
				<title>Research - Village</title>
			</Helmet>
			<ChatView />
		</div>
	);
});

export const Research = () => {
	return <_Research />;
};
