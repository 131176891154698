import { cleanXmlText, formatRelativeDate } from "@/lib/formatting";
import type { FeedItemMetadata } from "@api/schemas";
import { File as FileIcon } from "@phosphor-icons/react";
import { observer } from "mobx-react-lite";

export const FeedItemPreview = observer(
	({ feedItem }: { feedItem: FeedItemMetadata | null }) => {
		if (!feedItem) {
			return (
				<div className="flex h-full min-h-64 w-full flex-col items-center justify-center space-y-2 overflow-y-scroll border-l bg-neutral-100 p-2 text-neutral-500 text-sm">
					<FileIcon className="h-8 w-8" />
					<p className="text-center">
						Select a feed item
						<br /> to preview it here
					</p>
				</div>
			);
		}

		return (
			<div
				className="flex h-full min-h-64 flex-col overflow-y-scroll border-l p-4"
				key={feedItem.feed_item_id}
			>
				<div className="pb-4">
					{feedItem.feed_item_og_image ? (
						<img
							src={feedItem.feed_item_og_image}
							alt={feedItem.file_name ?? ""}
							className="flex max-h-64 w-full shrink-0 items-center justify-center rounded object-cover text-xs"
						/>
					) : (
						<></>
					)}
				</div>

				<div>
					<h1 className="font-semibold text-neutral-800 text-sm">
						{feedItem.file_name}
					</h1>
					{feedItem.feed_item_description && (
						<h2 className="mt-2 text-neutral-500 text-sm">
							{cleanXmlText(feedItem.feed_item_description)}
						</h2>
					)}
				</div>
				<div className="mt-4 flex flex-col">
					<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
						<h3>Author</h3>
						<span className="text-right font-semibold">
							{feedItem.feed_item_author ?? "Unknown"}
						</span>
					</div>

					<div className="flex justify-between space-x-1 border-b py-1 text-neutral-950 text-xs">
						<h3>Channel</h3>
						<span className="text-right font-semibold">
							{feedItem.feed_item_author}
						</span>
					</div>
					<div className="flex justify-between space-x-1 py-1 text-neutral-950 text-xs">
						<h3>Published</h3>
						<span className="text-right font-semibold">
							{formatRelativeDate(feedItem.file_created_at)}
						</span>
					</div>
				</div>
			</div>
		);
	},
);
