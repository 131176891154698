import { SingleDocumentSelector } from "@/components/documentSelectors/SingleDocumentSelector";
import { useAppContext } from "@/contexts/AppContext";
import { useTableContext } from "@/contexts/TableContext";
import { formatAuthors, formatUploadTitle } from "@/lib/utils";
import type { TableCellProps } from "@/pages/Table/cellTypes";
import type {
	DocumentCellValue,
	DocumentColumnMetadata,
	FeedItemId,
	UploadId,
} from "@api/schemas";
import clsx from "clsx";
import { observer } from "mobx-react-lite";

const UploadCell = observer(({ uploadId }: { uploadId: UploadId }) => {
	const appContext = useAppContext();

	const upload = appContext.getUploadById(uploadId);

	if (!upload) {
		return <div>Upload not found</div>;
	}

	return (
		<div className="truncate text-sm">
			<h1 className="line-clamp-3 whitespace-break-spaces font-semibold text-neutral-800">
				{formatUploadTitle({
					title: upload.upload_title,
					subtitle: upload.upload_subtitle,
					filename: upload.file_name,
				})}
			</h1>
			<h2 className="min-w-0 truncate whitespace-break-spaces">
				{upload.upload_authors
					? formatAuthors(upload.upload_authors)
					: "Unknown author"}
			</h2>
		</div>
	);
});

const FeedItemCell = observer(({ feedItemId }: { feedItemId: FeedItemId }) => {
	const appContext = useAppContext();

	const feedItem = appContext.getFeedItemById(feedItemId);

	if (!feedItem) {
		return <div>Feed item not found</div>;
	}

	return (
		<div className="truncate text-sm">
			<h1 className="line-clamp-3 whitespace-break-spaces font-semibold text-neutral-800">
				{feedItem.file_name}
			</h1>
			<h2>{feedItem.feed_item_author ?? "Unknown author"}</h2>
		</div>
	);
});

export const DocumentCell = observer(
	({
		rowId,
		columnId,
		cellValue,
		isProxy,
	}: TableCellProps<DocumentColumnMetadata>) => {
		const tableContext = useTableContext();

		let castValue: DocumentCellValue | null;
		if (cellValue && cellValue.cell_value_type !== "document") {
			castValue = null;
		} else {
			castValue = cellValue;
		}
		const currentValue = castValue?.cell_value;

		return (
			<SingleDocumentSelector
				disabled={isProxy || !tableContext.editable}
				popoverTrigger={({ open }) => (
					<div
						className={clsx(
							"h-full w-full min-w-0 truncate whitespace-nowrap p-1 text-neutral-500",
							open && "bg-blue-50 ring-2 ring-blue-300",
						)}
					>
						{currentValue ? (
							<>
								{currentValue.document_type === "upload" && (
									<UploadCell uploadId={currentValue.document_id} />
								)}
								{currentValue.document_type === "feed_item" && (
									<FeedItemCell feedItemId={currentValue.document_id} />
								)}
							</>
						) : null}
					</div>
				)}
				selectedDocumentId={currentValue ?? null}
				onSelect={(documentId) => {
					if (documentId?.document_id === currentValue?.document_id) {
						return;
					}

					documentId &&
						tableContext.updateCell({
							rowId,
							columnId,
							value: {
								cell_value: documentId,
								cell_value_type: "document",
							},
						});
				}}
			/>
		);
	},
);
