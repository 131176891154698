import type { AppState } from "@/contexts/AppContext";
import type { InitialChatState } from "@/contexts/ChatContext";
import { createSyncedAction } from "@/contexts/SyncedActions";
import { newChatId } from "@/idGenerators";
import { deleteChat, renameChat, toggleChatPublic } from "@api/fastAPI";
import type { ChatId, ChatMetadata } from "@api/schemas";
import { toast } from "sonner";

export const deleteChatAction = createSyncedAction<
	AppState,
	{ chatId: ChatId },
	{ deletedChat: ChatMetadata | undefined },
	void
>({
	async local({ chatId }) {
		return this.deleteChatLocally({ chatId });
	},
	async remote({ chatId }) {
		await deleteChat({ chat_id: chatId });
	},
	rollback({ chatId }, { deletedChat }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		if (deletedChat) {
			this.workspace.chats.set(chatId, deletedChat);
		}
	},
	onRemoteSuccess() {
		toast.success("Chat deleted successfully.");
	},
});

export function renameChatLocally(
	this: AppState,
	{ chatId, chatTitle }: { chatId: ChatId; chatTitle: string },
) {
	if (this.workspace === null) {
		throw new Error("Workspace not loaded yet!");
	}
	const chat = this.workspace.chats.get(chatId);
	if (!chat) {
		throw new Error(`Chat with id ${chatId} not found`);
	}
	const oldTitle = chat.file_name;
	chat.file_name = chatTitle;
	return { oldTitle, newTitle: chatTitle };
}

export const renameChatAction = createSyncedAction<
	AppState,
	{ chatId: ChatId; chatTitle: string },
	{ oldTitle: string; newTitle: string },
	void
>({
	async local({ chatId, chatTitle }) {
		return this.renameChatLocally({ chatId, chatTitle });
	},
	async remote({ chatId, chatTitle }) {
		await renameChat({ chat_id: chatId, chat_title: chatTitle });
	},
	rollback({ chatId }, { oldTitle }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const chat = this.workspace.chats.get(chatId);
		if (chat) {
			chat.file_name = oldTitle;
		}
	},
	onRemoteSuccess() {
		toast.success("Chat renamed successfully.");
	},
});

export const toggleChatPublicAction = createSyncedAction<
	AppState,
	{ chatId: ChatId; isPublic: boolean },
	{ oldIsPublic: boolean; newIsPublic: boolean },
	void
>({
	async local({ chatId, isPublic }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const chat = this.workspace.chats.get(chatId);
		if (!chat) {
			throw new Error(`Chat with id ${chatId} not found`);
		}
		const oldIsPublic = chat.chat_is_public;
		chat.chat_is_public = isPublic;
		return { oldIsPublic, newIsPublic: isPublic };
	},
	async remote({ chatId, isPublic }) {
		await toggleChatPublic({ chat_id: chatId, is_public: isPublic });
	},
	rollback({ chatId }, { oldIsPublic }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const chat = this.workspace.chats.get(chatId);
		if (chat) {
			chat.chat_is_public = oldIsPublic;
		}
	},
	onRemoteSuccess({ isPublic }) {
		toast.success(`Chat is now ${isPublic ? "public" : "private"}`);
	},
});

export function deleteChatLocally(
	this: AppState,
	{ chatId }: { chatId: ChatId },
) {
	if (this.workspace === null) {
		throw new Error("Workspace not loaded yet!");
	}
	const chat = this.workspace.chats.get(chatId);
	if (!chat) {
		throw new Error(`Chat with id ${chatId} not found`);
	}
	const deletedChat = { ...chat };
	chat.file_deleted_at = new Date().toISOString();
	return { deletedChat };
}

export function sortedChats(this: AppState): ChatMetadata[] | null {
	if (!this.workspace) {
		return null;
	}
	return Array.from(this.workspace.chats.values())
		.sort((a, b) => {
			return a.file_created_at > b.file_created_at ? -1 : 1;
		})
		.filter((x) => !x.file_deleted_at);
}

export function formatChatPublicLink(chatId: ChatId) {
	return `${window.location.origin}/share/${chatId}`;
}

export function navigateToNewChat(
	this: AppState,
	initialState: InitialChatState,
) {
	const activeChatId = newChatId();
	this.navigate(`/research/${activeChatId}`, {
		state: {
			initialState,
		},
	});
}
