import { newActionId, newStepId } from "@/idGenerators";
import type {
	FeedChannelId,
	UploadId,
	UserStepOutput as UserStep,
} from "@api/schemas";

export const createUserMessageStep = ({
	content,
	focus_upload_ids,
	focus_feed_channel_ids,
	idx,
}: {
	content: string;
	focus_upload_ids: Array<UploadId>;
	focus_feed_channel_ids: Array<FeedChannelId>;
	idx: number;
}): UserStep => ({
	step_id: newStepId(),
	step_idx: idx,
	deleted: false,
	type: "user",
	actions: [
		{
			action_id: newActionId(),
			deleted: false,
			type: "user-message",
			content,
			focus_upload_ids,
			focus_feed_channel_ids,
		},
	],
});
