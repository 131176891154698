import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@/components/ui/popover";
import { TimePicker } from "@/components/ui/time-picker";
import { useTableContext } from "@/contexts/TableContext";
import type { TableCellProps } from "@/pages/Table/cellTypes";
import type { DateTimeColumnMetadata, DatetimeCellValue } from "@api/schemas";
import { X } from "@phosphor-icons/react";
import clsx from "clsx";
import dayjs from "dayjs";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const DatetimeCell = observer(
	({
		rowId,
		columnId,
		cellValue,
		isProxy,
	}: TableCellProps<DateTimeColumnMetadata>) => {
		const tableContext = useTableContext();
		const [open, setOpen] = useState(false);

		let castValue: DatetimeCellValue | null;
		if (cellValue && cellValue.cell_value_type !== "datetime") {
			castValue = null;
		} else {
			castValue = cellValue;
		}
		const currentValue = castValue?.cell_value;

		const [newValue, setNewValue] = useState(
			currentValue ? dayjs.tz(currentValue, "UTC") : null,
		);
		useEffect(() => {
			setNewValue(currentValue ? dayjs.tz(currentValue, "UTC") : null);
		}, [currentValue]);

		/**
		 * carry over the current time when a user clicks a new day
		 * instead of resetting to 00:00
		 */
		const handleSelect = (newDay: Date | undefined) => {
			if (!newDay) return;
			if (!newValue) {
				setNewValue(dayjs.tz(newDay, "UTC"));
				return;
			}

			const updatedDate = newValue
				.year(dayjs.tz(newDay, "UTC").year())
				.month(dayjs.tz(newDay, "UTC").month())
				.date(dayjs.tz(newDay, "UTC").date());

			setNewValue(updatedDate);
		};

		return (
			<Popover
				open={open}
				onOpenChange={(newOpen) => {
					setOpen(newOpen);
					if (!newOpen && newValue) {
						if (dayjs(currentValue).isSame(newValue)) return;
						tableContext.updateCell({
							rowId,
							columnId,
							value: {
								cell_value: newValue ? newValue.utc().toISOString() : null,
								cell_value_type: "datetime",
							},
						});
					}
				}}
			>
				<PopoverTrigger
					disabled={isProxy || !tableContext.editable}
					className={clsx(
						"flex h-full w-full min-w-0 justify-start truncate p-1 text-neutral-600 text-sm",
						open && "bg-blue-50 ring-2 ring-blue-300",
					)}
				>
					{newValue ? newValue.local().format("YYYY-MM-DD HH:mm:ss") : null}
				</PopoverTrigger>
				<PopoverContent className="w-auto p-2">
					<Calendar
						mode="single"
						selected={newValue?.toDate()}
						onSelect={(d) => handleSelect(d)}
						initialFocus
					/>
					<div className="border-t p-3">
						<TimePicker
							setDate={(newDate) => {
								setNewValue(dayjs(newDate));
							}}
							date={newValue?.toDate()}
						/>
					</div>
					<Button
						onClick={() => {
							tableContext.updateCell({
								rowId,
								columnId,
								value: {
									cell_value: null,
									cell_value_type: "datetime",
								},
							});
						}}
						variant="ghost"
						className="mt-1 flex w-full items-center justify-start gap-1"
					>
						<X className="" />
						Clear date
					</Button>
				</PopoverContent>
			</Popover>
		);
	},
);
