import type { AppState } from "@/contexts/AppContext";
import { createSyncedAction } from "@/contexts/SyncedActions";
import { formatUploadTitle } from "@/lib/utils";
import { moveDirectoryNodes } from "@api/fastAPI";
import type { Folder, FolderId, Upload, UploadId } from "@api/schemas";
import type { MoveHandler } from "react-arborist";

export class DirectoryNode<T extends "folder" | "upload"> {
	node_id: string;
	item: T extends "folder"
		? { type: "folder"; data: Folder }
		: { type: "upload"; data: Upload };

	constructor(
		node_id: string,
		item: T extends "folder"
			? { type: "folder"; data: Folder }
			: { type: "upload"; data: Upload },
	) {
		this.node_id = node_id;
		this.item = item;
	}

	static fromFolder(folder: Folder): FolderDirectoryNode {
		return new DirectoryNode(folder.folder_id, {
			type: "folder",
			data: folder,
		});
	}

	static fromUpload(upload: Upload): UploadDirectoryNode {
		return new DirectoryNode(upload.upload_id, {
			type: "upload",
			data: upload,
		});
	}

	get name(): string {
		return this.item.type === "folder"
			? this.item.data.file_name
			: formatUploadTitle({
					title: this.item.data.upload_title,
					subtitle: this.item.data.upload_subtitle,
					filename: this.item.data.file_name,
				});
	}
}
export type AnyDirectoryNode = DirectoryNode<"folder" | "upload">;
export type FolderDirectoryNode = DirectoryNode<"folder">;
export type UploadDirectoryNode = DirectoryNode<"upload">;

export function rootDirectoryNodes(this: AppState): AnyDirectoryNode[] | null {
	if (!this.workspace) {
		return null;
	}
	const root_folders = [...this.workspace.folders.values()].filter(
		(folder) => folder.folder_parent_id === null && !folder.file_deleted_at,
	);
	const root_uploads = [...this.workspace.uploads.values()].filter(
		(upload) =>
			upload.upload_parent_id === null &&
			!upload.file_deleted_at &&
			upload.upload_status !== "failed",
	);

	const mergedNodes = [
		...root_folders.map((folder) => DirectoryNode.fromFolder(folder)),
		...root_uploads.map((upload) => DirectoryNode.fromUpload(upload)),
	];
	mergedNodes.sort((a, b) => a.name.localeCompare(b.name));

	return mergedNodes;
}

export function folderImmediateChildren(this: AppState) {
	const folderIdToChildren = new Map<FolderId, AnyDirectoryNode[]>();

	if (!this.workspace) {
		return folderIdToChildren;
	}

	for (const folder of this.workspace.folders.values()) {
		if (folder.file_deleted_at) {
			continue;
		}

		if (folder.folder_parent_id) {
			const children = folderIdToChildren.get(folder.folder_parent_id) || [];
			children.push(DirectoryNode.fromFolder(folder));
			folderIdToChildren.set(folder.folder_parent_id, children);
		}
	}

	for (const upload of this.workspace.uploads.values()) {
		// hide failed uploads from the directory tree
		if (upload.file_deleted_at || upload.upload_status === "failed") {
			continue;
		}

		if (upload.upload_parent_id) {
			const children = folderIdToChildren.get(upload.upload_parent_id) || [];
			children.push(DirectoryNode.fromUpload(upload));
			folderIdToChildren.set(upload.upload_parent_id, children);
		}
	}

	for (const children of folderIdToChildren.values()) {
		children.sort((a, b) => a.name.localeCompare(b.name));
	}

	return folderIdToChildren;
}

export function treeChildrenAccessor(
	this: AppState,
	node: FolderDirectoryNode,
) {
	return this.folderImmediateChildren.get(node.item.data.folder_id) || [];
}

export function getFolderDescendants(
	this: AppState,
	folderId: FolderId,
): {
	folderIds: FolderId[];
	uploadIds: UploadId[];
} {
	const result = {
		folderIds: [] as FolderId[],
		uploadIds: [] as UploadId[],
	};

	if (!this.workspace) {
		return result;
	}

	const queue: FolderId[] = [folderId];

	while (queue.length > 0) {
		const currentFolderId = queue.shift();
		if (currentFolderId === undefined) {
			continue;
		}

		const children = this.folderImmediateChildren.get(currentFolderId) || [];

		for (const child of children) {
			if (child.item.type === "folder") {
				result.folderIds.push(child.item.data.folder_id);
				queue.push(child.item.data.folder_id);
			} else {
				result.uploadIds.push(child.item.data.upload_id);
			}
		}
	}

	return result;
}

export const treeMoveHandlerAction = createSyncedAction<
	AppState,
	Parameters<MoveHandler<AnyDirectoryNode>>[0],
	{
		movedNodes: AnyDirectoryNode[];
		newParentId: FolderId | null;
	},
	void
>({
	async local({ dragNodes, parentId }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const movedNodes: AnyDirectoryNode[] = [];

		for (const node of dragNodes) {
			if (node.data.item.type === "folder") {
				const folder = this.getFolderById(node.data.item.data.folder_id);
				if (folder && folder.folder_parent_id !== parentId) {
					folder.folder_parent_id = parentId as FolderId;
					movedNodes.push(node.data);
				}
			} else {
				const upload = this.getUploadById(node.data.item.data.upload_id);
				if (upload && upload.upload_parent_id !== parentId) {
					upload.upload_parent_id = parentId as FolderId;
					movedNodes.push(node.data);
				}
			}
		}

		return { movedNodes, newParentId: parentId as FolderId | null };
	},
	async remote({ parentId }, { movedNodes }) {
		const draggedFolderIds: FolderId[] = [];
		const draggedUploadIds: UploadId[] = [];

		for (const node of movedNodes) {
			if (node.item.type === "folder") {
				draggedFolderIds.push(node.item.data.folder_id);
			} else {
				draggedUploadIds.push(node.item.data.upload_id);
			}
		}

		if (draggedFolderIds.length || draggedUploadIds.length) {
			await moveDirectoryNodes({
				folder_ids: draggedFolderIds,
				upload_ids: draggedUploadIds,
				parent_id: parentId as FolderId | null,
			});
		}
	},
	rollback(_, { movedNodes }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		for (const node of movedNodes) {
			if (node.item.type === "folder") {
				const folder = this.getFolderById(node.item.data.folder_id);
				if (folder) {
					folder.folder_parent_id = node.item.data.folder_parent_id;
				}
			} else {
				const upload = this.getUploadById(node.item.data.upload_id);
				if (upload) {
					upload.upload_parent_id = node.item.data.upload_parent_id;
				}
			}
		}
	},
});

export function treeMoveHandler(
	this: AppState,
	moveParams: Parameters<MoveHandler<AnyDirectoryNode>>[0],
): ReturnType<MoveHandler<AnyDirectoryNode>> {
	this.treeMoveHandlerAction(moveParams);
}
