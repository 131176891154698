import { API_ENDPOINT_WS, IS_DEV } from "@/config";
import { type AppState, useAppContext } from "@/contexts/AppContext";
import type { PDFViewerState } from "@/contexts/PDFViewerContext";
import type { SearchLibraryResultWithUpload } from "@/contexts/SearchContext";
import { createUserMessageStep } from "@/messaging";
import { viewPublicChat } from "@api/fastAPI";
import {
	ChatDisconnectCode,
	type ChatHistoryItem,
	type ChatId,
	type ChatRequest,
	type ChatResponse,
	type ChunkId,
	type ExecuteResearchReq,
	type FeedChannelId,
	type InitChatWithHistoryReq,
	type SearchFeedItemsResultOutput as SearchFeedItemsResult,
	type SearchLibraryResultOutput as SearchLibraryResult,
	type Upload,
	type UploadId,
} from "@api/schemas";
import { useAuth } from "@clerk/clerk-react";
import * as Sentry from "@sentry/react";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext, useEffect, useRef } from "react";
import { flushSync } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const MAX_RECONNECT_ATTEMPTS = 5;

export class PDFAnnotation {
	upload: Upload;
	textToHighlight: {
		textStart: string;
		textEnd?: string;
	};
	pageIndicesToSearch: number[];
	type: "pdf-annotation";

	constructor({
		upload,
		textToHighlight,
		pageIndicesToSearch,
	}: {
		upload: Upload;
		textToHighlight: {
			textStart: string;
			textEnd?: string;
		};
		pageIndicesToSearch: number[];
	}) {
		this.upload = upload;
		this.textToHighlight = textToHighlight;
		this.pageIndicesToSearch = pageIndicesToSearch;
		this.type = "pdf-annotation";
	}
}

export class ChatState {
	chatId: ChatId;
	chatTitle: string | null = null;
	private _steps: Map<string, ChatHistoryItem> | null = null;
	appState: AppState;
	viewOnly: boolean;

	activeSearchResult:
		| SearchLibraryResultWithUpload
		| SearchFeedItemsResult
		| PDFAnnotation
		| null = null;

	ws: WebSocket | null = null;
	wsConnected = false;
	reconnectAttempts = 0;
	reconnectTimeoutId: Timer | null = null;
	chatInitialized = false;
	chatInitializationFailed = false;

	viewerState: PDFViewerState | null = null;

	chatPendingState: {
		isPending: boolean;
		message?: string;
	} = {
		isPending: false,
	};

	navigate: ReturnType<typeof useNavigate>;
	getToken: ReturnType<typeof useAuth>["getToken"];

	chatContainerRef = useRef<HTMLDivElement | null>(null);

	constructor({
		chatId,
		navigate,
		appState,
		getToken,
		viewOnly,
	}: {
		chatId: ChatId;
		navigate: ReturnType<typeof useNavigate>;
		appState: AppState;
		getToken: ReturnType<typeof useAuth>["getToken"];
		viewOnly: boolean;
	}) {
		this.chatId = chatId;
		this.navigate = navigate;
		this.appState = appState;
		this.getToken = getToken;
		this.viewOnly = viewOnly;

		makeAutoObservable(this);
	}

	async init({
		initialState,
		isReconnect,
	}: {
		initialState?: InitialChatState;
		isReconnect?: boolean;
	}) {
		const token = await this.getToken();

		if (!token) {
			Sentry.captureMessage("No token found in ChatContext", "error");
			toast.error("Unable to authenticate. Please refresh the page.");
			return;
		}

		const ws = new WebSocket(
			`${API_ENDPOINT_WS}/research/${this.chatId}/ws?token=${token}`,
		);

		ws.onopen = () => {
			runInAction(() => {
				this.ws = ws;
				this.wsConnected = true;
				if (isReconnect) {
					this.reconnectAttempts = 0;
					toast.success("Reconnected to chat.");
				}
			});

			// If the chat is being initialized with a first message, send it as soon as the websocket is connected
			if (initialState) {
				this.initChatWithHistory(initialState);

				// clear the first message after sending so it doesn't get sent again on refresh
				this.navigate(location.pathname, {
					state: {},
				});
			}
		};

		ws.onmessage = (event) => {
			try {
				const data: ChatResponse = JSON.parse(event.data);
				this.#handleWsResponse(data);
			} catch (e) {
				console.error("Error parsing websocket response JSON:", e);
				return;
			}
		};

		ws.onclose = (e) => {
			// 4040 is the code for "chat deleted"
			if (e.code === ChatDisconnectCode.NUMBER_4040) {
				this.appState.deleteChatLocally({ chatId: this.chatId });
				this.navigate("/research");
				toast.error("Chat has been deleted.");
			}
			// 1000 is the code for "normal closure"
			else if (e.code === 1000) {
				return;
			}
			// 4000 is the code for session initialization failures, for which we display an error
			else if (e.code === ChatDisconnectCode.NUMBER_4000) {
				runInAction(() => {
					this.chatInitializationFailed = true;
				});
			}
			// otherwise, there is an unhandled error and we should attempt to reconnect
			else {
				runInAction(() => {
					this.wsConnected = false;
					this.ws = null;
				});

				this.#attemptReconnect();
			}
		};

		ws.onerror = (error) => {
			Sentry.captureException(error);
			this.#attemptReconnect();
		};
	}

	async initViewOnly() {
		viewPublicChat(this.chatId).then((res) => {
			this._steps = new Map(
				res.data.chat.history.map((item) => [item.step_id, item]),
			);
			this.chatTitle = res.data.chat_title;
			this.chatInitialized = true;
		});
	}

	#attemptReconnect() {
		Sentry.captureMessage("WebSocket connection lost", "error");

		if (this.reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
			console.error("Max reconnection attempts reached");
			toast.error("Unable to reconnect. Please refresh the page.");
			return;
		}
		toast.error("Connection lost. Reconnecting...");

		const delay = Math.min(1000 * 2 ** this.reconnectAttempts, 30000);

		this.reconnectTimeoutId = setTimeout(() => {
			this.reconnectAttempts++;
			this.init({
				isReconnect: true,
			});
		}, delay);
	}

	#sendWsRequest(req: ChatRequest) {
		if (this.ws === null) {
			throw Error("Cannot send request without WebSocket connection.");
		}
		this.ws.send(JSON.stringify(req));
	}

	#handleWsResponse(data: ChatResponse) {
		switch (data.type) {
			case "debug": {
				break;
			}
			case "init": {
				if (!this._steps) {
					this._steps = new Map();
				}
				for (const item of data.steps) {
					this._steps.set(item.step_id, item);
				}

				this.chatTitle = data.chat_metadata.file_name;

				flushSync(() => {
					this.appState.addChatLocally(data.chat_metadata);
					this.chatInitialized = true;
				});

				setTimeout(() => {
					this.chatContainerRef.current?.scrollTo({
						top: this.chatContainerRef.current.scrollHeight,
						behavior: "instant",
					});
				}, 10);

				break;
			}
			case "ping": {
				break;
			}
			case "upsert_steps": {
				const newSteps = data.steps;

				if (this._steps === null) {
					this._steps = new Map();
				}

				for (const step of newSteps) {
					this._steps.set(step.step_id, step);
				}

				// TODO: do something better here...
				setTimeout(() => {
					this.chatContainerRef.current?.scrollTo({
						top: this.chatContainerRef.current.scrollHeight,
						behavior: "smooth",
					});
				});

				break;
			}
			case "update_title": {
				// update the titles both in this context and in the global app context
				this.chatTitle = data.new_title;
				this.appState.renameChatLocally({
					chatId: this.chatId,
					chatTitle: data.new_title,
				});
				break;
			}
			case "set_pending": {
				this.chatPendingState = {
					isPending: data.is_pending,
					message: data.message || undefined,
				};
				break;
			}
			case "delete_messages": {
				if (this._steps === null) {
					throw Error("Steps should not be null when deleting messages.");
				}

				for (const stepId of data.deleted_step_ids) {
					const step = this._steps.get(stepId);
					if (step) {
						step.deleted = true;
					}
				}

				break;
			}
			case "error": {
				if (IS_DEV) {
					toast.error(data.message);
				} else {
					toast.error("An error occurred. Please try again later.");
				}
				this.chatPendingState = {
					isPending: false,
				};
				break;
			}
			default: {
				const _exhaustiveCheck: never = data;
				return _exhaustiveCheck;
			}
		}
	}

	research({
		content,
		focusUploadIds,
		focusFeedChannelIds,
	}: {
		content: string;
		focusUploadIds: Set<UploadId>;
		focusFeedChannelIds: Set<FeedChannelId>;
	}) {
		if (!content.trim()) {
			toast.error("Please enter a message to send.");
			return;
		}
		if (this._steps === null) {
			throw Error("Active chat should never be null when sending research.");
		}
		this.chatPendingState = {
			isPending: true,
		};
		const stepIdx =
			Math.max(
				...Array.from(this._steps.values()).map((step) => step.step_idx),
			) + 1;
		const step = createUserMessageStep({
			content,
			focus_upload_ids: [...focusUploadIds],
			focus_feed_channel_ids: [...focusFeedChannelIds],
			idx: stepIdx,
		});
		const req: ExecuteResearchReq = {
			user_step: step,
			type: "research",
		};

		this.#sendWsRequest(req);
	}

	initChatWithHistory({ initialMessages, chatTitle }: InitialChatState) {
		this.chatInitialized = true;
		this.chatPendingState = {
			isPending: true,
		};

		if (this._steps && this._steps.size > 0) {
			toast.error(
				"Steps should be null/empty when initializing a new chat with predefined conversation history.",
			);
		}

		this._steps = new Map(initialMessages.map((item) => [item.step_id, item]));

		const req: InitChatWithHistoryReq = {
			initial_steps: initialMessages,
			chat_title: chatTitle,
			type: "init-chat",
		};

		this.#sendWsRequest(req);
	}

	rerunResearch() {
		if (!this.sortedSteps) return;

		const lastUserGroupIdx = this.sortedSteps.findLastIndex(
			(group) => group.type === "user" && !group.deleted,
		);

		// mark all groups after the last user group as deleted
		for (let i = lastUserGroupIdx + 1; i < this.sortedSteps.length; i++) {
			const stepId = this.sortedSteps[i].step_id;
			const step = this._steps?.get(stepId);
			if (step) {
				step.deleted = true;
			}
		}
		this.chatPendingState = {
			isPending: true,
		};
		this.#sendWsRequest({
			type: "rerun",
			client_last_user_group_idx: lastUserGroupIdx,
		});
	}

	/**
	 * Accessor for all steps in the chat sorted by index. Excludes deleted steps.
	 */
	get sortedSteps() {
		if (this._steps === null) {
			return null;
		}

		return Array.from(this._steps.values())
			.sort((a, b) => (a.step_idx < b.step_idx ? -1 : 1))
			.filter((step) => !step.deleted);
	}

	get retrievedChunks() {
		if (this._steps === null) {
			return null;
		}

		const chunks = new Map<ChunkId, SearchLibraryResult>();

		for (const group of this._steps.values()) {
			if (group.type === "environment") {
				for (const action of group.actions) {
					for (const result of action.search_response.library_results) {
						chunks.set(result.chunk_id, result);
					}
				}
			}
		}

		return chunks;
	}

	cleanup() {
		if (this.reconnectTimeoutId !== null) {
			clearTimeout(this.reconnectTimeoutId);
		}
		this.ws?.close(1000, "Client closed connection");
	}
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
export const ChatContext = createContext<ChatState>(null as any);

export const useChatContext = () => {
	const context = useContext(ChatContext);
	if (!context) {
		throw new Error("ChatContext must be used within a ChatProvider");
	}
	return context;
};

export type InitialChatState = {
	initialMessages: Array<ChatHistoryItem>;
	chatTitle: string | null;
};

export type ResearchLocationState = {
	initialState?: InitialChatState;
};

export const ChatProvider: React.FC<{
	chatId: ChatId;
	children: React.ReactNode;
}> = ({ chatId, children }) => {
	// when does navigate rerender?Is there
	const navigate = useNavigate();
	const appContext = useAppContext();
	const { getToken } = useAuth();

	const location = useLocation();
	const state = location.state as ResearchLocationState | undefined;

	const didInit = useRef(false);

	const chatState = useRef(
		new ChatState({
			chatId,
			navigate,
			appState: appContext,
			getToken,
			viewOnly: false,
		}),
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		if (!didInit.current) {
			didInit.current = true;
			chatState.current.init({
				initialState: state?.initialState,
				isReconnect: false,
			});
		}
		return () => {
			chatState.current.cleanup();
		};
	}, []);

	return (
		<ChatContext.Provider value={chatState.current}>
			{children}
		</ChatContext.Provider>
	);
};

export const ViewOnlyChatProvider: React.FC<{
	chatId: ChatId;
	children: React.ReactNode;
}> = ({ chatId, children }) => {
	const navigate = useNavigate();
	const appContext = useAppContext();
	const { getToken } = useAuth();

	const chatState = useRef(
		new ChatState({
			chatId,
			navigate,
			appState: appContext,
			getToken,
			viewOnly: true,
		}),
	);

	const didInit = useRef(false);

	useEffect(() => {
		if (!didInit.current) {
			didInit.current = true;
			chatState.current.initViewOnly();
		}
		return () => {
			chatState.current.cleanup();
		};
	}, []);

	return (
		<ChatContext.Provider value={chatState.current}>
			{children}
		</ChatContext.Provider>
	);
};
