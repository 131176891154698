import {
	ColumnTypeIcons,
	ColumnTypeLabels,
} from "@/components/ColumnTypeOptions";
import { Button } from "@/components/ui/button";
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuLabel,
	DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@/components/ui/select";
import { useTableContext } from "@/contexts/TableContext";
import {
	type ColumnMetadata,
	type TableColumnId,
	TableColumnType,
} from "@api/schemas";
import { Plus } from "@phosphor-icons/react";
import { DropdownMenuTrigger } from "@radix-ui/react-dropdown-menu";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { toast } from "sonner";

const ProxyColumnSelector = observer(
	({
		proxiedColumnId,
		setProxiedColumnId,
	}: {
		proxiedColumnId: TableColumnId | null;
		setProxiedColumnId: (value: TableColumnId | null) => void;
	}) => {
		const tableContext = useTableContext();

		if (!tableContext.parentTableColumns) {
			return null;
		}

		const sortedColumns = [...tableContext.parentTableColumns.values()].sort(
			(a, b) => {
				return a.column_order.localeCompare(b.column_order);
			},
		);

		return (
			<Select
				value={proxiedColumnId ?? undefined}
				onValueChange={(newValue) => {
					setProxiedColumnId(newValue as TableColumnId);
				}}
			>
				<SelectTrigger>
					<SelectValue placeholder="Select column..." />
				</SelectTrigger>
				<SelectContent>
					{sortedColumns.map((column) => {
						return (
							<SelectItem key={column.column_id} value={column.column_id}>
								<div className="flex items-center gap-3">
									{ColumnTypeIcons[column.column_metadata.column_type]({})}
									{column.column_metadata.column_name}
								</div>
							</SelectItem>
						);
					})}
				</SelectContent>
			</Select>
		);
	},
);

export const ColumnCreationHeader = observer(() => {
	const tableContext = useTableContext();
	const [columnName, setColumnName] = useState("");
	const [columnType, setColumnType] = useState<TableColumnType>(
		TableColumnType.text,
	);
	const [columnDescription, setColumnDescription] = useState("");

	const [proxiedColumnId, setProxiedColumnId] = useState<TableColumnId | null>(
		null,
	);

	return (
		<div className="flex items-center gap-2 p-0.5">
			<DropdownMenu>
				<DropdownMenuTrigger asChild>
					<button
						type="button"
						className="rounded-md p-1.5 hover:bg-neutral-100"
					>
						<Plus className="text-lg " />
					</button>
				</DropdownMenuTrigger>
				<DropdownMenuContent align="start" className="w-64">
					<DropdownMenuLabel>Add a column</DropdownMenuLabel>
					<DropdownMenuSeparator />
					<div className="flex flex-col gap-2 p-2">
						{/* Label input */}
						<section>
							<Label>Label</Label>
							<Input
								value={columnName}
								onChange={(e) => setColumnName(e.target.value)}
								placeholder="Label..."
							/>
						</section>
						{/* Description input */}
						<section>
							<Label>Description</Label>
							<Input
								value={columnDescription}
								onChange={(e) => setColumnDescription(e.target.value)}
								placeholder="Description..."
							/>
						</section>
						{/* Type selector */}
						<section>
							<Label>Type</Label>
							<Select
								value={columnType}
								onValueChange={(newValue) => {
									setColumnType(newValue as TableColumnType);
								}}
							>
								<SelectTrigger>
									{columnType ? (
										<div className="flex items-center">
											{(() => {
												const IconComponent = ColumnTypeIcons[columnType];
												return <IconComponent className="mr-2" />;
											})()}
											{ColumnTypeLabels[columnType]}
										</div>
									) : (
										<SelectValue placeholder="Select..." />
									)}
								</SelectTrigger>
								<SelectContent className="max-w-72">
									<SelectItem value={TableColumnType.text}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.text]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Text</h2>
												<span className="text-neutral-500">
													A simple text field that you directly input data into.
												</span>
											</div>
										</div>
									</SelectItem>
									<SelectItem value={TableColumnType.document}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.document]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Document</h2>
												<span className="text-neutral-500">
													A reference to an upload or article in your workspace.
												</span>
											</div>
										</div>
									</SelectItem>
									<SelectItem value={TableColumnType.category}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.category]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Select</h2>
												<span className="text-neutral-500">
													An option you can select from a list. Helpful for
													categorizing data.
												</span>
											</div>
										</div>
									</SelectItem>
									<SelectItem value={TableColumnType.boolean}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.boolean]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Checkbox</h2>
												<span className="text-neutral-500">
													Indicate if an item matches a condition.
												</span>
											</div>
										</div>
									</SelectItem>
									<SelectItem value={TableColumnType.date}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.date]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Date</h2>
												<span className="text-neutral-500">
													A specific date. Useful for deadlines or events.
												</span>
											</div>
										</div>
									</SelectItem>
									<SelectItem value={TableColumnType.datetime}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.datetime]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Date + time</h2>
												<span className="text-neutral-500">
													A specific date and time. Useful for deadlines or
													events.
												</span>
											</div>
										</div>
									</SelectItem>
									<SelectItem value={TableColumnType.number}>
										<div className="flex items-center gap-3">
											{ColumnTypeIcons[TableColumnType.number]({
												className: "shrink-0 text-2xl text-neutral-700",
											})}
											<div>
												<h2>Number</h2>
												<span className="text-neutral-500">
													A number. Useful for quantities or measurements.
												</span>
											</div>
										</div>
									</SelectItem>
									{tableContext.table.parentTableIds.size > 0 ? (
										<SelectItem value={TableColumnType.proxy}>
											<div className="flex items-center gap-3">
												{ColumnTypeIcons[TableColumnType.proxy]({
													className: "shrink-0 text-2xl text-neutral-700",
												})}
												<div>
													<h2>Rollup</h2>
													<span className="text-neutral-500">
														Sync a column from the parent table.
													</span>
												</div>
											</div>
										</SelectItem>
									) : null}
								</SelectContent>
							</Select>
						</section>

						{
							// Rollup column selector
							columnType === TableColumnType.proxy ? (
								<section>
									<Label>Rollup column</Label>
									<ProxyColumnSelector
										proxiedColumnId={proxiedColumnId}
										setProxiedColumnId={setProxiedColumnId}
									/>
								</section>
							) : null
						}

						<Button
							className="mt-4"
							onClick={() => {
								let columnMetadata: ColumnMetadata;
								if (columnType === TableColumnType.proxy) {
									if (!proxiedColumnId) {
										toast.error("Please select a rollup column.");
										return;
									}
									columnMetadata = {
										column_type: columnType,
										proxied_column_ids: [proxiedColumnId],
										column_name: columnName,
										column_description: columnDescription,
									};
								} else if (columnType === TableColumnType.proxy_group) {
									throw new Error("Rollup group columns are not supported.");
								} else if (columnType === TableColumnType.category) {
									columnMetadata = {
										column_type: columnType,
										categories: {},
										column_name: columnName,
										column_description: columnDescription,
									};
								} else {
									columnMetadata = {
										column_type: columnType,
										column_name: columnName,
										column_description: columnDescription,
									};
								}
								tableContext.createColumn({
									columnMetadata,
								});
							}}
						>
							Add column
						</Button>
					</div>
				</DropdownMenuContent>
			</DropdownMenu>
		</div>
	);
});
