import filePdfIcon from "@/assets/file-pdf.svg";
import { ImageWithFallback } from "@/components/ImageWithFallback";
import {
	Tooltip,
	TooltipContent,
	TooltipTrigger,
} from "@/components/ui/tooltip";
import { API_ENDPOINT_HTTP } from "@/config";
import type { UploadId, UploadStatus } from "@api/schemas";
import { CircleNotch, Warning } from "@phosphor-icons/react";
import type { ImgHTMLAttributes } from "react";

type SourceCoverImageProps = Omit<
	ImgHTMLAttributes<HTMLImageElement>,
	"className"
> & {
	upload_id: UploadId;
	upload_status: UploadStatus;
	size: 128 | 256 | 512;
	className: (uploadStatus: UploadStatus) => string;
};

export const UploadCoverImage = ({
	upload_id,
	upload_status,
	size,
	className,
	...props
}: SourceCoverImageProps) => {
	if (upload_status === "failed") {
		return (
			<Tooltip>
				<TooltipTrigger>
					<div className={className(upload_status)}>
						<Warning className="w-full text-red-500" weight="fill" />
					</div>
				</TooltipTrigger>
				<TooltipContent align="start">
					Source failed to process. Please try again.
				</TooltipContent>
			</Tooltip>
		);
	}

	if (upload_status === "pending") {
		return (
			<div className={className(upload_status)}>
				<CircleNotch
					className="w-full animate-spin text-emerald-500"
					weight="bold"
				/>
			</div>
		);
	}

	return (
		<ImageWithFallback
			className={className(upload_status)}
			{...props}
			src={`${API_ENDPOINT_HTTP}/uploads/cover_image/${upload_id}/${size}.jpg`}
			alt="Source thumbnail"
			fallbackSrc={filePdfIcon}
		/>
	);
};
