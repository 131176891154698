import { Button } from "@/components/ui/button";
import { useChatContext } from "@/contexts/ChatContext";
import { AssistantMessage } from "@/pages/Research/AssistantMessage";
import { ChatEmptyState } from "@/pages/Research/ChatEmptyState";
import { ChatHeader } from "@/pages/Research/ChatHeader";
import { ChatInput } from "@/pages/Research/ChatInput";
import { ChatSearchResults } from "@/pages/Research/ChatSearchResults";
import { UserMessage } from "@/pages/Research/UserMessage";
import type { ChatHistoryItem } from "@api/schemas";
import { ArrowCounterClockwise, WarningDiamond } from "@phosphor-icons/react";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import { EditorContent, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";

export type Action = ChatHistoryItem extends { actions: (infer A)[] }
	? A
	: never;

const ActionComponent = observer(
	({
		action,
		isLast,
	}: {
		action: Action;
		isLast: boolean;
	}) => {
		switch (action.type) {
			case "assistant-message":
				return <AssistantMessage assistantMessage={action} isLast={isLast} />;
			case "user-message":
				return <UserMessage userMessage={action} />;
			case "search-results":
				return <ChatSearchResults searchResultsAction={action} />;
			case "expand-search": {
				return null;
			}
			case "search": {
				return null;
			}
			case "thinking": {
				return null;
			}
			case "wait": {
				return null;
			}
			default: {
				const _exhaustiveCheck: never = action;
				return _exhaustiveCheck;
			}
		}
	},
);

// TODO: refactor to share with Research/AssistantMessage.tsx
const TABLE_STYLES =
	"prose-table:border prose-table:border-collapse prose-th:bg-neutral-100 prose-td:border prose-td:px-2 prose-th:px-2 prose-th:border prose-headings:font-semibold prose-td:text-left prose-th:text-left prose-td:align-top prose-th:align-top prose-table:mb-2";

const ThinkingComponent = observer(({ thinking }: { thinking: string }) => {
	const editor = useEditor(
		{
			extensions: [StarterKit, Table, TableCell, TableHeader, TableRow, Link],
			content: thinking,
			editable: false,
		},
		[thinking],
	);
	return (
		<EditorContent
			editor={editor}
			className={clsx(
				"prose-sm group mt-2 prose-ol:list-decimal prose-ul:list-disc border-l-2 px-2.5 text-neutral-600 text-sm italic",
				TABLE_STYLES,
			)}
		/>
	);
});

const StepComponent = observer(
	({ step, isLast }: { step: ChatHistoryItem; isLast: boolean }) => {
		return (
			<motion.div
				key={step.step_id}
				className="flex w-full min-w-0 max-w-full flex-col"
				initial="hidden"
				animate="visible"
				exit="hidden"
				variants={{
					hidden: { opacity: 0 },
					visible: { opacity: 1 },
				}}
				transition={{ duration: 0.2 }}
			>
				{step.type === "assistant" && (
					<>
						<div className="font-semibold text-emerald-600 text-sm">
							Assistant
						</div>
						{step.thinking && <ThinkingComponent thinking={step.thinking} />}
					</>
				)}
				{step.actions
					.filter((action) => !action.deleted)
					.map((action) => (
						<div
							key={action.action_id}
							className="flex w-full min-w-0 max-w-full flex-col"
						>
							<ActionComponent action={action} isLast={isLast} />
						</div>
					))}
			</motion.div>
		);
	},
);

export const Chat = observer(() => {
	const chatContext = useChatContext();
	const chatContainerRef = useRef<HTMLDivElement>(null);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	useEffect(() => {
		chatContext.chatContainerRef = chatContainerRef;
	}, []);

	const renderChatBody = () => {
		if (chatContext.chatInitializationFailed) {
			return (
				<div className="flex h-full w-full flex-col items-center justify-center px-8 text-center">
					<WarningDiamond
						className="mb-8 shrink-0 text-6xl text-amber-500"
						weight="duotone"
					/>
					<h2 className="mb-4 shrink-0 font-semibold text-3xl">
						Could not load conversation
					</h2>
					<div className="mb-6 shrink-0 text-gray-600">
						This error has automatically been reported. Please try refreshing
						the page.
					</div>
					<Button
						onClick={() => window.location.reload()}
						variant="default"
						className="flex shrink-0 items-center gap-2"
					>
						<ArrowCounterClockwise weight="bold" />
						Reload Page
					</Button>
				</div>
			);
		}

		if (chatContext.chatInitialized === false) {
			return <div className="flex h-full w-full items-center justify-center" />;
		}

		if (
			chatContext.sortedSteps === null ||
			chatContext.sortedSteps.length === 0
		) {
			return <ChatEmptyState />;
		}

		return (
			<div className="flex w-full min-w-0 max-w-2xl flex-col items-end gap-6 px-4 py-8">
				<AnimatePresence>
					{chatContext.sortedSteps?.map((step, idx) => (
						<StepComponent
							key={step.step_id}
							step={step}
							isLast={idx + 1 === chatContext.sortedSteps?.length}
						/>
					))}
				</AnimatePresence>
				{chatContext.chatPendingState.isPending && (
					<div className="flex w-full items-center gap-2">
						{chatContext.chatPendingState.message ? (
							<div className="animate-pulse text-emerald-700 text-sm">
								{chatContext.chatPendingState.message}
							</div>
						) : (
							<div className="h-6 w-1 animate-blink bg-emerald-500" />
						)}
					</div>
				)}
			</div>
		);
	};

	return (
		<div className="relative flex h-full w-full min-w-0 flex-col items-center">
			<ChatHeader />
			<div
				className="flex w-full grow flex-col items-center overflow-y-auto pt-16"
				ref={chatContainerRef}
			>
				{renderChatBody()}
			</div>
			{!chatContext.viewOnly && !chatContext.chatInitializationFailed && (
				<ChatInput
					onSubmit={(message) => {
						chatContext.research(message);
					}}
					isPending={chatContext.chatPendingState.isPending}
				/>
			)}
		</div>
	);
});
