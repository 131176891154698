import { ChatLink } from "@/components/ChatLink";
import { CommandKButton } from "@/components/CommandK";
import { FeedLink } from "@/components/FeedLink";
import { TableLink } from "@/components/TableLink";
import { Skeleton } from "@/components/ui/skeleton";
import { VITE_APP_ENV } from "@/config";
import { useAppContext } from "@/contexts/AppContext";
import { cn } from "@/lib/utils";
import { SignedIn, UserButton } from "@clerk/clerk-react";
import {
	Books,
	CaretDoubleLeft,
	CaretRight,
	Gear,
	LightbulbFilament,
	MagnifyingGlass,
	Plus,
	Rss,
	Table,
} from "@phosphor-icons/react";
import * as Collapsible from "@radix-ui/react-collapsible";
import { useMediaQuery } from "@uidotdev/usehooks";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { NavLink, type NavLinkRenderProps } from "react-router-dom";

const Overlay = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean;
	onClose: () => void;
}) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div
			role="button"
			aria-label="Close sidebar"
			tabIndex={0}
			className="absolute top-0 right-0 bottom-0 left-0 z-40 bg-black/10 opacity-80"
			onClick={onClose}
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					onClose();
				}
			}}
		/>
	);
};

const CollapsibleTrigger = ({
	open,
	setOpen,
	children,
	text,
}: {
	open: boolean;
	setOpen: (open: boolean) => void;
	children: React.ReactNode;
	text: string;
}) => {
	const [isHovered, setIsHovered] = useState(false);

	return (
		<Collapsible.CollapsibleTrigger
			className="flex grow items-center gap-1"
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			onClick={(e) => {
				e.stopPropagation();
				setOpen(!open);
			}}
		>
			<div className="rounded p-0.5 hover:bg-neutral-200">
				{isHovered ? (
					<CaretRight
						weight="bold"
						className={clsx(
							"text-base text-neutral-500 transition-transform duration-100",
							open ? "rotate-90 transform" : "",
						)}
					/>
				) : (
					<>{children}</>
				)}
			</div>
			{text}
		</Collapsible.CollapsibleTrigger>
	);
};

export const Sidebar = observer(() => {
	const appContext = useAppContext();
	const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");

	const [showChats, setShowChats] = useState(false);
	const [showFeeds, setShowFeeds] = useState(false);
	const [showTables, setShowTables] = useState(false);

	const navLinkClass = (props: NavLinkRenderProps) =>
		clsx(
			"flex h-8 w-full shrink-0 items-center gap-1 rounded-lg px-2 py-1.5 font-medium text-neutral-600 text-sm ",
			props.isActive ? "bg-neutral-200" : "",
		);

	return (
		<>
			{isSmallDevice && (
				<Overlay
					isOpen={appContext.showSidebar}
					onClose={() => {
						runInAction(() => {
							appContext.showSidebar = false;
						});
					}}
				/>
			)}
			<AnimatePresence initial={false}>
				{appContext.showSidebar && (
					<motion.div
						initial={{ width: 0 }}
						animate={{ width: "16rem" }}
						exit={{ width: 0 }}
						transition={{ duration: 0.15 }}
						className={cn(
							"z-50 h-full min-w-0 shrink-0 overflow-hidden",
							isSmallDevice ? "absolute top-0 bottom-0 shadow-xl" : "relative",
						)}
					>
						<nav
							className={cn(
								"group/sidebar flex h-screen w-[16rem] flex-col justify-between gap-4 py-4 pr-1 pl-3 backdrop-blur",
								isSmallDevice ? "bg-white" : "bg-neutral-100/90 ",
							)}
						>
							<div className="flex min-h-0 w-full grow flex-col gap-4">
								<div className="flex items-center justify-between">
									<NavLink to="/search" className="">
										<img
											src={"/gr_logo.png"}
											alt="Logo"
											className="h-9 w-9 rounded"
										/>
									</NavLink>
									<button
										type="button"
										className="shrink-0 rounded-lg p-3 text-lg text-neutral-500 opacity-0 transition-opacity duration-100 hover:bg-neutral-200 group-hover/sidebar:opacity-100"
										onClick={() => {
											runInAction(() => {
												appContext.showSidebar = false;
											});
										}}
									>
										<CaretDoubleLeft weight="bold" />
									</button>
								</div>

								<div className="flex max-h-full min-h-0 w-full flex-col gap-0.5">
									<CommandKButton />

									<div className="mt-2" />

									<Collapsible.Root
										open={showChats}
										onOpenChange={(open) => setShowChats(open)}
										className={clsx(
											"flex flex-col",
											showChats ? "min-h-16" : "min-h-8",
										)}
									>
										<div className="flex h-8 w-full shrink-0 items-center gap-1 rounded-lg px-2 py-1.5 font-medium text-neutral-600 text-sm">
											<CollapsibleTrigger
												open={showChats}
												setOpen={setShowChats}
												text="Research"
											>
												<LightbulbFilament
													className="text-base text-neutral-500"
													weight="duotone"
												/>
											</CollapsibleTrigger>
											<NavLink to="/research">
												<div className="rounded border p-0.5 text-neutral-500 hover:border-neutral-300 hover:bg-neutral-200 hover:text-neutral-700">
													<Plus />
												</div>
											</NavLink>
										</div>

										<Collapsible.CollapsibleContent className="ml-4 flex min-h-0 flex-col border-l">
											{appContext.sortedChats !== null ? (
												<div className="flex min-h-0 w-full flex-col gap-0.5 overflow-y-auto pl-1">
													{appContext.sortedChats?.map((chat) => (
														<ChatLink chat={chat} key={chat.chat_id} />
													))}
												</div>
											) : (
												<div className="flex grow flex-col items-center justify-center gap-1 pr-1 pl-2">
													<Skeleton className="h-4 w-full" />
													<Skeleton className="h-4 w-full" />
													<Skeleton className="h-4 w-full" />
												</div>
											)}
										</Collapsible.CollapsibleContent>
									</Collapsible.Root>

									<NavLink to="/search" className={navLinkClass}>
										<MagnifyingGlass
											weight="duotone"
											className="m-0.5 text-base text-neutral-500"
										/>
										Search
									</NavLink>

									<NavLink to="/library" className={navLinkClass}>
										<Books
											className="m-0.5 text-base text-neutral-500"
											weight="duotone"
										/>
										Library
									</NavLink>

									<Collapsible.Root
										open={showFeeds}
										onOpenChange={(open) => setShowFeeds(open)}
										className={clsx(
											"flex flex-col",
											showFeeds ? "min-h-16" : "min-h-8",
										)}
									>
										<div className="flex h-8 w-full shrink-0 items-center gap-1 rounded-lg px-2 py-1.5 font-medium text-neutral-600 text-sm">
											<div className="flex grow items-center gap-1">
												<CollapsibleTrigger
													open={showFeeds}
													setOpen={setShowFeeds}
													text="Feeds"
												>
													<Rss
														className="text-base text-neutral-500"
														weight="duotone"
													/>
												</CollapsibleTrigger>
											</div>
											<NavLink to="/feeds">
												<div className="rounded border p-0.5 text-neutral-500 hover:border-neutral-300 hover:bg-neutral-200 hover:text-neutral-700">
													<Gear />
												</div>
											</NavLink>
										</div>

										<Collapsible.CollapsibleContent className="ml-4 flex min-h-0 flex-grow flex-col overflow-auto border-l">
											{appContext.sortedFeedChannels !== null ? (
												<div className="flex max-h-full min-h-0 w-full flex-col gap-0.5 overflow-y-auto pl-1">
													{appContext.sortedFeedChannels.length > 0 ? (
														appContext.sortedFeedChannels.map((feedChannel) => (
															<FeedLink
																key={feedChannel.feed_channel_id}
																feedChannel={feedChannel}
															/>
														))
													) : (
														<div className="py-2 pl-3 text-neutral-500 text-sm">
															No feeds
														</div>
													)}
												</div>
											) : (
												<div className="flex grow flex-col items-center justify-center gap-1 pr-1 pl-2">
													<Skeleton className="h-4 w-full" />
													<Skeleton className="h-4 w-full" />
													<Skeleton className="h-4 w-full" />
												</div>
											)}
										</Collapsible.CollapsibleContent>
									</Collapsible.Root>

									{VITE_APP_ENV !== "production" && (
										<Collapsible.Root
											open={showTables}
											onOpenChange={(open) => setShowTables(open)}
											className={clsx(
												"flex flex-col",
												showTables ? "min-h-16" : "min-h-8",
											)}
										>
											<div className="flex h-8 w-full shrink-0 items-center gap-1 rounded-lg px-2 py-1.5 font-medium text-neutral-600 text-sm">
												<CollapsibleTrigger
													open={showTables}
													setOpen={setShowTables}
													text="Tables"
												>
													<Table
														className="text-base text-neutral-500"
														weight="duotone"
													/>
												</CollapsibleTrigger>
												<NavLink to="/tables">
													<div className="rounded border p-0.5 text-neutral-500 hover:border-neutral-300 hover:bg-neutral-200 hover:text-neutral-700">
														<Gear />
													</div>
												</NavLink>
											</div>

											<Collapsible.CollapsibleContent className="ml-4 flex flex-col overflow-auto border-l">
												{appContext.tablesAsArray !== null ? (
													<div className="flex min-h-0 w-full flex-col gap-0.5 overflow-y-auto pl-1">
														{appContext.tablesAsArray.length > 0 ? (
															appContext.tablesAsArray.map((table) => (
																<TableLink key={table.table_id} table={table} />
															))
														) : (
															<div className="py-2 pl-3 text-neutral-500 text-sm">
																No tables
															</div>
														)}
													</div>
												) : (
													<div className="flex grow flex-col items-center justify-center gap-1 pr-1 pl-2">
														<Skeleton className="h-4 w-full" />
														<Skeleton className="h-4 w-full" />
														<Skeleton className="h-4 w-full" />
													</div>
												)}
											</Collapsible.CollapsibleContent>
										</Collapsible.Root>
									)}
								</div>
							</div>

							<SignedIn>
								<UserButton />
							</SignedIn>
						</nav>
					</motion.div>
				)}
			</AnimatePresence>
		</>
	);
});
