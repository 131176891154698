import { Checkbox } from "@/components/ui/checkbox";
import { useTableContext } from "@/contexts/TableContext";
import type { TableCellProps } from "@/pages/Table/cellTypes";
import type { BooleanCellValue, BooleanColumnMetadata } from "@api/schemas";
import { observer } from "mobx-react-lite";

export const BooleanCell = observer(
	({
		rowId,
		columnId,
		cellValue,
		isProxy,
	}: TableCellProps<BooleanColumnMetadata>) => {
		const tableContext = useTableContext();

		let castValue: BooleanCellValue | null;
		if (cellValue && cellValue.cell_value_type !== "boolean") {
			castValue = null;
		} else {
			castValue = cellValue;
		}

		return (
			<Checkbox
				className="ml-2 flex items-center"
				checked={castValue?.cell_value ?? false}
				disabled={isProxy || !tableContext.editable}
				onCheckedChange={(checked) => {
					tableContext.updateCell({
						rowId,
						columnId,
						value: {
							cell_value: checked === true,
							cell_value_type: "boolean",
						},
					});
				}}
			/>
		);
	},
);
