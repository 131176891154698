/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PrimaryColumnType = {  text: 'text',
  document: 'document',
} as const
export type PrimaryColumnType = typeof PrimaryColumnType[keyof typeof PrimaryColumnType] ;
