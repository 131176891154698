import type { ChunkId, UploadId } from "@api/schemas";
import * as Sentry from "@sentry/react";
import { Mark, mergeAttributes } from "@tiptap/react";
import { Plugin } from "prosemirror-state";

interface LinkOptions {
	handleUploadLinkClick: (uploadLink: {
		uploadId: UploadId;
		chunkId: ChunkId;
		textStart: string;
		textEnd?: string;
	}) => void;
}

export function uploadLinkClickHandler(
	handleUploadLinkClick: LinkOptions["handleUploadLinkClick"],
): Plugin {
	return new Plugin({
		props: {
			handleClick: (_view, _pos, event) => {
				if (event.button !== 0) {
					return false;
				}
				// TODO: validate
				const a = event.target as HTMLLinkElement;
				// Example: "upload/upload_01J1S1QRKFACDZHFEFYJ2NWBRM#chunk_01J1S2669H1RE8YNYVJP8EWDX8:~:text=The%20minutes%20show%20that%20Volcker%20had%20support%20from%20a%20few%20governors%20and%20presidents%20throughout%2C%20but%20he%20also%20had%20opposition%20much%20of%20the%20time."
				const href = a.getAttribute("href");
				if (!href) return false;

				const [, uploadId, fragment] =
					href.match(/^upload\/([^#]+)(?:#(.+))?/) || [];
				if (!uploadId) return false;

				if (!fragment) {
					console.error("Invalid upload link: missing fragment");
					return false;
				}

				const [chunkId, textPart] = fragment.split(":~:text=");
				if (!chunkId) {
					console.error("Invalid upload link: missing chunkId");
					return false;
				}

				let textStart: string | undefined = undefined;
				let textEnd: string | undefined = undefined;
				if (textPart) {
					const split = textPart.split(",");
					textStart = decodeURIComponent(split[0]);
					if (split.length > 1) textEnd = decodeURIComponent(split[1]);
				}

				if (!textStart) {
					Sentry.captureMessage("Invalid upload link: missing textStart");
					return false;
				}

				// clean soft hyphens in textStart and textEnd
				// textStart = textStart.replace(/\u00AD/g, "");
				// if (textEnd) textEnd = textEnd.replace(/\u00AD/g, "");

				handleUploadLinkClick({
					uploadId: uploadId as UploadId,
					chunkId: chunkId as ChunkId,
					textStart,
					textEnd,
				});
				return true;
			},
		},
	});
}

export const UploadLink = Mark.create<LinkOptions>({
	name: "uploadLink",
	priority: 1001, // Higher priority than Link (1000) to process the nodes first
	addOptions() {
		return {
			handleUploadLinkClick: () => {},
		};
	},
	parseHTML() {
		return [
			{
				tag: 'a[href^="upload/"]',
			},
		];
	},
	addAttributes() {
		return {
			href: {},
		};
	},
	renderHTML({ HTMLAttributes }) {
		// keep only href attribute
		return [
			"a",
			mergeAttributes(HTMLAttributes, { onclick: "event.preventDefault()" }),
			0,
		];
	},
	addProseMirrorPlugins() {
		return [uploadLinkClickHandler(this.options.handleUploadLinkClick)];
	},
});
