import type { AppState } from "@/contexts/AppContext";
import {
	DisplayedActionError,
	createSyncedAction,
} from "@/contexts/SyncedActions";
import { newFolderId, newUploadId } from "@/idGenerators";
import {
	createFolder,
	deleteFolder,
	deleteMultipleFiles,
	deleteUpload,
	downloadOriginal,
	downloadPdf,
	renameFolder,
	updateUploadMetadata,
	uploadToFolder,
	uploadToRoot,
} from "@api/fastAPI";
import {
	type Folder,
	type FolderId,
	type Upload,
	type UploadId,
	UploadMimetype,
} from "@api/schemas";
import { toast } from "sonner";
import xxhash from "xxhash-wasm";

export const updateUploadMetadataAction = createSyncedAction<
	AppState,
	{ uploadId: UploadId; newMetadata: Partial<Upload> },
	{ updatedUpload: Upload; originalUpload: Upload },
	void
>({
	async local({ uploadId, newMetadata }) {
		if (!this.workspace) {
			throw new Error("Workspace not loaded yet!");
		}

		const originalUpload = this.workspace.uploads.get(uploadId);
		if (!originalUpload) {
			throw new Error(`Upload with id ${uploadId} not found`);
		}

		const updatedUpload = { ...originalUpload, ...newMetadata };
		this.workspace.uploads.set(uploadId, updatedUpload);
		this.uploadsFlexsearchIndex.add(updatedUpload);

		return { updatedUpload: updatedUpload, originalUpload: originalUpload };
	},
	async remote({ uploadId, newMetadata }) {
		await updateUploadMetadata({
			upload_id: uploadId,
			new_upload_title: newMetadata.file_name ?? null,
			new_upload_subtitle: newMetadata.upload_subtitle ?? null,
			new_upload_authors: newMetadata.upload_authors ?? null,
			new_upload_publisher: newMetadata.upload_publisher ?? null,
			new_upload_year_published: newMetadata.upload_year_published ?? null,
			new_upload_type: newMetadata.upload_type ?? null,
		});
	},
	rollback({ uploadId }, { originalUpload }) {
		if (!this.workspace) {
			throw new Error("Workspace not loaded yet!");
		}

		this.workspace.uploads.set(uploadId, originalUpload);
		this.uploadsFlexsearchIndex.add(originalUpload);
	},
	onRemoteSuccess() {
		toast.success("Upload metadata updated successfully.");
	},
});

export const createFolderAction = createSyncedAction<
	AppState,
	{ parentFolderId: FolderId | null; folderName: string },
	Folder,
	void
>({
	async local(args) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const folderId = newFolderId();
		const newFolder: Folder = {
			folder_id: folderId,
			file_name: args.folderName,
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_deleted_at: null,
			file_creator_id: this.workspace.userId,
			folder_parent_id: args.parentFolderId,
			file_type: "folder",
		};

		this.workspace.folders.set(folderId, newFolder);
		return newFolder;
	},
	async remote(args, localResult) {
		await createFolder({
			folder_id: localResult.folder_id,
			folder_name: args.folderName,
			folder_parent_id: args.parentFolderId,
		});
	},
	rollback(_, localResult) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		this.workspace.folders.delete(localResult.folder_id);
	},
	onRemoteSuccess() {
		toast.success("Folder created successfully.");
	},
});

export const deleteFolderAction = createSyncedAction<
	AppState,
	{ folderId: FolderId },
	{
		deletedFolder: Folder;
		deletedDescendants: { folderIds: FolderId[]; uploadIds: UploadId[] };
	},
	void
>({
	async local({ folderId }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const folder = this.workspace.folders.get(folderId);
		if (!folder) {
			throw new Error("Folder not found");
		}

		const descendants = this.getFolderDescendants(folderId);

		for (const uploadId of descendants.uploadIds) {
			const upload = this.workspace.uploads.get(uploadId);
			if (upload) {
				upload.file_deleted_at = new Date().toISOString();
			}
		}

		for (const descendantFolderId of descendants.folderIds) {
			const descendantFolder = this.workspace.folders.get(descendantFolderId);
			if (descendantFolder) {
				descendantFolder.file_deleted_at = new Date().toISOString();
			}
		}

		folder.file_deleted_at = new Date().toISOString();

		return { deletedFolder: { ...folder }, deletedDescendants: descendants };
	},
	async remote({ folderId }) {
		await deleteFolder({ folder_id: folderId });
	},
	rollback(_, { deletedFolder, deletedDescendants }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		// Restore the main folder
		const folder = this.workspace.folders.get(deletedFolder.folder_id);
		if (folder) {
			folder.file_deleted_at = null;
		}

		// Restore descendant folders
		for (const folderId of deletedDescendants.folderIds) {
			const descendantFolder = this.workspace.folders.get(folderId);
			if (descendantFolder) {
				descendantFolder.file_deleted_at = null;
			}
		}

		// Restore descendant uplaods
		for (const uploadId of deletedDescendants.uploadIds) {
			const upload = this.workspace.uploads.get(uploadId);
			if (upload) {
				upload.file_deleted_at = null;
			}
		}
	},
	onRemoteSuccess() {
		toast.success("Folder deleted successfully.");
	},
});

export const deleteMultiple = createSyncedAction<
	AppState,
	{ folderIds: FolderId[]; uploadIds: UploadId[] },
	{ deletedFolders: Folder[]; deletedUploads: Upload[] },
	void
>({
	async local({ folderIds, uploadIds }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const deletedFolders: Folder[] = [];
		const deletedUploads: Upload[] = [];

		// Mark folders as deleted
		for (const folderId of folderIds) {
			const folder = this.workspace.folders.get(folderId);
			if (folder) {
				folder.file_deleted_at = new Date().toISOString();
				deletedFolders.push({ ...folder });
			}
		}

		// Mark uploads as deleted
		for (const uploadId of uploadIds) {
			const upload = this.workspace.uploads.get(uploadId);
			if (upload) {
				upload.file_deleted_at = new Date().toISOString();
				deletedUploads.push({ ...upload });
			}
		}

		return { deletedFolders, deletedUploads: deletedUploads };
	},
	async remote({ folderIds, uploadIds }) {
		await deleteMultipleFiles({
			folder_ids: folderIds,
			upload_ids: uploadIds,
		});
	},
	rollback(_, { deletedFolders, deletedUploads }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		// Restore folders
		for (const folder of deletedFolders) {
			const existingFolder = this.workspace.folders.get(folder.folder_id);
			if (existingFolder) {
				existingFolder.file_deleted_at = null;
			}
		}

		// Restore uploads
		for (const upload of deletedUploads) {
			const existingUpload = this.workspace.uploads.get(upload.upload_id);
			if (existingUpload) {
				existingUpload.file_deleted_at = null;
			}
		}
	},
	onRemoteSuccess({ folderIds, uploadIds }) {
		const totalDeleted = folderIds.length + uploadIds.length;
		toast.success(
			`${totalDeleted} item${totalDeleted !== 1 ? "s" : ""} deleted successfully.`,
		);
	},
});

export const createUpload = createSyncedAction<
	AppState,
	{
		folderId: FolderId | null;
		file: File;
		inferMetadata: boolean;
	},
	Upload,
	void
>({
	async local({ folderId, file }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		const hasher = (await xxhash()).h64Raw;
		const hash = hasher(new Uint8Array(await file.arrayBuffer()), 42n).toString(
			16,
		);

		let upload_filetype: UploadMimetype | null = null;

		if (file.type === "application/pdf") {
			upload_filetype = "pdf";
		} else if (file.type === "application/epub+zip") {
			upload_filetype = "epub";
		} else {
			throw new DisplayedActionError(`Unsupported file type for ${file.name}`);
		}

		const uploadId = newUploadId();
		const newUpload: Upload = {
			upload_id: uploadId,
			upload_filetype,
			upload_parent_id: folderId,
			upload_authors: null,
			upload_publisher: null,
			upload_title: file.name,
			upload_subtitle: null,
			upload_type: null,
			upload_year_published: null,
			upload_hash: hash,
			upload_status: "pending",
			file_name: file.name,
			file_created_at: new Date().toISOString(),
			file_updated_at: new Date().toISOString(),
			file_deleted_at: null,
			file_creator_id: this.workspace.userId,
			file_type: "upload",
		};

		this.recentUploads.set(uploadId, newUpload);
		// pass the new upload to the remote function as well as the setUploadedFiles function so that we can update the upload's status
		return newUpload;
	},
	async remote({ folderId, file, inferMetadata }, newUpload) {
		folderId
			? uploadToFolder({
					file,
					upload_id: newUpload.upload_id,
					folder_id: folderId,
					infer_metadata: inferMetadata,
				})
			: uploadToRoot({
					file,
					upload_id: newUpload.upload_id,
					infer_metadata: inferMetadata,
				});
	},
	rollback(_, localResult) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}

		this.workspace.uploads.delete(localResult.upload_id);
	},
});

export const deleteUploadAction = createSyncedAction<
	AppState,
	{ uploadId: UploadId },
	{ deletedUpload: Upload },
	void
>({
	async local({ uploadId }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const upload = this.workspace.uploads.get(uploadId);
		if (!upload) {
			throw new Error("Upload not found");
		}
		upload.file_deleted_at = new Date().toISOString();
		return { deletedUpload: { ...upload } };
	},
	async remote({ uploadId }) {
		await deleteUpload({ upload_id: uploadId });
	},
	rollback(_, { deletedUpload }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const upload = this.workspace.uploads.get(deletedUpload.upload_id);
		if (upload) {
			upload.file_deleted_at = new Date().toISOString();
		}
	},
	onRemoteSuccess() {
		toast.success("Upload deleted successfully.");
	},
});

export const renameFolderAction = createSyncedAction<
	AppState,
	{ folderId: FolderId; folderName: string },
	{ oldName: string; newName: string },
	void
>({
	async local({ folderId, folderName }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const folder = this.workspace.folders.get(folderId);
		if (!folder) {
			throw new Error("Folder not found");
		}
		const oldName = folder.file_name;
		folder.file_name = folderName;
		return { oldName, newName: folderName };
	},
	async remote({ folderId, folderName }) {
		await renameFolder({ folder_id: folderId, folder_name: folderName });
	},
	rollback({ folderId }, { oldName }) {
		if (this.workspace === null) {
			throw new Error("Workspace not loaded yet!");
		}
		const folder = this.workspace.folders.get(folderId);
		if (folder) {
			folder.file_name = oldName;
		}
	},
});

export function downloadUploadPdf(this: AppState, uploadId: UploadId) {
	toast.promise(
		async () => {
			const upload = this.getUploadById(uploadId);

			if (!upload) {
				throw new Error("Upload not found");
			}

			const resp = await downloadPdf(uploadId, {
				responseType: "blob",
			});

			const url = URL.createObjectURL(resp.data as Blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = `${upload.upload_title ?? upload.file_name}.pdf`;
			a.click();
		},
		{
			loading: "Exporting PDF...",
			success: "PDF exported!",
			error: "Failed to export PDF",
		},
	);
}

export function downloadOriginalUploadFile(this: AppState, uploadId: UploadId) {
	toast.promise(
		async () => {
			const upload = this.getUploadById(uploadId);

			if (!upload) {
				throw new Error("Upload not found");
			}

			let extension = null;
			switch (upload.upload_filetype) {
				case UploadMimetype.pdf:
					extension = "pdf";
					break;
				case UploadMimetype.epub:
					extension = "epub";
					break;
			}

			if (extension === null) {
				throw new Error("Unsupported file type");
			}

			const resp = await downloadOriginal(uploadId, {
				responseType: "blob",
			});

			const url = URL.createObjectURL(resp.data as Blob);
			const a = document.createElement("a");
			a.href = url;
			a.download = upload.file_name;
			a.click();
		},
		{
			loading: "Retrieving original...",
			success: "Original file retrieved!",
			error: "Failed to retrieve original file",
		},
	);
}

export function sortedIndexedUploads(this: AppState): Upload[] | null {
	if (!this.workspace) {
		return null;
	}
	return Array.from(this.workspace.uploads.values())
		.sort((a, b) => {
			const aName = a.upload_title ?? a.file_name;
			const bName = b.upload_title ?? b.file_name;
			return aName.localeCompare(bName);
		})
		.filter((x) => !x.file_deleted_at && x.upload_status === "ready");
}

export function searchUploadsByMetadata(this: AppState, query: string) {
	const searchResults = this.uploadsFlexsearchIndex.search(query);

	return new Set(searchResults.flatMap((result) => result.result));
}
