import { TableColumnType } from "@api/schemas";
import {
	Calendar,
	CaretCircleDown,
	CheckSquare,
	Clock,
	File,
	Hash,
	type IconProps,
	Link,
	TextAa,
} from "@phosphor-icons/react";

export const ColumnTypeIcons: {
	[key in TableColumnType]: (props: IconProps) => JSX.Element;
} = {
	[TableColumnType.text]: (props: IconProps) => <TextAa {...props} />,
	[TableColumnType.document]: (props: IconProps) => <File {...props} />,
	[TableColumnType.boolean]: (props: IconProps) => <CheckSquare {...props} />,
	[TableColumnType.category]: (props: IconProps) => (
		<CaretCircleDown {...props} />
	),
	[TableColumnType.date]: (props: IconProps) => <Calendar {...props} />,
	[TableColumnType.datetime]: (props: IconProps) => <Clock {...props} />,
	[TableColumnType.number]: (props: IconProps) => <Hash {...props} />,
	[TableColumnType.proxy]: (props: IconProps) => <Link {...props} />,
	[TableColumnType.proxy_group]: (props: IconProps) => <Link {...props} />,
};

export const ColumnTypeLabels: {
	[key in TableColumnType]: string;
} = {
	[TableColumnType.text]: "Text",
	[TableColumnType.document]: "Document",
	[TableColumnType.boolean]: "Checkbox",
	[TableColumnType.category]: "Select",
	[TableColumnType.date]: "Date",
	[TableColumnType.datetime]: "Date and time",
	[TableColumnType.number]: "Number",
	[TableColumnType.proxy]: "Rollup",
	[TableColumnType.proxy_group]: "Rollup",
};
