import type { AppState } from "@/contexts/AppContext";
import type {
	FeedChannel,
	FeedChannelId,
	FeedItemMetadata,
} from "@api/schemas";

export class DirectoryNode<T extends "feed_channel" | "feed_item"> {
	node_id: string;
	item: T extends "feed_channel"
		? { type: "feed_channel"; data: FeedChannel }
		: { type: "feed_item"; data: FeedItemMetadata };

	constructor(
		node_id: string,
		item: T extends "feed_channel"
			? { type: "feed_channel"; data: FeedChannel }
			: { type: "feed_item"; data: FeedItemMetadata },
	) {
		this.node_id = node_id;
		this.item = item;
	}

	static fromFeedChannel(feed_channel: FeedChannel): FeedChannelNode {
		return new DirectoryNode(feed_channel.feed_channel_id, {
			type: "feed_channel",
			data: feed_channel,
		});
	}

	static fromFeedItem(feed_item: FeedItemMetadata): FeedItemNode {
		return new DirectoryNode(feed_item.feed_item_id, {
			type: "feed_item",
			data: feed_item,
		});
	}

	get name(): string {
		return this.item.type === "feed_channel"
			? this.item.data.file_name
			: (this.item.data.file_name ?? this.item.data.feed_item_link);
	}
}
export type FeedNode = DirectoryNode<"feed_channel" | "feed_item">;
export type FeedChannelNode = DirectoryNode<"feed_channel">;
export type FeedItemNode = DirectoryNode<"feed_item">;

export function feedChannelNodes(this: AppState): FeedNode[] | null {
	if (!this.workspace) {
		return null;
	}
	const feedChannels = [...this.workspace.feedChannels.values()]
		.filter((feedChannel) => !feedChannel.file_deleted_at)
		.sort((a, b) => a.file_name.localeCompare(b.file_name))
		.map((feedChannel) => DirectoryNode.fromFeedChannel(feedChannel));

	return feedChannels;
}

export function feedChannelImmediateChildren(this: AppState) {
	const feedChannelIdToChildren = new Map<FeedChannelId, FeedNode[]>();

	if (!this.workspace) {
		return feedChannelIdToChildren;
	}

	const feedItems = [...this.workspace.feedItems.values()]
		.filter((feedItem) => !feedItem.file_deleted_at)
		.filter((feedItem) => {
			if (!this.feedChannelsById?.get(feedItem.feed_channel_id)) {
				return false;
			}
			return (
				this.feedChannelsById?.get(feedItem.feed_channel_id)
					?.file_deleted_at === undefined
			);
		})
		.map((feedItem) => DirectoryNode.fromFeedItem(feedItem));

	for (const feedItem of feedItems) {
		const feedChannelId = feedItem.item.data.feed_channel_id;
		const channelChildren = feedChannelIdToChildren.get(feedChannelId) || [];
		channelChildren.push(feedItem);
		feedChannelIdToChildren.set(feedChannelId, channelChildren);
	}

	return feedChannelIdToChildren;
}

export function feedTreeChildrenAccessor(this: AppState, node: FeedNode) {
	if (node.item.type === "feed_channel") {
		return (
			this.feedChannelImmediateChildren.get(node.item.data.feed_channel_id) ||
			[]
		);
	}
	return null;
}
