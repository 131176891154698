/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * FastAPI
 * OpenAPI spec version: 0.1.0
 */

export type ChatDisconnectCode = typeof ChatDisconnectCode[keyof typeof ChatDisconnectCode];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ChatDisconnectCode = {
  NUMBER_4040: 4040,
  NUMBER_4000: 4000,
} as const;
